import { useState } from "react";
import {
  Tabs,
  Tab,
  Avatar,
  TextField,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Divider from "../components/Divider";
import { Button, Form } from "react-bootstrap";
import "../css/profileConfig.css";
import { useEffect } from "react";
import { useAppContext } from "../context/appContext";
import { Alert, Snackbar } from "@mui/material";
import cssProfileConfig from "../css/profileConfig.module.css";
import {customTab, customTextField, select_phone_number_flags, simple_blue_toogleButton, toogleButtonGroupSimple, toogleButtonGroupSimpleGap} from '../components/Styles.js'

//Para subir imagen
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { SVG } from "../helpers/Svgs.js";

import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={cssProfileConfig['verticalPanel']}
    >
     
      {value === index && (
        <main
          className={cssProfileConfig['tabPanel__Container']}
        >
          {children}
        </main>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const ProfileConfig = () => {
  const theme = useTheme();
  const minor1200px = useMediaQuery('(max-width:1200px)');
  const minor370px = useMediaQuery('(max-width:370px)');

  const [value, setValue] = useState(0);
  const location = useLocation()
  const prefixes = [
    { prefix: "34", country: "España", flag: "🇪🇸" },
    { prefix: "1", country: "Estados Unidos", flag: "🇺🇸" },
    { prefix: "86", country: "China", flag: "🇨🇳" },
    { prefix: "91", country: "India", flag: "🇮🇳" },
    { prefix: "7", country: "Rusia", flag: "🇷🇺" },
    { prefix: "81", country: "Japón", flag: "🇯🇵" },
    { prefix: "49", country: "Alemania", flag: "🇩🇪" },
    { prefix: "44", country: "Reino Unido", flag: "🇬🇧" },
    { prefix: "33", country: "Francia", flag: "🇫🇷" },
    { prefix: "55", country: "Brasil", flag: "🇧🇷" },
    { prefix: "39", country: "Italia", flag: "🇮🇹" },
    { prefix: "1", country: "Canadá", flag: "🇨🇦" },
    { prefix: "61", country: "Australia", flag: "🇦🇺" },
    { prefix: "82", country: "Corea del Sur", flag: "🇰🇷" },
    { prefix: "52", country: "México", flag: "🇲🇽" },
    { prefix: "62", country: "Indonesia", flag: "🇮🇩" },
    { prefix: "90", country: "Turquía", flag: "🇹🇷" },
    // { prefix: "966", country: "Arabia Saudita", flag: "🇸🇦" },
    { prefix: "27", country: "Sudáfrica", flag: "🇿🇦" },
    { prefix: "971", country: "Emiratos Árabes Unidos", flag: "🇦🇪" },
    { prefix: "41", country: "Suiza", flag: "🇨🇭" },
  ];

  const {
    user,
    userLoading,
    updateUser,
    updateProfilePic,
    isLoading,
    showAlert,
    disableAlert,
    alertText,
    alertType,
    updatePassword,
  } = useAppContext();

  const [prefixSelected, setPrefixSelected] = useState(prefixes[0].prefix);
  const [businessAccount, setBusinessAccount] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [checkPassword, setCheckPassword] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState(false);

  const [actualPassword, setActualPassword] = useState("");

  useEffect(() => {
    if (showAlert) {
      const timeout = setTimeout(() => {
        disableAlert();
      }, 2000);
      return () => clearTimeout(timeout); // Limpiar el timeout si el componente se desmonta
    }
  }, [showAlert]);

  useEffect(() => {
    if (alertType) {
      const timeout = setTimeout(() => {
        disableAlert();
      }, 2000);
      return () => clearTimeout(timeout); // Limpiar el timeout si el componente se desmonta
    }
  }, [alertType]);

  useEffect(() => {
    if (passwordError) {
      const timeoutId = setTimeout(() => {
        setPasswordError(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [passwordError]);

  useEffect(() => {
    if (phoneNumberError) {
      const timeoutId = setTimeout(() => {
        setPhoneNumberError(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [phoneNumberError]);

  useEffect(() => {
    if (emailError) {
      const timeoutId = setTimeout(() => {
        setEmailError(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [emailError]);
  useEffect(() => {
    if (checkPasswordError) {
      const timeoutId = setTimeout(() => {
        setCheckPasswordError(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [checkPasswordError]);

  useEffect(() => {
    if (!userLoading) {
      const name = user.name ? user.name : "";
      const lastName = user.lastName ? user.lastName : "";
      const biography = user.biography ? user.biography : "";
      const email = user.email ? user.email : "";
      const phoneNumber = user.phoneNumber ? user.phoneNumber : "";
      setEmail(email);
      setPhoneNumber(phoneNumber);
      setBusinessAccount(user.businessAccount);
      setProfileStruct([name, lastName, biography]);
    }
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    disableAlert();
  };

  const handlePrefixSelected = (event) => {
    if (event.target.value != null) {
      setPrefixSelected(event.target.value);
    }
  };

  const handleClick = (event, option) => {
    if (option !== null) {
      setBusinessAccount(option);
    }
  };
  const handleInputChange = (event) => {
    setPhoneNumberError(false);
    const inputValue = event.target.value.replace(/[^\d]/g, ""); // Elimina cualquier carácter no numérico
    event.target.value = inputValue;
    if (inputValue.length <= 9) {
      setPhoneNumber(event.target.value);
    }
  };
  const handleActualContrasena = (event) => {
    setActualPassword(event.target.value); // Actualiza el estado de la contraseña
  };
  const handleNewContrasena = (event) => {
    setNewPassword(event.target.value); // Actualiza el estado de la contraseña
  };
  const handleCheckContrasena = (event) => {
    setCheckPassword(event.target.value);
  };
  //Subir foto

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    disableAlert();
    setOpen(false);
    setSelectedFileName(""); // Restablecer el nombre del archivo
    setImagePreview(null); // Restablecer la vista previa de la imagen
  };

  const [image, setImage] = useState(null); //Imagen para subir
  const [selectedFileName, setSelectedFileName] = useState("");
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.name.split(".").pop().toLowerCase();
      if (fileType === "png" || fileType === "jpg") {
        setSelectedFileName(file.name);
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
        setImage(file);
      } else {
        alert("Solo se permiten archivos .png o .jpg");
        e.target.value = "";
        setSelectedFileName("");
        setImagePreview(null); //
      }
    }
  };

  const handleUploadAndClose = () => {
    if (image) {
      updateProfilePic(image, "Imagen de perfil actualizada correctamente");
    }
    handleClose();
  };

  //Tu perfil, Nombre, Apellidos y bio.
  const [profileStruct, setProfileStruct] = useState(["", "", ""]);

  //Por probar, aqui en este array guardo todos los datos de la primera tab
  function handleProfileStructChange(key, value) {
    setProfileStruct((prevState) => {
      let newState = [...prevState];
      switch (key) {
        case "name":
          newState[0] = value;
          break;
        case "lastName":
          newState[1] = value;
          break;
        case "biography":
          newState[2] = value;
          break;
        default:
          break;
      }
      return newState;
    });
  }

  const timeHideAlert = 1000;
  const formatoemail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isPasswordValid = newPassword.length >= 8 && /\d/.test(newPassword);
  const phonenumberlength = phoneNumber.length == 9;

  const handleSave = (e) => {
    e.preventDefault();
    let currentUser;
    switch (value) {
      case 0:
        currentUser = {
          name: profileStruct[0],
          lastName: profileStruct[1],
          biography: profileStruct[2],
        };
        updateUser(currentUser, "Datos actualizados correctamente");
        break;
      case 1:
        if (!formatoemail.test(email)) {
          setEmailError(true);
          return;
        }
        if (!phonenumberlength) {
          setPhoneNumberError(true);
          return;
        }
        if (!phoneNumber || !formatoemail.test(email)) {
          return;
        } else {
          console.log("amai2")
          currentUser = { email, phoneNumber };
          updateUser(currentUser, "Datos actualizados correctamente");
        }
        break;
      case 2:
        currentUser = {
          actualPassword,
          newPassword,
        };
        if (!isPasswordValid) {
          setPasswordError(true);
          return;
        } else {
          setPasswordError(false);
          if (newPassword !== checkPassword) {
            setCheckPasswordError(true);
          }
        }
        if (
          !isPasswordValid ||
          newPassword !== checkPassword ||
          !newPassword ||
          !checkPassword ||
          !actualPassword
        ) {
          return;
        } else {
          setCheckPasswordError(false);
          updatePassword(currentUser, "Datos actualizados correctamente");
          break;
        }

      case 3:
        currentUser = { businessAccount };
        updateUser(currentUser, "Datos actualizados correctamente");
        break;
      default:
        break;
    }
  };
  let typeOfTab = "standard"
  if(minor1200px){
    typeOfTab = "fullWidth"
    if(minor370px){
      typeOfTab = "scrollable"
    }
  }
  return (
    <div className="justify-content-start p-0 m-0">
       <Helmet>
                {/* Título básico */}
                <title>Ajustes de cuenta</title>

                {/* Caracteres y idioma */}
                <meta charSet="utf-8" />
                <html lang="es" />

                {/* Descripción de la página */}
                <meta name="description" content="Ajustes de cuenta" />

                {/* Mobile Meta */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {/* Favicon */}
                <link rel="icon" type="image/png" href="https://www.spacease.com/favicon.ico" />

                {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
                <link rel="canonical" href={`https://www.spacease.com${location.pathname}`} />
       </Helmet>
      <div className={cssProfileConfig['main__container']}>
        <Tabs 
          allowScrollButtonsMobile
          // orientation="vertical"
          orientation={minor1200px ? "horizontal" : "vertical"}
          variant={typeOfTab}
          value={value} 
          onChange={handleChange}
          sx={{
            minWidth:minor1200px ? "unset": "fit-content",
              marginTop:"1rem",
              ".MuiTabs-flexContainer":{
                paddingTop:minor1200px ? "0" : "1rem",
              },
              ".Mui-disabled":{
                display:"none"
              },
              ".MuiTabs-indicator":{
                backgroundColor:"#034CF3"
              },
              "& .MuiTabScrollButton-root":{
                width:"20px"
              }
            
          }}
        >
          <Tab sx={customTab} label="Tu perfil" {...a11yProps(0)} />
          <Tab sx={customTab} label="Datos de la cuenta" {...a11yProps(1)} />
          <Tab sx={customTab} label="Contraseña" {...a11yProps(2)} />
          <Tab sx={customTab} label="Preferencias globales" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={value} index={0}>
            <section className="d-flex flex-column gap_1-25rem">
              <h1 className="fs_2rem text-black fw_600 m-0">Tu perfil</h1>
              <p className="fs_1rem text-gray fw_400 m-0">
                Tener un perfil de usuario completo asegura una mayor confianza
                entre inquilino y dueños de espacios
              </p>
            </section>
            <Divider/>
            <section className="d-flex flex-column gap_1-25rem">
              <p className="fs_1-25rem text-black fw_600 m-0">
                Persona de contacto de la cuenta
              </p>
              <p className="fs_1rem text-black fw_600 m-0">Tu foto de perfil</p>
                <Avatar
                  style={{ width: "10rem", height: "10rem" }}
                  src={user.profilePicture}
                ></Avatar>
                <p className="text-gray fs_1rem fw_400 m-0">
                  Tip: Sube una foto que muestre tu cara
                </p>
                <div>
                  <Button
                    className="d-flex align-items-center upload-space br_0-93rem"
                    onClick={handleClickOpen}
                  >
                    {SVG.CAMARA}
                    {user.profilePicture ? "Modificar" : "Añadir"}
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      {user.profilePicture
                        ? "Modifica tu imagen de perfil"
                        : "Sube tu imagen de perfil"}
                    </DialogTitle>
                    <DialogContent>
                      <label
                        htmlFor="file-upload"
                        className={cssProfileConfig["custom-file-upload-button"]}
                        style={{ marginBottom: "20px",cursor:"pointer" }}
                      >
                        Seleccionar archivo
                      </label>
                      <input
                        id="file-upload"
                        style={{display:"none"}}
                        type="file"
                        accept=".png, .jpg"
                        onChange={handleImageUpload}
                      />
                      {selectedFileName && (
                        <div>Imagen seleccionada: {selectedFileName}</div>
                      )}
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Vista previa"
                          style={{
                            width: "10rem",
                            height: "10rem",
                            objectFit: "cover",
                            marginTop: "20px",
                          }}
                        />
                      )}
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} 
                      style={{backgroundColor:"#FFF",color:"#222",border:"0"}}
                       >
                        Cancelar
                      </Button>
                      <Button  
                        className=""
                        onClick={handleUploadAndClose} 
                        style={{
                          borderRadius:"0.65rem",
                          backgroundColor:"#034CF3",
                          color:"#FFF"
                        }}
                        >
                        Subir
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <p className="text-gray fs_1rem fw_400 m-0">
                  .JPG, .PNG. Máximo 10 MB
                </p>
                {showAlert && (
                  <div class="alert alert-success" role="alert">
                    {alertText}
                  </div>
                )}
            </section>
            <Divider/>
            <section className="d-flex flex-column gap_1-25rem">
              <p className="text-black fs_1rem fw_600 m-0">Nombre y Apellidos</p>
              <div className="d-flex gap_1-25rem">
                <TextField
                  sx={customTextField}
                  variant="outlined"
                  placeholder="Nombre"
                  margin="none"
                  onChange={(event) =>
                    handleProfileStructChange("name", event.target.value)
                  }
                  value={profileStruct[0]}
                ></TextField>
                <TextField
                sx={customTextField}
                  variant="outlined"
                  placeholder="Apellidos"
                  margin="none"
                  onChange={(event) =>
                    handleProfileStructChange("lastName", event.target.value)
                  }
                  value={profileStruct[1]}
                ></TextField>
              </div>
              <p className="text-black fs_1rem fw_600 m-0">Tu biografía</p>
              <div>
                <TextField
                  style={{ width: "70%" }}
                  sx={customTextField}
                  minRows={4}
                  multiline
                  placeholder="Cuéntanos un poco sobre ti"
                  onChange={(event) =>
                    handleProfileStructChange("biography", event.target.value)
                  }
                  value={profileStruct[2]}
                ></TextField>
                <p className="fw_400 fs_0-75rem text-gray mt-1 mb-0">
                  {450 - profileStruct[2].length} caracteres restantes.
                </p>
              </div>
            </section>
            <Divider/>
            <Button className="fw_500 button-black w_fit-content" onClick={handleSave}>
              Guardar cambios
            </Button>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <h1 className="fs_2rem fw_600 text-black">Datos de la cuenta</h1>
            <Divider/>
            <section>
              <p className="fs_1rem text-black fw_600">Tu direccion de email</p>
              <div style={{maxWidth:"22rem"}}>
                <TextField
                  sx={customTextField}
                  fullWidth
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></TextField>
              </div>
            </section>
            <section>
              <Snackbar
                onClose={handleClose}
                open={showAlert}
                autoHideDuration={timeHideAlert}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleClose}
                  variant="filled"
                  severity={alertType ? alertType : ""}
                >
                  <strong>{alertText ? alertText : ""} </strong>
                </Alert>
              </Snackbar>
              <p className="fs_1rem text-black fw_600">Teléfono móvil</p>
              <div className="prefixes__container d-flex gap_1-25rem">
                <Select
                  autoWidth
                  sx={select_phone_number_flags}
                  onChange={handlePrefixSelected}
                  value={prefixSelected}
                >
                  {prefixes?.map((country) => (
                    <MenuItem value={country.prefix} key={country.prefix}>
                      <div className="d-flex justify-content-between w-100">
                        <p className="col d-inline">+{country.prefix}</p>
                        <p
                          style={{ paddingLeft: "20px" }}
                          className="col d-inline align-self-center"
                        >
                          {country.country} {country.flag}
                        </p>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  sx={customTextField}
                  placeholder="Teléfono móvil"
                  onChange={handleInputChange}
                  value={phoneNumber}
                />
              </div>
              {(emailError && (
                <div class="alert alert-danger" role="alert">
                  Introduce un email válido.
                </div>
              )) ||
                (phoneNumberError && (
                  <div class="alert alert-danger" role="alert">
                    El número de teléfono debe tener 9 caracteres.
                  </div>
                ))}
              {showAlert && alertType === "success" && (
                <div class="alert alert-success" role="alert">
                  {alertText}
                </div>
              )}
            </section>
            <Divider/>
            <Button className="fw_500 button-black w_fit-content" onClick={handleSave}>
              Guardar cambios
            </Button>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <h1 className="fs_2rem fw_600 text-black m-0">Cambiar contraseña</h1>
            <Divider/>
            <section  style={{maxWidth:"20rem"}} className="d-flex flex-column gap_1-25rem">
              <p className="fs_1rem text-black fw_600 m-0">Nueva contraseña</p>
              
              <TextField
                sx={customTextField}
                fullWidth
                required
                type="password"
                placeholder="Escribe tu nueva contraseña"
                value={newPassword} // Establece el valor del campo de texto
                onChange={handleNewContrasena} // Maneja el camb
              ></TextField>
              <TextField
                sx={customTextField}
                fullWidth
                required
                type="password"
                placeholder="Confirmar contraseña"
                value={checkPassword} // Establece el valor del campo de texto
                onChange={handleCheckContrasena} // Maneja el camb
              ></TextField>
              <p className="fs_1rem text-black fw_600 m-0">Contraseña actual</p>
                <TextField
                  sx={customTextField}
                  fullWidth
                  required
                  type="password" // Cambiado a "password" para campo de contraseña
                  placeholder="Escribe tu contraseña actual"
                  value={actualPassword}
                  onChange={handleActualContrasena}
                />
              {passwordError && (
                <div class="alert alert-danger" role="alert">
                  La contraseña nueva debe tener más de 8 caracteres y al menos
                  un número.
                </div>
              )}
              {!passwordError && checkPasswordError && (
                <div class="alert alert-danger" role="alert">
                  La contraseña nueva y su comprobación, no coinciden.
                </div>
              )}
              {showAlert && alertType === "success" && (
                <div class="alert alert-success" role="alert">
                  {alertText}
                </div>
              )}
              {showAlert && alertType === "error" && (
                <div class="alert alert-danger" role="alert">
                  {alertText}
                </div>
              )}
            </section>
            <Divider/>
            <Button
              className="fw_500 button-black w_fit-content"
              onClick={handleSave}
              disabled={isLoading}
            >
              Guardar cambios
            </Button>
        </TabPanel>
        <TabPanel value={value} index={3}>
            <h1  className="fs_2rem fw_600 text-black m-0">
              Preferencias globales
            </h1>
            <Divider/>
            <section>
              <Form.Group className="form__objectives">
                <p style={{textAlign:"unset"}} className="text-black fs_1rem fw_600">
                  Principalmente uso mi cuenta para...
                </p>
                  <ToggleButtonGroup
                  sx={{
                    gap:"1.25rem"
                  }}
                    exclusive
                    value={businessAccount}
                    onChange={handleClick}
                  >
                    <ToggleButton sx={simple_blue_toogleButton} key={"Alquilar espacios"} value={false}>
                      Alquilar espacios
                    </ToggleButton>
                    <ToggleButton sx={simple_blue_toogleButton} key={"Subir espacios"} value={true}>
                      Subir espacios
                    </ToggleButton>
                  </ToggleButtonGroup>
                {showAlert && (
                  <div class="alert alert-success" role="alert">
                    {alertText}
                  </div>
                )}
              </Form.Group>
            </section>
            <Button
              className="fw_500 button-black w_fit-content"
              onClick={handleSave}
              disabled={isLoading}
            >
              Guardar cambios
            </Button>
        </TabPanel>
      </div>
    </div>
  );
};

export default ProfileConfig;
