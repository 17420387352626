import React, { useState, useEffect } from "react";
import "../css/extraPages.css";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { useLanguage } from "../hooks/useLanguage";

const TermsOfUse = () => {
	const location = useLocation();
	const [idioma, setIdioma] = useState("es");

	const languageData = useLanguage(idioma, "terms");

	const cambiarIdioma = (event) => {
		setIdioma(event.target.value);
	};

	return (
		<div>
			<Helmet>
				{/* Título básico */}
				<title>Términos de uso - Spacease</title>

				{/* Caracteres y idioma */}
				<meta charSet="utf-8" />
				<html lang="es" />

				{/* Descripción de la página */}
				<meta name="description" content="Términos de uso de Spacease" />

				{/* Mobile Meta */}
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>

				{/* Open Graph (para Facebook y LinkedIn) */}
				<meta property="og:title" content="Términos de uso - Spacease" />
				<meta property="og:description" content="Términos de uso de Spacease" />
				<meta
					property="og:image"
					content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Spacease" />

				{/* Twitter Card */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content="@Spacease_es" />
				<meta name="twitter:title" content="Términos de uso - Spacease" />
				<meta
					name="twitter:description"
					content="Términos de uso de Spacease"
				/>
				<meta
					name="twitter:image"
					content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg"
				/>

				{/* Favicon */}
				<link
					rel="icon"
					type="image/png"
					href="https://www.spacease.com/favicon.ico"
				/>

				{/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
				<link
					rel="canonical"
					href={`https://www.spacease.com${location.pathname}`}
				/>
			</Helmet>
			<main className="cities-container" style={{ maxWidth: "80rem" }}>
				<section className="d-flex justify-content-between align-items-center">
					<h3>{languageData.title}</h3>
					<Select value={idioma} onChange={cambiarIdioma}>
						<MenuItem value="es">Español</MenuItem>
						<MenuItem value="en">English</MenuItem>
					</Select>
				</section>
				<p className="text-gray">{languageData.lastUpdate}</p>
				<section className="cities-container__section">
					<h3 className="text-black fs_1-5rem">{languageData.p1}</h3>
					<p className="text-gray fs_1rem">{languageData.p1_1}</p>
					<p className="text-gray fs_1rem">{languageData.p1_2}</p>
					<p className="text-gray fs_1rem">{languageData.p1_3}</p>
					<p className="text-gray fs_1rem">{languageData.p1_4}</p>

					<h3 className="text-black fs_1-5rem">{languageData.p2}</h3>
					<p className="text-gray fs_1rem">{languageData.p2_1}</p>
					<div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p2_1_1}</p>
						<p className="text-gray fs_1rem">{languageData.p2_1_2}</p>
					</div>
					<p className="text-gray fs_1rem">{languageData.p2_2}</p>
					<p className="text-gray fs_1rem">{languageData.p2_3}</p>
					<p className="text-gray fs_1rem">{languageData.p2_4}</p>
					<p className="text-gray fs_1rem">{languageData.p2_5}</p>

					<h3 className="text-black fs_1-5rem">{languageData.p3}</h3>
					<p className="text-gray fs_1rem">{languageData.p3_1}</p>

					<h3 className="text-black fs_1-5rem">{languageData.p4}</h3>
					<p className="text-gray fs_1rem">{languageData.p4_1}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p4_1_1}</p>
					<p className="text-gray fs_1rem">{languageData.p4_2}</p>
					<div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p4_2_1}</p>
						<p className="text-gray fs_1rem">{languageData.p4_2_2}</p>
					</div>

					<h3 className="text-black fs_1-5rem">{languageData.p5}</h3>
					<p className="text-gray fs_1rem">{languageData.p5_1}</p>
					<p className="text-gray fs_1rem">{languageData.p5_2}</p>
					<div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p5_2_1}</p>
						<div className="cities-container__section ms-3">
							<p className="text-gray fs_1rem">{languageData.p5_2_1_1}</p>
							<p className="text-gray fs_1rem">{languageData.p5_2_1_2}</p>
							<p className="text-gray fs_1rem">{languageData.p5_2_1_3}</p>
							<p className="text-gray fs_1rem">{languageData.p5_2_1_4}</p>
							<p className="text-gray fs_1rem">{languageData.p5_2_1_5}</p>
						</div>
					</div>
					<div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p5_2_2}</p>
						<div className="cities-container__section ms-3">
							<p className="text-gray fs_1rem">{languageData.p5_2_2_0}</p>
							<div className="cities-container__section ms-3">
								<p className="text-gray fs_1rem">{languageData.p5_2_2_1}</p>
								<p className="text-gray fs_1rem">{languageData.p5_2_2_2}</p>
								<p className="text-gray fs_1rem">{languageData.p5_2_2_3}</p>
								<p className="text-gray fs_1rem">{languageData.p5_2_2_4}</p>
							</div>
						</div>
					</div>
					<div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p5_2_3}</p>
						<div className="cities-container__section ms-3">
							<p className="text-gray fs_1rem">{languageData.p5_2_3_0}</p>
							<div className="cities-container__section ms-3">
								<p className="text-gray fs_1rem">{languageData.p5_2_3_1}</p>
								<p className="text-gray fs_1rem">{languageData.p5_2_3_2}</p>
								<p className="text-gray fs_1rem">{languageData.p5_2_3_3}</p>
							</div>
						</div>
					</div>

          <h3 className="text-black fs_1-5rem">{languageData.p6}</h3>
					<p className="text-gray fs_1rem">{languageData.p6_1}</p>
          <div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p6_1_1}</p>
						<p className="text-gray fs_1rem">{languageData.p6_1_2}</p>
						<p className="text-gray fs_1rem">{languageData.p6_1_3}</p>
						<p className="text-gray fs_1rem">{languageData.p6_1_4}</p>
						<p className="text-gray fs_1rem">{languageData.p6_1_5}</p>
						<p className="text-gray fs_1rem">{languageData.p6_1_6}</p>
					</div>
					<p className="text-gray fs_1rem">{languageData.p6_2}</p>
          <div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p6_2_1}</p>
						<p className="text-gray fs_1rem">{languageData.p6_2_2}</p>
						<p className="text-gray fs_1rem">{languageData.p6_2_3}</p>
						<p className="text-gray fs_1rem">{languageData.p6_2_4}</p>
					</div>
					<p className="text-gray fs_1rem">{languageData.p6_3}</p>
          <div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p6_3_1}</p>
						<p className="text-gray fs_1rem">{languageData.p6_3_2}</p>
						<p className="text-gray fs_1rem">{languageData.p6_3_3}</p>
					</div>

          <h3 className="text-black fs_1-5rem">{languageData.p7}</h3>
					<p className="text-gray fs_1rem">{languageData.p7_1}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p7_1_text}</p>
					<p className="text-gray fs_1rem">{languageData.p7_2}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p7_2_text}</p>
					<p className="text-gray fs_1rem">{languageData.p7_3}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p7_3_text}</p>
					<p className="text-gray fs_1rem">{languageData.p7_4}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p7_4_text}</p>
					<p className="text-gray fs_1rem">{languageData.p7_5}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p7_5text}</p>
					<p className="text-gray fs_1rem">{languageData.p7_6}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p7_6_text}</p>
					<p className="text-gray fs_1rem">{languageData.p7_7}</p>
					<p className="text-gray fs_1rem ms-3">{languageData.p7_7_text}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p8}</h3>
					<p className="text-gray fs_1rem">{languageData.p8_0}</p>
					<p className="text-gray fs_1rem">{languageData.p8_0_1}</p>
					<p className="text-gray fs_1rem">{languageData.p8_1}</p>
          <div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p8_1_1}</p>
						<p className="text-gray fs_1rem">{languageData.p8_1_2}</p>
						<p className="text-gray fs_1rem">{languageData.p8_1_3}</p>
					</div>
					<p className="text-gray fs_1rem">{languageData.p8_2}</p>
          <div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p8_2_1}</p>
					</div>
					<p className="text-gray fs_1rem">{languageData.p8_final}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p9}</h3>
					<p className="text-gray fs_1rem">{languageData.p9_1}</p>
          <div className="cities-container__section ms-3">
						<p className="text-gray fs_1rem">{languageData.p9_1_1}</p>
					</div>
					<p className="text-gray fs_1rem">{languageData.p9_2}</p>
          <div className="cities-container__section ms-3">
					  <p className="text-gray fs_1rem">{languageData.p9_2_1}</p>
					  <p className="text-gray fs_1rem">{languageData.p9_2_2}</p>
            <div className="cities-container__section ms-3">
              <p className="text-gray fs_1rem">{languageData.p9_2_3}</p>
              <p className="text-gray fs_1rem">{languageData.p9_2_4}</p>
              <p className="text-gray fs_1rem">{languageData.p9_2_5}</p>
              <p className="text-gray fs_1rem">{languageData.p9_2_6}</p>
            </div>
					</div>
					<p className="text-gray fs_1rem">{languageData.p9_3}</p>
          <div className="cities-container__section ms-3">
					  <p className="text-gray fs_1rem">{languageData.p9_3_1}</p>
					  <p className="text-gray fs_1rem">{languageData.p9_3_2}</p>
					</div>
					<p className="text-gray fs_1rem">{languageData.p9_final}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p10}</h3>
					<p className="text-gray fs_1rem">{languageData.p10_usuarios}</p>
          <div className="cities-container__section ms-3">
            <p className="text-gray fs_1rem">{languageData.p10_usuarios_1}</p>
            <p className="text-gray fs_1rem">{languageData.p10_usuarios_2}</p>
          </div>
					<p className="text-gray fs_1rem">{languageData.p10_conducta}</p>
          <div className="cities-container__section ms-3">
            <p className="text-gray fs_1rem">{languageData.p10_conducta_general_1}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_general_2}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_general_3}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_general_4}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_general_5}</p>
          </div>
					<p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida}</p>
          <div className="cities-container__section ms-3">
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_1}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_2}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_3}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_4}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_5}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_6}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_7}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_8}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_9}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_10}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_11}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_12}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_13}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_14}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_prohibida_15}</p>
          </div>
          <p className="text-gray fs_1rem">{languageData.p10_conducta_spacease}</p>
          <div className="cities-container__section ms-3">
            <p className="text-gray fs_1rem">{languageData.p10_conducta_spacease_1}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_spacease_2}</p>
            <p className="text-gray fs_1rem">{languageData.p10_conducta_spacease_3}</p>
          </div>
          <p className="text-gray fs_1rem">{languageData.p10_derechos_spacease}</p>
          <div className="cities-container__section ms-3">
            <p className="text-gray fs_1rem">{languageData.p10_derechos_spacease_1}</p>
            <p className="text-gray fs_1rem">{languageData.p10_derechos_spacease_2}</p>
            <p className="text-gray fs_1rem">{languageData.p10_derechos_spacease_3}</p>
          </div>
          <p className="text-gray fs_1rem">{languageData.p10_final}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p11}</h3>
          <p className="text-gray fs_1rem">{languageData.p11_condiciones_anfitriones}</p>
          <div className="cities-container__section ms-3">
            <p className="text-gray fs_1rem">{languageData.p11_condiciones_anfitriones_1}</p>
            <p className="text-gray fs_1rem">{languageData.p11_condiciones_anfitriones_2}</p>
          </div>
          <p className="text-gray fs_1rem">{languageData.p11_condiciones_invitados}</p>
          <div className="cities-container__section ms-3">
            <p className="text-gray fs_1rem">{languageData.p11_condiciones_invitados_1}</p>
            <p className="text-gray fs_1rem">{languageData.p11_condiciones_invitados_2}</p>
            <p className="text-gray fs_1rem">{languageData.p11_condiciones_invitados_3}</p>
          </div>
          <p className="text-gray fs_1rem">{languageData.p11_final}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p12}</h3>
          <p className="text-gray fs_1rem">{languageData.p12_text}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p13}</h3>
          <p className="text-gray fs_1rem">{languageData.p13_text}</p>
          <h3 className="text-black fs_1-5rem">{languageData.p14}</h3>
          <p className="text-gray fs_1rem">{languageData.p14_text}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p15}</h3>
          <p className="text-gray fs_1rem">{languageData.p15_text}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p16}</h3>
          <p className="text-gray fs_1rem">{languageData.p16_text}</p>

          <h3 className="text-black fs_1-5rem">{languageData.p17}</h3>
          <p className="text-gray fs_1rem">{languageData.p17_text}</p>

        </section>
			</main>
		</div>
	);
};

export default TermsOfUse;
