import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import cssCities from "../css/cities.module.css"
import { useAppContext } from "../context/appContext";
import { Helmet } from 'react-helmet';


const Cities = () => {
  
    const cities = ["Madrid", "Barcelona", "Bilbao", "Valencia", "Alicante", "Murcia", "Albacete"];
    const { setSelectedCity } = useAppContext();

    const location = useLocation();
    return (
        <div>
          <Helmet>
        {/* Título básico */}
        <title>Nuestras ciudades - Spacease</title>

        {/* Caracteres y idioma */}
        <meta charSet="utf-8" />
        <html lang="es" />

        {/* Descripción de la página */}
        <meta name="description" content="Consulta las ciudades en las que ofrecemos espacios" />

        {/* Mobile Meta */}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

        {/* Open Graph (para Facebook y LinkedIn) */}
        <meta property="og:title" content="Nuestras ciudades - Spacease" />
        <meta property="og:description" content="Consulta las ciudades en las que ofrecemos espacios" />
        <meta property="og:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Spacease" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@Spacease_es" />
        <meta name="twitter:title" content="Nuestras ciudades - Spacease" />
        <meta name="twitter:description" content="Consulta las ciudades en las que ofrecemos espacios" />
        <meta name="twitter:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg" />

        {/* Favicon */}
        <link rel="icon" type="image/png" href="https://www.spacease.com/favicon.ico" />

        {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
        <link rel="canonical" href={`https://www.spacease.com${location.pathname}`} />
        
    </Helmet>
          <main className={cssCities["main__container"]}>

            <h1 className={cssCities["main__container__title"]}>Ciudades de Spacease</h1>
            <p className={cssCities["main__container__description"]}>Descubre los mejores locales comerciales y espacios de retail que Spacease ofrece en las siguientes ciudades</p>
            <h2 className={cssCities["main__container__country"]}>España</h2>
            <div className={cssCities["main__container__links-container"]}>
              {cities.map(city => (
                    <Link key={city} to={`/?city=${city}`} className={cssCities["main__container__links-container__link"]} onClick={(e)=>{setSelectedCity(city)}}>
                        {city}
                    </Link>
                ))}
            </div>
          </main>
        </div>
      );
};

export default Cities;
