const WhiteButtonSvg = (props) =>{
    const{mainText,svg,secondaryText} = props;

    return(
        <div  className="simple_white_button" style={{padding:"0.9rem 1rem"}} >
            <div className="d-flex gap_0-62rem align-items-center d-flex  ">
                {svg}
                <div className="d-flex flex-column">
                <p className="m-0 fs_1rem text-black fw_600">{mainText}</p>
                <p className="m-0 text-gray fw_500 fs_0-875rem">{secondaryText}</p>
                </div>
            </div>
        </div>
    )
}

export default WhiteButtonSvg;