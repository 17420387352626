import { useAppContext } from '../context/appContext'
import { Navigate } from 'react-router-dom'
import { URL_LOGIN } from '../context/urlPath'
import { CircularProgress } from '@mui/material'

const ProtectedRoute = ({children}) => {
    const { user, userLoading }= useAppContext()
    if(userLoading) return <CircularProgress />;
    if(!user){ 
        return <Navigate to={URL_LOGIN}/>
    }
  return children
}

export default ProtectedRoute
