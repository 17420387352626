import React from "react";
import { Container } from "react-bootstrap";
const HelpCenter = () => {
  return (
    <Container className="container__text">
      <h2 className="text-blue">Centro de ayuda</h2>
      <h3>Texto de Centro de ayuda</h3>
    </Container>
  );
};

export default HelpCenter;
