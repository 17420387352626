import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { about_us_1, about_us_2, about_us_3, about_us_4, about_us_5 } from "../Assets";

import cssAboutUs from "../css/aboutUs.module.css";
const AboutUs = () => {
	const location = useLocation();
	return (
		<div>
			<Helmet>
				{/* Título básico */}
				<title>Sobre nosotros - Spacease</title>

				{/* Caracteres y idioma */}
				<meta charSet="utf-8" />
				<html lang="es" />

				{/* Descripción de la página */}
				<meta
					name="description"
					content="Spacease es el primer marketplace Español de alquileres de locales comerciales. Spacease surgió al notar la dificultad de encontrar locales comerciales de calidad en las plataformas inmobiliarias españolas."
				/>

				{/* Mobile Meta */}
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>

				{/* Open Graph (para Facebook y LinkedIn) */}
				<meta property="og:title" content="Sobre nosotros - Spacease" />
				<meta
					property="og:description"
					content="Spacease es el primer marketplace Español de alquileres de locales comerciales. Spacease surgió al notar la dificultad de encontrar locales comerciales de calidad en las plataformas inmobiliarias españolas."
				/>
				<meta
					property="og:image"
					content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Spacease" />

				{/* Twitter Card */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content="@Spacease_es" />
				<meta name="twitter:title" content="Sobre nosotros - Spacease" />
				<meta
					name="twitter:description"
					content="Spacease es el primer marketplace Español de alquileres de locales comerciales. Spacease surgió al notar la dificultad de encontrar locales comerciales de calidad en las plataformas inmobiliarias españolas."
				/>
				<meta
					name="twitter:image"
					content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png"
				/>

				{/* Favicon */}
				<link
					rel="icon"
					type="image/png"
					href="https://www.spacease.com/favicon.ico"
				/>

				{/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
				<link
					rel="canonical"
					href={`https://www.spacease.com${location.pathname}`}
				/>
			</Helmet>
			<div className={cssAboutUs["main__container"]}>
				<header className={cssAboutUs["main__container__header__container"]}>
					<h1 className={cssAboutUs["main__container__header__container__mainText"]}>
						Sobre Spacease
					</h1>
					<p className={cssAboutUs["main__container__header__container__subText"]}
					>
						Spacease es el primer marketplace Español de alquileres de locales
						comerciales
					</p>
					<div className={cssAboutUs["main__container__header__container__image-container"]}>
						<img
							src={about_us_1}
							className={
								cssAboutUs[
									"main__container__header__container__image-container__image"
								]
							}
							alt=""
						/>
					</div>
				</header>
				<h2 className={cssAboutUs["main__container__testimonio"]}>
					“Si tienes una idea, tienes un espacio”
				</h2>
				<section className={cssAboutUs["main__container__section"]}>
          <aside className={cssAboutUs["main__container__section__aside"]}>
            <h3 className={cssAboutUs["main__container__section__aside__mainText"]}>
              Nuestra misión
            </h3>
            <p className={cssAboutUs["main__container__section__aside__subText"]}>
              Queremos conectar personas y lugares excepcionales para impulsar negocios que revitalicen nuestras ciudades.
            </p>
          </aside>
          <div className={cssAboutUs["main__container__section__image-container"]}>
            <img className={cssAboutUs["main__container__section__image-container__image"]} src={about_us_2} alt="" />
          </div>
        </section>
				<section className={cssAboutUs["main__container__section"]}>
          <div className={cssAboutUs["main__container__section__image-container"]}>
            <img className={cssAboutUs["main__container__section__image-container__image"]} src={about_us_3} alt="" />
          </div>
          <aside className={cssAboutUs["main__container__section__aside"]}>
            <h3 className={cssAboutUs["main__container__section__aside__mainText"]}>
            Nuestra visión
            </h3>
            <p className={cssAboutUs["main__container__section__aside__subText"]}>
            El canal de venta físico es fundamental para ofrecer una experiencia cercana al cliente. Por eso, facilitamos la apertura de nuevos negocios, conectando locales con emprendedores que quieren dar vida a sus ideas.
            </p>
          </aside>
        </section>
				<section className={cssAboutUs["main__container__section"]}>
          <aside className={cssAboutUs["main__container__section__aside"]}>
            <h3 className={cssAboutUs["main__container__section__aside__mainText"]}>
            La idea
            </h3>
            <p className={cssAboutUs["main__container__section__aside__subText"]}>
            Spacease surgió al notar la dificultad de encontrar locales comerciales de calidad en las plataformas inmobiliarias españolas. La necesidad de tomar decisiones de inversión sin una forma sencilla de comparar locales nos impulsó a crear esta plataforma.
            </p>
          </aside>
          <div className={cssAboutUs["main__container__section__image-container"]}>
            <img className={cssAboutUs["main__container__section__image-container__image"]} src={about_us_4} alt="" />
          </div>
        </section>
				<section className={cssAboutUs["main__container__section"]}>
          <div className={cssAboutUs["main__container__section__image-container"]}>
            <img className={cssAboutUs["main__container__section__image-container__image"]} src={about_us_5} alt="" />
          </div>
          <aside className={cssAboutUs["main__container__section__aside"]}>
            <h3 className={cssAboutUs["main__container__section__aside__mainText"]}>
            ¿Quienes somos?
            </h3>
            <p className={cssAboutUs["main__container__section__aside__subText"]}>
            Somos una startup tecnológica española en crecimiento constante. Nuestra meta es proporcionar una comunidad de profesionales del retail y eventos junto con el mejor servicio, para hacer realidad tus ideas de manera más sencilla
            </p>
          </aside>
         
        </section>
			</div>
		</div>
	);
};

export default AboutUs;
