
export const customTextField = {
  "& .MuiInputBase-root": {
    borderRadius: "0.625rem",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.25)",
    "&.MuiOutlinedInput-root.Mui-error": {
      border: "1px solid #d32f2f !important",
    },
    "&.MuiOutlinedInput-root.Mui-error.MuiOutlinedInput-notchedOutline": {},
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // border: '1px solid rgba(0, 0, 0, 0.25)'
    borderWidth: "0",
  },
  "& .MuiInputBase-root.Mui-focused": {
    // border: `1px solid rgba(0, 0, 0, 0.25)`,
  },
  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      // border: '0px solid rgba(0, 0, 0, 0.25)', // Cambia el color del borde del notchedOutline al hacer hover
    },
  },
  // Aplicar los estilos al notchedOutline cuando el TextField está enfocado
  [`& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
    border: `0px`,
  },
};

export const errorLabel = {
  fontFamily: "inherit",
  fontSize: ".875rem",
  fontWeight: "500",
};
export const errorLabel1Rem = {
  fontFamily: "inherit",
  fontSize: "1rem",
  fontWeight: "500",
};

export const cardContactInfoSpace = {
  "&": {
    display: "flex",
    flexDirection: "column",
    gap: ".62rem",
    borderRadius: ".93rem",
    position: "sticky",
    zIndex: "200",
    padding: "2rem",
    top: "100px",
    boxShadow: "0px 4px 10px 4px rgba(0, 40, 133, 0.07)",
  },
};

export const customTab = {
  textTransform: "none",
  fontFamily: "inherit",
  alignItems:"self-start",
  minWidth:"15rem",
  color:"#717171",
  fontWeight:"500",
  fontSize:"1rem",
  "&.Mui-selected":{
    color:"#034CF3",
    fontWeight:"600"
  },
  "@media (max-width:75em)":{
    minWidth:"unset",
    padding:"1.5rem .75rem",
    alignItems:"center"
  }
};

export const toogleButtonSimple = {
  '&':{
    margin:'0 !important',
    borderRadius:"0.94rem !important",
    border:"2px solid #DEE2E6 !important",
    width:"100%",
    padding:"1.12rem 1.5rem",
    textTransform:"none",
    fontFamily:"inherit"
  }
}
export const toogleButtonGroupSimple ={
  '&':{
    display:"flex",
    flexDirection:"column"
  }
}
export const toogleButtonGroupSimpleGap ={
  '&':{
    display:"flex",
    flexDirection:"column",
    gap:"1.25rem"
  }
}

export const accordion_blue_background= {
  "&":{
      background: "#F2F6FE",
      border: "0px",
      borderBottom:"1px solid rgba(0, 0, 0, 0.12)",
      borderRadius:"0 !important",
      boxShadow:"none",
      ":before":{
      height:"0"
     }
    },
    "&.Mui-expanded":{
      margin:"0"
    }

}

export const accordionSummary_owners = {
  "&": {
    padding: "0.625rem 0",
    margin: "0",
  }
}

export const simple_blue_toogleButton ={
  "&":{
    textTransform:"none",
    fontFamily:"inherit",
    padding:"0.5rem 1.5rem",
    margin:"0 !important",
    color:"#717171",
    fontWeight:"500",
    whiteSpace:"nowrap",
    backgroundColor:"#FFF",
    borderLeft:"1px solid",
    borderRadius:"0.63rem !important",
    border:"1px solid rgba(34, 34, 34, 0.25) !important",
    borderColor:"rgba(34, 34, 34, 0.25)",
    ":hover":{
      border:"1px solid rgba(34, 34, 34, 0.25) !important",
    }
  },
  "&.Mui-selected":{
    color: "#034CF3",
    border: "1px solid #034CF3 !important",
    background: "rgba(3, 76, 243, 0.05)",
    ":hover":{
    background: "rgba(3, 76, 243, 0.05)",
    }
  },
  "& .MuiTouchRipple-root":{
    zIndex:"-1",
    backgroundColor:"#FFF"
  }
}
export const slidder_blue_toogleButton ={
  "&":{
    textTransform:"none",
    fontFamily:"inherit",
    padding:"0 !important",
    borderRadius:"0 !important",
    color:"#717171",
    height:"56px",
    fontWeight:"500",
    whiteSpace:"nowrap",
    border:"0 !important",
    backgroundColor:"#FFF !important",
    ":hover":{
      color:"#222",
    }
  },
  "&.Mui-selected":{
    border: "0 !important",
    boxShadow:"inset 0 -2px #111",
    ":hover":{
    backgroundColor: "#FFF",
    }
  }
  // "& .MuiTouchRipple-root":{
  //   // backgroundColor:"#FFF"
  // }
}

export const select_phone_number_flags= {
  "& .MuiSelect-select > div > p:nth-of-type(2)": {
    display: "none !important",
  },
  "& .MuiSelect-select > div > p:first-child": {
    margin: "0 !important",
  },
  "& .MuiSelect-select > div": {
    margin: "0 .2rem !important",
  },
}

export const customImagesList = {
  "& .MuiImageListItem-root":{
      "&:hover":{
          filter:"brightness(75%)",
          WebkitFilter:"brightness(75%)",
          cursor:"pointer"
      },
      minHeight:"100%"
  }
}

export const half_size_image_space = { 
  width: "100%", 
  minHeight: "100%",
  // maxHeight:"18rem"
  maxHeight:"fit-content"
}

export const full_size_image_space = { 
  width: "100%",
  height: "100%",
  // kmaxHeight:"36.25rem"
}