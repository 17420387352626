import { useCallback, useState } from "react";
import { MinMaxLenghtValues } from "../helpers/Consts";
export const useImageUploader = () => {
  const [images, setImages] = useState([]);
  const [countAllImages, setCountAllImages] = useState(0);
  const [errorMessageImages, setErrorMessageImages] = useState(null);
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [errorMessageTimer, setErrorMessageTimer] = useState(null);
  const allowedExtensions = [".png", ".jpg", ".jpeg"];
  const maxSizeMB = 10;

  const handleImageChange = (event) => {
    const newImages = Array.from(event.target.files);
    if (newImages.length + countAllImages <= MinMaxLenghtValues.images.max) {
      const maxSizeInBytes = maxSizeMB * 1024 * 1024; // 10MB

      const oversizedImages = newImages.filter((image) => {
        return image.size > maxSizeInBytes;
      });
      if (oversizedImages.length > 0) {
        const oversizedImageNames = oversizedImages
          .map((image) => image.name)
          .join(", ");
        setErrorMessageImages(
          `Error: Una o más imágenes exceden el tamaño máximo permitido (${maxSizeMB} MB): ${oversizedImageNames}`
        );
        resetErrorMessage();
        return;
      }
      const validImages = newImages.filter((image) => {
        const imageExtension = image.name.split(".").pop().toLowerCase();
        const isValidExtension = allowedExtensions.includes(
          `.${imageExtension}`
        );
        const imageName = image.name.split(".").slice(0, -1).join("");

        if (!isValidExtension) {
          setErrorMessageImages(
            `Error: Formato de archivo no admitido - ${image.name}`
          );
          resetErrorMessage();
          return false;
        }
        const isDuplicate = images.some((selectedImage) => {
          const selectedImageExtension = selectedImage.name
            .split(".")
            .pop()
            .toLowerCase();
          const selectedImageName = selectedImage.name
            .split(".")
            .slice(0, -1)
            .join("");
          return (
            selectedImageExtension === imageExtension &&
            selectedImageName === imageName
          );
        });
        if (isDuplicate) {
          setErrorMessageImages(
            `Error: La imagen ${image.name} ya ha sido seleccionada.`
          );
          resetErrorMessage();
          return false;
        }
        return true;
      });

      if(validImages){
        resetCountAllImages(validImages.length);
        setImages((prevImages) => [...prevImages, ...validImages]);
        setErrorMessageImages(null);
      }
      return;
    }
    setErrorMessageImages(
      `Error: estas sobrepasando el maximo de imagenes disponibles.`
    );
    resetErrorMessage();
  };

  const resetErrorMessage = useCallback(() => {
    // Cancela el temporizador existente si hay uno
    if (errorMessageTimer) {
      clearTimeout(errorMessageTimer);
    }

    // Establece un nuevo temporizador
    const timerId = setTimeout(() => {
      setErrorMessageImages(null);
    }, 3500);

    // Almacena la referencia del temporizador en el estado
    setErrorMessageTimer(timerId);
  }, [errorMessageTimer]);

  const handleRemoveImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    resetCountAllImages(-1);
  };

  const handleRemoveImageUrl = (index, space, handleSpaceImage) => {
    if (space) {
      setImagesToRemove((prev) => [...prev, space.images.at(index)]);
      space.images.splice(index, 1);
      resetCountAllImages(-1);
    }
  };
  const resetCountAllImages = (number, concat) => {
    if (concat) {
      setCountAllImages(number);
    } else {
      setCountAllImages((prev) => prev + number);
    }
  };

  return {
    images,
    countAllImages,
    handleImageChange,
    handleRemoveImage,
    imagesToRemove,
    resetCountAllImages,
    errorMessageImages,
    handleRemoveImageUrl,
  };
};
