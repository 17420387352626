import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Form, Button, Modal } from "react-bootstrap";
import "../css/resetPassword.css";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidToken, setIsValidToken] = useState(true);
  const [loading, setLoading] = useState(false); // Nuevo estado para controlar el botón
  const [passwordError, setPasswordError] = useState(false); // Nuevo estado para error de contraseña
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [invalidTokenModal, setInvalidTokenModal] = useState(false); // Modal para token inválido

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");

    axios
      .post("/api/auth/reset-password/verify-token", { token })
      .then((response) => {
        if (response.data.isValid) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
          setInvalidTokenModal(true); // Mostrar modal si el token no es válido
        }
      })
      .catch((error) => {
        console.error("Error verifying token:", error);
        setIsValidToken(false);
        setInvalidTokenModal(true); // Mostrar modal en caso de error
      });
  }, [location.search]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setPasswordError(false); // Resetear error

    if (!isValidToken) {
      setInvalidTokenModal(true); // Mostrar modal si el token no es válido
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError(true); // Mostrar error
      return;
    }

    setLoading(true); // Deshabilita el botón

    const token = new URLSearchParams(location.search).get("token");
    axios
      .post("/api/auth/reset-password/confirm", {
        token: token,
        newPassword: password,
      })
      .then((response) => {
        setShowModal(true);
        setLoading(false); // Habilitar botón
      })
      .catch((error) => {
        console.error("Error al actualizar la contraseña:", error);
        setLoading(false); // Habilitar botón
        if (error.response) {
          alert(
            error.response.data.message || "Error al actualizar la contraseña."
          );
        } else {
          alert("Error al actualizar la contraseña.");
        }
      });
  };

  const handleRedirect = () => {
    navigate("/"); // Redirigir al inicio
  };

  const handleCloseModal = () => {
    setInvalidTokenModal(false);
    setTimeout(handleRedirect, 2000); // Redirige después de 2 segundos
  };

  return (
    <>
      {invalidTokenModal && (
        <Modal
          show={invalidTokenModal}
          onHide={handleCloseModal}
          onExited={handleRedirect} // Asegurarse de que la redirección ocurra después de que el modal se haya cerrado
        >
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            El link ha caducado o ya no es válido.
          </Modal.Body>
        </Modal>
      )}
      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Contraseña Actualizada</Modal.Title>
          </Modal.Header>
          <Modal.Body>La contraseña se ha actualizado con éxito.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => navigate("/")}>
              Ir a Inicio
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <div>
        <Form onSubmit={handleSubmit} className="form__login">
          <h2>Restablecer contraseña</h2>
          {isValidToken ? (
            <>
              <p>
                Este link solo puede usarse una vez. Si no reseteas la
                contraseña ahora tendrás que solicitar un nuevo email de
                recuperación.
              </p>
              <Form.Group className="form__login-inputs">
                <Form.Control
                  type="password"
                  placeholder="Nueva Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control
                  type="password"
                  placeholder="Confirmar nueva contraseña"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                {passwordError && (
                  <div style={{ color: "red" }}>
                    Las contraseñas no coinciden
                  </div>
                )}
              </Form.Group>
              <div className="justify-content-center d-flex">
                <Button
                  className="button-blue"
                  variant="primary"
                  type="submit"
                  disabled={loading}
                >
                  Cambiar contraseña
                </Button>
              </div>
            </>
          ) : (
            <p>El link ha caducado o ya no es válido.</p>
          )}
        </Form>
      </div>
    </>
  );
}

export default ResetPassword;
