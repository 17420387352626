import {  useLocation } from "react-router-dom";
import { URL_CONTACT, URL_DASHBOARD, URL_LANDING_CREATE_SPACE } from "../context/urlPath";
import { home_1, home_2, home_3, home_4, home_5,plant_img } from "../Assets";
import cssLanding from "../css/landingPage.module.css";
import React from "react";
// import { Link } from "react-router-dom";
import {Link} from "@mui/material";
import { SVG } from "../helpers/Svgs";
import { Helmet } from "react-helmet";

const Landing = () => {
	const location = useLocation();
	return (
		<>
			<Helmet>
				{/* Título básico */}
				<title>Alquileres de locales comerciales - Spacease</title>

				{/* Caracteres y idioma */}
				<meta charSet="utf-8" />
				<html lang="es" />

				{/* Descripción de la página */}
				<meta
					name="description"
					content="Compara miles de locales y tiendas de forma fácil y segura. Anuncios con información detallada para que puedas filtrar y alquilar según tus necesidades, todo en un mismo lugar."
				/>

				{/* Mobile Meta */}
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>

				{/* Open Graph (para Facebook y LinkedIn) */}
				<meta
					property="og:title"
					content="¿Cómo funciona la plataforma? - Spacease"
				/>
				<meta
					property="og:description"
					content="Busca miles de locales y tiendas de forma fácil y segura. Anuncios con información detallada para que puedas filtrar y alquilar según tus necesidades, todo en un mismo lugar."
				/>
				<meta
					property="og:image"
					content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png"
				/>
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Spacease" />

				{/* Twitter Card */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content="@Spacease_es" />
				<meta
					name="twitter:title"
					content="¿Cómo funciona la plataforma? - Spacease"
				/>
				<meta
					name="twitter:description"
					content="Busca miles de locales y tiendas de forma fácil y segura. Anuncios con información detallada para que puedas filtrar y alquilar según tus necesidades, todo en un mismo lugar."
				/>
				<meta
					name="twitter:image"
					content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png"
				/>

				{/* Favicon */}
				<link
					rel="icon"
					type="image/png"
					href="https://www.spacease.com/favicon.ico"
				/>

				{/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
				<link
					rel="canonical"
					href={`https://www.spacease.com${location.pathname}`}
				/>
			</Helmet>
			<div>
				<main className={cssLanding["landing__Container"]}>
					<header className={cssLanding["header__container"]}>
						<h1 className={cssLanding["header__container__mainText"]}>
							Alquila las mejores tiendas y locales comerciales de tu ciudad en
							unos clicks 🙌
						</h1>
						<p className={cssLanding["header__container__subText"]}>
							Spacease es la forma más fácil de descubrir, alquilar y subir
							espacios de retail para que puedas hacer realidad tus ideas.
						</p>
						<Link
							href={URL_DASHBOARD}
							
							className="w_fit-content simple_blue_button text-decoration-none"
						>
							Buscar espacios{" "}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="16"
								height="17"
								viewBox="0 0 16 17"
								fill="none"
							>
								<path
									d="M15 15.5L11.3793 11.8793M1 7.53448C1 4.20173 3.70173 1.5 7.03448 1.5C10.3673 1.5 13.069 4.20173 13.069 7.53448C13.069 10.8673 10.3673 13.569 7.03448 13.569C3.70173 13.569 1 10.8673 1 7.53448Z"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</Link>
					</header>
					<section className={cssLanding["section__container"]}>
						<aside className={cssLanding["section__container__aside"]}>
							<p className={cssLanding["section__container__header"]}>
								Para marcas y propietarios
							</p>
							<h3 className={cssLanding["section__container__title"]}>
								Busca espacios de retail u ofrece el tuyo a miles de marcas 🤝
							</h3>
							<p className={cssLanding["section__container__description"]}>
								Conectando marcas y empresas con propietarios de espacios. Tanto
								si necesitas una pop-up store como algo a más largo plazo, lo
								podrás encontrar en Spacease.
							</p>
							<Link
								href={URL_DASHBOARD}
								className={cssLanding["section__container__link"]}
							>
								Echar un vistazo {SVG.FLECHA_DERECHA_AZUL}
							</Link>
						</aside>
						<div className={cssLanding["section__container__image"]}>
							<img
								className={cssLanding["section__image"]}
								src={home_1}
								alt=""
							/>
						</div>
					</section>
					<section className={cssLanding["section__container"]}>
						<div className={cssLanding["section__container__image"]}>
							<img
								className={cssLanding["section__image"]}
								src={home_2}
								alt=""
							/>
						</div>
						<aside className={cssLanding["section__container__aside"]}>
							<p className={cssLanding["section__container__header"]}>
								Descubrir espacios
							</p>
							<h3 className={cssLanding["section__container__title"]}>
								Encuentra el local comercial perfecto 👀
							</h3>
							<p className={cssLanding["section__container__description"]}>
								Descubre y compara locales comerciales únicos adaptados a tus
								necesidades. Reserva por día, semana, mes o año.
							</p>
							<Link
								href={URL_DASHBOARD}
								className={cssLanding["section__container__link"]}
							>
								Buscar espacios {SVG.FLECHA_DERECHA_AZUL}
							</Link>
						</aside>
					</section>
					<section className={cssLanding["section__container"]}>
						<aside className={cssLanding["section__container__aside"]}>
							<p className={cssLanding["section__container__header"]}>
								Información actualizada
							</p>
							<h3 className={cssLanding["section__container__title"]}>
								Espacios comerciales con información detallada 📇
							</h3>
							<p className={cssLanding["section__container__description"]}>
								Toma decisiones informadas teniendo en cuenta datos como el
								número de visitantes diario, la visibilidad del espacio,
								superficie utilizable, descripción del barrio en el que se
								encuentra y mucho más.
							</p>
						</aside>
						<div className={cssLanding["section__container__image"]}>
							<img
								className={cssLanding["section__image"]}
								src={home_3}
								alt=""
							/>
						</div>
					</section>
					<section className={cssLanding["section__container"]}>
						<div className={cssLanding["section__container__image"]}>
							<img
								className={cssLanding["section__image"]}
								src={home_4}
								alt=""
							/>
						</div>
						<aside className={cssLanding["section__container__aside"]}>
							<p className={cssLanding["section__container__header"]}>
								Seguridad
							</p>
							<h3 className={cssLanding["section__container__title"]}>
								Solo lo mejor para tu idea
							</h3>
							<p className={cssLanding["section__container__description"]}>
								Revisamos manualmente todos los espacios antes de ser
								publicados, asegurándonos de que los anuncios cumplen con los
								requisitos de la plataforma.
							</p>
							<Link
								href={URL_DASHBOARD}
								className={cssLanding["section__container__link"]}
							>
								Suena bien {SVG.FLECHA_DERECHA_AZUL}
							</Link>
						</aside>
					</section>
					<section className={cssLanding["section__container"]}>
						<aside className={cssLanding["section__container__aside"]}>
							<p className={cssLanding["section__container__header"]}>
								Anunciarse en Spacease
							</p>
							<h3 className={cssLanding["section__container__title"]}>
								Sube tu espacio a la plataforma 📥
							</h3>
							<p className={cssLanding["section__container__description"]}>
								Llega a miles de marcas que están buscando donde establecerse y
								únete gratuitamente a la mejor comunidad de propietarios de
								retail de España
							</p>
							<Link
								href={URL_LANDING_CREATE_SPACE}
								className={cssLanding["section__container__link"]}
							>
								Quiero saber más {SVG.FLECHA_DERECHA_AZUL}
							</Link>
						</aside>
						<div className={cssLanding["section__container__image"]}>
							<img
								style={{ objectPosition: "0 0" }}
								className={cssLanding["section__image"]}
								src={home_5}
								alt=""
							/>
						</div>
					</section>
				</main>
			</div>

			<section className={cssLanding["landingBanner__container"]}>
				<aside className={cssLanding["landingBanner__container__aside"]}>
					<h3 className={cssLanding["landingBanner__container__text"]}>¿Necesitas ayuda buscando el espacio perfecto o subiendo el tuyo?</h3>
					<Link href={URL_CONTACT}
						style={{ textDecoration: "none" }}
						className="w_fit-content simple_blue_button">Contacta con nuestro equipo</Link>
				</aside>
				<div className={cssLanding["landingBanner__container__image-container"]}>
					<img className={cssLanding["landingBanner__container__image-container__image"]} src={plant_img} alt="" />
				</div>
			</section>
		</>
	);
};

export default Landing;
