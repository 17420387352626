import { keyframes } from "@emotion/react";
import {
	Avatar,
	Backdrop,
	Box,
	Card,
	Chip,
	CircularProgress,
	Fade,
	ImageList,
	ImageListItem,
	Modal,
	Stack,
	useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import Divider from "../components/Divider.js";
import Map from "../components/Map";
import { Helmet } from "react-helmet";
import ShowMore from "../components/ShowMore";
import {
	cardContactInfoSpace,
	customImagesList,
	full_size_image_space,
	half_size_image_space,
} from "../components/Styles";
import WhiteButtonSvg from "../components/WhiteButtonSvg";
import { useAppContext } from "../context/appContext";
import {
	AvgVisitorData,
	DaysInfo,
	SizeSpaceData,
	getStateInfo,
	getVisibilityInfo,
} from "../context/modelHelper";
import { URL_LOGIN, URL_REGISTER } from "../context/urlPath";
import "../css/space.css";
import { AttributesEnum, Categories_Data } from "../helpers/Consts";
import { SVG } from "../helpers/Svgs";
import Error from "./Error";
import { ENDPOINT_CONTACT_INFO } from "../context/endpoints.js";

const Space = () => {
	const { id } = useParams();
	const { user, spaceById, isLoading, getSpaceById,authFetch} = useAppContext();
	const [showContactInfo, setShowContactInfo] = useState(false);
	const [isOpenAllImages, setIsOpenAllImages] = useState(false);
	const [contactInfoData,setContactInfoData] = useState({email:"",name:"",phoneNumber:"",profilePicture:"",countryCode:""});
	const moreThan1300Px = useMediaQuery('(min-width:1300px)');
	const lessThan745Px = useMediaQuery('(min-width:745px)')

	const location = useLocation();

	let visibility, avgVisitors, sizeSpace, stateSpace, days;
	if (spaceById._id) {
		visibility = getVisibilityInfo(spaceById.visibility);
		avgVisitors = AvgVisitorData(spaceById.avgVisitors);
		sizeSpace = SizeSpaceData(spaceById.spaceSize);
		stateSpace = getStateInfo(spaceById.state);
		days = DaysInfo(spaceById.minDays, spaceById.maxDays);
	}
	const slideInAnimation = keyframes`
   0% {
     transform: translateY(100%);
     opacity: 0;
   }
   100% {
     transform: translateY(0);
     opacity: 1;
   }
 `;
	useEffect(() => {
		getSpaceById(id);
	}, []);

	const handleIsOpenAllImages = () => {
		setIsOpenAllImages(!isOpenAllImages);
	};

	async function getContactInfo (idUser){
		try{
			const response = await authFetch.post(ENDPOINT_CONTACT_INFO,{idUser:idUser});
			setContactInfoData(response.data)
		}catch(error){
			console.log(error)
		}
	}

	const handleShowContactInfo = () => {
	 getContactInfo(spaceById.createdBy);
	 setShowContactInfo(true);
	};
	const handleShowContactInfoClose = () => {
	 setShowContactInfo(false);
	};

	if (spaceById.length === 0 && !isLoading) {
		return <Error />;
	} else if (isLoading) {
		return (
			<div className="w-100 d-flex justify-content-center align-items-center">
				<CircularProgress />
			</div>
		);
	}

	return (
		<>
			<Helmet>
				{/* Título básico */}
				<title>{spaceById.name} - Spacease</title>

				{/* Caracteres y idioma */}
				<meta charSet="utf-8" />
				<html lang="es" />

				{/* Descripción de la página */}
				<meta
					name="description"
					content={spaceById.description.substring(0, 150)}
				/>

				{/* Mobile Meta */}
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, shrink-to-fit=no"
				/>

				{/* Open Graph (para Facebook y LinkedIn) */}
				<meta property="og:title" content={spaceById.name} />
				<meta
					property="og:description"
					content={spaceById.description.substring(0, 150)}
				/>
				<meta property="og:image" content={spaceById.images[0]} />
				<meta property="og:url" content="https://www.spacease.com/" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Spacease" />

				{/* Twitter Card */}
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content="@Spacease_es" />
				<meta name="twitter:title" content={spaceById.name} />
				<meta
					name="twitter:description"
					content={spaceById.description.substring(0, 150)}
				/>
				<meta name="twitter:image" content={spaceById.images[0]} />

				{/* Favicon */}
				<link
					rel="icon"
					type="image/png"
					href="https://www.spacease.com/favicon.ico"
				/>

				{/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
				<link
					rel="canonical"
					href={`https://www.spacease.com${location.pathname}`}
				/>
			</Helmet>
			<div>
				{spaceById._id && (
					<div className="space__container">
						<Modal
							open={isOpenAllImages}
							closeAfterTransition
							slots={{ backdrop: Backdrop }}
							slotProps={{
								backdrop: {
									timeout: 500,
								},
							}}
							onClose={handleIsOpenAllImages}
						>
							<Fade in={isOpenAllImages}>
								<Box
									sx={{
										position: "absolute",
										top: "0",
										left: "0",
										width: "100vw",
										height: "100%",
										bgcolor: "background.paper",
										boxShadow: 24,
										overflowY: "scroll",
										padding: "0 4rem",
										animation: `${slideInAnimation} 0.5s ease`,
									}}
								>
									<div
										className="d-flex align-items-center sticky-top"
										style={{ backgroundColor: "#fff", height: "3rem" }}
									>
										<div
											onClick={handleIsOpenAllImages}
											className="d-flex justify-content-center align-items-center arrow_back"
											style={{
												height: "30px",
												width: "30px",
												borderRadius: "50%",
											}}
										>
											{SVG.FLECHA_IZQUIERDA}
										</div>
									</div>
									<div>
										<ImageList cols={1}>
											{spaceById.images.map((imgUrl) => (
												<ImageListItem
													sx={{
														"&:hover": {
															filter: "brightness(75%)",
															WebkitFilter: "brightness(75%)",
															cursor: "pointer",
														},
													}}
													key={imgUrl}
													rows={1}
												>
													<img src={imgUrl} className="imageModal" alt="text"></img>
												</ImageListItem>
											))}
										</ImageList>
									</div>
								</Box>
							</Fade>
						</Modal>

						<div className="imagesContainer d-flex w-100 justify-content-center position-relative">
							<ImageList
								cols={4}
								style={{ borderRadius: "12px" }}
								rowHeight={moreThan1300Px ? 300 : lessThan745Px ? 200 : 150}
								sx={customImagesList}
								onClick={handleIsOpenAllImages}
							>
								<ImageListItem cols={2} rows={2}>
									<img
										src={spaceById.images[0]}
										style={full_size_image_space}
										alt="Imagen 1"
										className="d-flex align-items-center justify-content-center"
									/>
								</ImageListItem>
								<ImageListItem cols={1}>
									<img
										src={spaceById.images[1]}
										style={half_size_image_space}
										alt="Imagen 2"
										className="d-flex align-items-center justify-content-center"
									/>
								</ImageListItem>
								<ImageListItem cols={1}>
									<img
										src={spaceById.images[2]}
										style={half_size_image_space}
										alt="Imagen 3"
										className="d-flex align-items-center justify-content-center"
									/>
								</ImageListItem>
								<ImageListItem cols={1}>
									<img
										src={spaceById.images[3]}
										style={half_size_image_space}
										alt="Imagen 2"
										className="d-flex align-items-center justify-content-center"
									/>
								</ImageListItem>
								<ImageListItem cols={1}>
									<img
										src={spaceById.images[4]}
										style={half_size_image_space}
										alt="Imagen 3"
										className="d-flex align-items-center justify-content-center"
									/>
								</ImageListItem>
							</ImageList>
							<Button
								onClick={handleIsOpenAllImages}
								className="simple_white_button fw_600 fs_0-875rem position-absolute"
								style={{
									bottom: "2rem",
									right: "1rem",
									border: "3px solid black ",
								}}
							>
								Mostrar todas las fotos
							</Button>
						</div>

						
							<div
								className="d-flex flex-column gap_1-25rem content__Container"
							>
								<div className="d-flex flex-column">
									<h1 style={{wordBreak:"break-word"}} className="fs_1-5rem text-black fw_600 m-0">
										{spaceById.name}
									</h1>
									<div className="d-flex align-items-center gap_0-62rem">
										{SVG.MARCADOR_LOCALIZACION}
										<p className="fs_1rem m-0 text-gray fw_500">
											{spaceById.street} {spaceById.numberStreet},{" "}
											{spaceById.city}
										</p>
									</div>
									<div className="mt-3">
										<Stack direction="row" sx={{flexWrap:"wrap",gap:"1rem"}}>
											{spaceById.spaceType.map((value, index) =>
												value === 1 ? (
													<Chip
														key={
															Categories_Data[
																Object.keys(Categories_Data)[index]
															]
														}
														sx={{ fontFamily: "inherit" }}
														label={
															Categories_Data[
																Object.keys(Categories_Data)[index]
															]
														}
													/>
												) : null
											)}
										</Stack>
									</div>
								</div>
								<div className="d-flex flex-column gap_1-25rem">
									<p className="fs_1-25rem fw_600 text-black m-0">
										Características destacadas
									</p>
									<div
										className="d-flex flex-wrap gap_1-25rem"
										style={{ maxWidth: "90%" }}
									>
										<WhiteButtonSvg
											mainText={avgVisitors.mainText}
											svg={avgVisitors.svg}
											secondaryText={avgVisitors.secondaryText}
										/>
										<WhiteButtonSvg
											mainText={sizeSpace.mainText}
											svg={sizeSpace.svg}
											secondaryText={sizeSpace.secondaryText}
										/>
										{spaceById.minDays > 0 && spaceById.maxDays > 0 && (
											<WhiteButtonSvg
												mainText={days.mainText}
												svg={days.svg}
												secondaryText={days.secondaryText}
											/>
										)}
										<WhiteButtonSvg
											mainText={visibility.mainText}
											svg={visibility.svg}
											secondaryText={visibility.secondaryText}
										/>
										<WhiteButtonSvg
											mainText={stateSpace.mainText}
											svg={stateSpace.svg}
											secondaryText={stateSpace.secondaryText}
										/>
									</div>
								</div>
								<Divider />
								<div className="d-flex flex-column gap_1-25rem">
									<p className="fs_1-25rem fw_600 text-black m-0">
										Sobre el espacio
									</p>
									<ShowMore
										classNameText="fs_0-875rem fw_400 text-gray"
										text={spaceById.description}
									/>
								</div>
								<div className="d-flex flex-column gap_1-25rem">
									<p className="fs_1-25rem fw_600 text-black m-0">
										El espacio ofrece
									</p>
									<div
										className="space__atributes__container"
									>
										{spaceById.attributes.map((value, index) => {
											if (value === 1) {
												const attribute =
													AttributesEnum[Object.keys(AttributesEnum)[index]];
												return (
													<div
														key={index}
														className="d-flex align-items-center gap_0-62rem"
													>
														{attribute.svg}
														<p className="m-0">{attribute.text}</p>
													</div>
												);
											}
											return null;
										})}
									</div>
								</div>
								<Divider />
								<div className="d-flex flex-column gap_1-25rem">
									<p className="fs_1-25rem fw_600 text-black m-0">
										Sobre el área
									</p>
									<ShowMore
										classNameText="fs_0-875rem fw_400 text-gray"
										text={
											spaceById.neighbourDescription || "Sin descripcion"
										}
									></ShowMore>
								</div>

								<Map
									className="br_0-93rem"
									coordinatesIn={spaceById.coordinates}
									showSpace={true}
								></Map>
							</div>
							<div className=" position-relative contactCard__Container">
								<Card sx={cardContactInfoSpace}>
									<h1 className="m-0 text-black fs_1-25rem fw_600">
										Desde {spaceById.dailyPrice} € / día
									</h1>
									{spaceById?.minDays > 0 && (
										<p className="m-0 text-gray fs_0-75rem fw_400">
											Duración mínima de {spaceById.minDays} día
										</p>
									)}
									{spaceById?.maxDays > 0 && (
										<p className="m-0 text-gray fs_0-75rem fw_400">
											Duración máxima de {spaceById.maxDays} días
										</p>
									)}
									<Divider />
									<p className="m-0 fs_1rem text-black fw_500">Precios</p>
									{spaceById.dailyPrice ? 
										<div className="w-100 d-flex justify-content-between">
											<p className="m-0 text-gray fs_0-875rem fw_400">
												Precio diario
											</p>
											<p className="m-0 text-black fs_0-875rem fw_500 ">
												{spaceById.dailyPrice} €
											</p>
										</div>:<></>
									}
									{spaceById.weeklyPrice ?
										<div className="w-100 d-flex justify-content-between">
											<p className="m-0 text-gray fs_0-875rem fw_400">
												Precio semanal
											</p>
											<p className="m-0 text-black fs_0-875rem fw_500 ">
												{spaceById.weeklyPrice} €
											</p>
										</div>:<></>
									}
									{spaceById.monthlyPrice ?
										<div className="w-100 d-flex justify-content-between">
											<p className="m-0 text-gray fs_0-875rem fw_400">
												Precio mensual
											</p>
											<p className="m-0 text-black fs_0-875rem fw_500 ">
												{spaceById.monthlyPrice} €
											</p>
										</div>
									:<></>}
									<Divider />
									<p className="m-0 text-black fs_1rem fw_500">
										Contactar al dueño
									</p>
									{user ? (
										<>
											<Button
												className="w-100 fs_0-875rem fw_500 simple_white_button p_simple_white_button"
												onClick={handleShowContactInfo}
											>
												Ver información de contacto
											</Button>
											{showContactInfo === false ? (
												<></>
											) : (
												<>
													<Modal
														open={showContactInfo}
														onClose={handleShowContactInfoClose}
														closeAfterTransition
														slots={{ backdrop: Backdrop }}
														slotProps={{
															backdrop: {
																timeout: 500,
															},
														}}
													>
														<Fade in={showContactInfo}>
															<Box
																className="br_0-93rem"
																sx={{
																	position: "absolute",
																	top: "50%", // Centrar verticalmente
																	left: "50%", // Centrar horizontalmente
																	transform: "translate(-50%, -50%)", // Centrar perfectamente
																	width: "37.5rem",
																	maxHeight: "var(--maxHeightShowContactInfo)",
																	bgcolor: "background.paper",
																	overflow: "auto",
																	boxShadow: 24,
																	padding: "2rem",
																	maxWidth:"calc(100vw - 4rem)"
																}}
															>
																<main className="d-flex flex-column gap_1-25rem">
																	<h1 className="m-0 text-black fs_1rem fw_500">
																		Información de contacto
																	</h1>
																	<Avatar
																		style={{ width: "10rem", height: "10rem" }}
																		src={contactInfoData.profilePicture}
																	></Avatar>
																	<p className="m-0 text-black fs_0-875rem fw_600">
																		{contactInfoData.name}
																	</p>
																	<ShowMore
																		text={
																			contactInfoData.biography || "Sin biografía"
																		}
																		classNameText="text-black fw_400 m-0 fs_0-875rem"
																	/>
																	<Divider />
																	<div className="d-flex justify-content-between">
																		<p className="m-0 text-gray fs_0-875rem fw_400">
																			Número de teléfono
																		</p>
																		<p className="text-black m-0 fs_0-875rem fw_500 ">
																			{contactInfoData.countryCode || "Sin prefijo"}{" "}
																			{contactInfoData.phoneNumber || "Sin teléfono"}
																		</p>
																	</div>
																	<div className="d-flex justify-content-between">
																		<p className="m-0 text-gray fs_0-875rem fw_400">
																			Email
																		</p>
																		<p className="m-0 text-black fs_0-875rem fw_500 ">
																			{contactInfoData.email}
																		</p>
																	</div>
																	<Button
																		className="simple_white_button fs_0-875rem fw_500 text-black"
																		onClick={handleShowContactInfoClose}
																	>
																		Cerrar
																	</Button>
																</main>
															</Box>
														</Fade>
													</Modal>
												</>
											)}
										</>
									) : (
										<>
											<Link to={`${URL_LOGIN}?spaceId=${spaceById._id}`}>
												<Button className="w-100 fs_0-875rem fw_500 simple_white_button p_simple_white_button">
													Inicia sesión
												</Button>
											</Link>
											<Link to={URL_REGISTER}>
												<Button className="w-100 fs_0-875rem fw_500 simple_blue_button">
													Registrarse
												</Button>
											</Link>
											<p className="text-gray fs_0-75rem fw_400 text-center">
												Tienes que iniciar sesión o registrarte para ver los
												datos de contacto del dueño
											</p>
										</>
									)}
								</Card>
							</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Space;
