import { Button } from "react-bootstrap";
import { Card, CardContent, CardMedia, Tooltip, Zoom } from "@mui/material";
import "../css/spaceCard.css";
import { SVG } from "../helpers/Svgs";
import { Link } from "react-router-dom";
import { URL_EDIT_SPACE } from "../context/urlPath";
import { PlatformVisibilityTypes } from "../helpers/Consts";
const SpaceCard = (props) => {
  const { space, type, desactivate } = props;
  const {
    _id,
    name,
    street,
    province,
    avgVisitors,
    spaceSize,
    dailyPrice,
    weeklyPrice,
    monthlyPrice,
    platformVisibility,
    images,
  } = space;

  const categoriesColors = {
    Pendiente: "#FFC107", // Amarillo
    Borrador: "#2196F3", // Azul
    Publicado: "#4CAF50", // Verde
    Rechazado: "#F44336", // Rojo
    Inactivo: "#9E9E9E", // Gris
    Desactivado: "#673AB7", // Morado
  };

  const handleClicPlatformSpace = () => {
    if (platformVisibility === PlatformVisibilityTypes.Desactivado) {
      desactivate(_id, PlatformVisibilityTypes.Publicado);
    } else {
      desactivate(_id, PlatformVisibilityTypes.Desactivado);
    }
  };

  return (
    <Card className="card__container">
      <CardMedia
        component="img"
        sx={{
          "&":{
            height:"17.35rem",
            borderRadius:"1rem",
            padding:".5rem"
          }
        }}
        image={images[0]} // images[0] => image
      />
      <CardContent>
        {!type && (
            <Tooltip TransitionComponent={Zoom} TransitionProps={{timeout:600}} placement="top" arrow title={`${street},${province}`}>
          <div className="d-flex position-absolute justify-content-center align-items-center location__container  fw_500 fs_0-75rem text-black gap_0-62rem">
            {SVG.MARCADOR_LOCALIZACION}
            <p>
              {street}, {province}
            </p>
          </div>
            </Tooltip>
        )}
        <div className="description__container d-flex flex-column">
          <Tooltip TransitionComponent={Zoom} TransitionProps={{timeout:600}} placement="top" arrow title={name}>
            <p style={{textOverflow:"ellipsis"}} className="overflow-hidden ">{name}</p>
          </Tooltip>
          {!type && (
            <div className="d-flex align-items-center gap-1">
              {dailyPrice ? 
              <p>{dailyPrice}€ / día </p>
               : 
              weeklyPrice ? 
                <p>{weeklyPrice}€ / semana </p>:
                <p>{monthlyPrice}€ / mes </p>
              }
            </div>
          )}
          {!type && (
            <div className="d-flex data__container">
              <div className="data__container-days">
                {SVG.DOS_USUARIOS_THIN}
                <p>{avgVisitors}/día</p>
              </div>
              <div className="data__container-meters">
                {SVG.CUADRADO_SOLO_ESQUINAS_THIN}
                <p>{spaceSize} m²</p>
              </div>
            </div>
          )}
          {type && (
            <div className="d-flex gap-1">
              <p>Estado:</p>
              <p style={{ color: categoriesColors[platformVisibility] }}>
                {platformVisibility}
              </p>
            </div>
          )}

          {type && (
            <div className="control-panel__container">
              <Link
                to={URL_EDIT_SPACE(_id)}
                className="control-panel__container__button"
              >
                {SVG.LAPIZ}
                Editar
              </Link>
              {platformVisibility === PlatformVisibilityTypes.Desactivado && (
                <Button
                  className="control-panel__container__button control-panel__container__button-disable"
                  onClick={handleClicPlatformSpace}
                >
                 {SVG.OJO_THIN}
                  Activar
                </Button>
              )}
              {platformVisibility === PlatformVisibilityTypes.Publicado && (
                <Button
                  onClick={handleClicPlatformSpace}
                  className="control-panel__container__button control-panel__container__button-disable"
                >
                  {SVG.DESACTIVAR}
                  Desactivar
                </Button>
              )}
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default SpaceCard;
