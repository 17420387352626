import { BrowserRouter, Routes, Route} from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import { URL_DASHBOARD,URL_LOGIN,URL_REGISTER,URL_PROFILE,URL_PANELCONTROL,URL_GETSPACE,URL_UPLOAD_SPACE, URL_PRIVACY_POLICY, URL_TERMS_OF_USE, URL_CONTACT, URL_HELP_CENTER, URL_HOW_DOES_IT_WORK, URL_ABOUT_US, URL_CITIES, URL_RULES, URL_EDIT_SPACE, URL_RESET_PASSWORD, URL_RESET_PASSWORD_REQUEST,URL_LANDING_CREATE_SPACE , URL_LANDING} from './context/urlPath';
import { Landing, Error, Register, Dashboard,ControlPanel,ProfileConfig,Space, UploadSpace, PrivacyPolicy, TermsOfUse, Contact, HelpCenter, HowDoesItWork, AboutUs, Cities, Rules, EditSpace, ResetPassword, ResetPasswordRequest,LandingCreateSpace} from "./pages";

import MiNavbar from './components/MiNavbar';
import ScrollToTop from './components/ScrollToTop';


function App() {

  return (
    <BrowserRouter>
      <ScrollToTop/>
      <MiNavbar />
      <Routes>
        <Route path={URL_DASHBOARD}>
          <Route index element={<Dashboard/>}/>
          <Route path="all-spaces" element={<h1>all-spaces</h1>}/>
          <Route path={URL_GETSPACE()} element={<Space/>}/>
          <Route path={URL_PROFILE} element={<ProtectedRoute><ProfileConfig/></ProtectedRoute>}/>
          <Route path={URL_PANELCONTROL} element={<ProtectedRoute><ControlPanel/></ProtectedRoute>}/>
          <Route path={URL_UPLOAD_SPACE} element={<ProtectedRoute><UploadSpace/></ProtectedRoute>}/>
          <Route path={URL_EDIT_SPACE(':id')} element={<ProtectedRoute><EditSpace/></ProtectedRoute>}/>
        </Route>
        <Route path={URL_REGISTER} element={<Register register/>}/>
        <Route path={URL_LANDING_CREATE_SPACE} element={<LandingCreateSpace/>}/>
        <Route path={URL_LOGIN} element={<Register login/>}/>
        <Route path={URL_LANDING} element={<Landing />}/>
        {/* <Route path = {URL_PRIVACY_POLICY} element={<PrivacyPolicy/>}/> */}
        <Route path ={URL_TERMS_OF_USE} element={<TermsOfUse/>}/>
        <Route path ={URL_CONTACT} element={<Contact/>}/>
        <Route path ={URL_HELP_CENTER} element={<HelpCenter/>}/>
        <Route path ={URL_HOW_DOES_IT_WORK} element={<HowDoesItWork/>}/>
        <Route path ={URL_ABOUT_US} element={<AboutUs/>}/>
        <Route path ={URL_RESET_PASSWORD} element={<ResetPassword/>}/>
        <Route path ={URL_RESET_PASSWORD_REQUEST} element={<ResetPasswordRequest/>}/>
        <Route path ={URL_CITIES} element={<Cities/>}/>
        <Route path ={URL_RULES} element={<Rules/>}/>
        <Route path="*" element={<Error/>}/>
      </Routes>
      <Footer /> 
    </BrowserRouter>
  );
}

export default App;