import React from "react";
import { Link } from "react-router-dom";
import cssLogo from "../css/logo.module.css"
const Logo = (props) =>{
    const {typeLogo} = props
    switch(typeLogo){
        case 'Logo':
            return(
            <>
            <div className={cssLogo.logo__beta}>
                <svg  xmlns="http://www.w3.org/2000/svg" width="133" height="24" viewBox="0 0 133 24" fill="none">
                <path d="M17.7081 24C15.1349 24 12.7152 22.9658 10.8975 21.087L13.0252 18.8913C14.2742 20.1832 15.9385 20.8944 17.7081 20.8944C19.4777 20.8944 21.1389 20.1832 22.3909 18.8944C23.6428 17.6025 24.332 15.8851 24.332 14.059C24.332 12.2329 23.6428 10.5186 22.3939 9.22671L17.7081 4.3913L13.0222 9.22671C8.50492 13.8882 4.12305 14.0994 0 9.84472L2.12774 7.64907C4.57749 10.177 7.00618 11.0404 10.8915 7.03106L17.705 0L24.5186 7.03106C26.3364 8.90683 27.3385 11.4037 27.3385 14.059C27.3385 16.7143 26.3364 19.2081 24.5186 21.087C22.6978 22.9658 20.2782 24 17.705 24H17.7081Z" fill="#034CF3"/>
                <path d="M35.8757 15.1399C36.7756 15.9598 37.865 16.5001 39.1019 16.5001C39.7941 16.5001 41.2808 16.2827 41.2808 15.3324C41.2808 14.7486 40.715 14.4256 40.1493 14.1865C39.5413 13.9287 38.7257 13.7113 37.9282 13.4287C36.3151 12.8231 34.8495 11.9598 34.8495 9.92871C34.8495 7.57467 36.8598 6.42871 38.9755 6.42871C40.5465 6.42871 42.1385 7.01256 43.2671 8.15852L41.5908 9.84486C40.8986 9.17405 39.9356 8.76411 38.9725 8.76411C38.3225 8.76411 37.1728 8.95977 37.1728 9.84486C37.1728 10.4504 37.7386 10.7517 38.3255 10.9908L40.5465 11.7486C42.1385 12.3324 43.6253 13.2175 43.6253 15.3355C43.6253 17.7113 41.2176 18.8355 39.1019 18.8355C37.2601 18.8355 35.6259 18.1865 34.2656 16.8914L35.8787 15.1399H35.8757Z" fill="#034CF3"/>
                <path d="M47.8542 6.84179V8.85111C48.754 7.4039 50.0752 6.49707 52.0856 6.49707C55.2486 6.49707 57.6141 9.11198 57.6141 12.3325C57.6141 15.9412 55.4773 18.5561 52.0645 18.5561C50.3461 18.5561 48.7149 17.7362 47.8542 16.3294V23.3076H45.5098V6.84179H47.8542ZM51.9381 16.1989C53.9906 16.1989 55.2245 14.2983 55.2245 12.4598C55.2245 10.6213 53.8431 8.85111 51.9592 8.85111C50.0752 8.85111 48.6306 10.73 48.0017 12.5033C48.5885 14.1896 49.8856 16.1989 51.9381 16.1989Z" fill="#034CF3"/>
                <path d="M69.0681 18.2734V16.2641C68.1682 17.7113 66.826 18.6181 64.8367 18.6181C61.6526 18.6181 59.2871 16.0032 59.2871 12.7827C59.2871 9.15225 61.4449 6.55908 64.8577 6.55908C66.5762 6.55908 68.2073 7.37896 69.0681 8.78579V6.84169H71.3914V18.2734H69.0681ZM64.9631 8.89449C62.9316 8.89449 61.6977 10.8168 61.6977 12.6336C61.6977 14.556 63.058 16.2641 64.9631 16.2641C66.8681 16.2641 68.2916 14.3852 68.8995 12.6119C68.3337 10.9255 67.0366 8.89449 64.9631 8.89449Z" fill="#034CF3"/>
                <path d="M81.9036 10.6895C81.38 9.69574 80.2905 9.04667 79.18 9.04667C77.1696 9.04667 75.9568 10.6895 75.9568 12.6119C75.9568 14.4256 77.1095 16.0902 79.0356 16.0902C80.3327 16.0902 81.5906 15.4628 82.03 14.1243L84.2902 14.7516C83.598 17.1057 81.5274 18.469 79.1589 18.469C75.9538 18.469 73.6094 15.9411 73.6094 12.6119C73.6094 9.2827 75.9117 6.6709 79.18 6.6709C81.2114 6.6709 83.1375 7.81686 84.0374 9.69574L81.9006 10.6895H81.9036Z" fill="#034CF3"/>
                <path d="M87.6433 13.4535C88.0195 15.0746 89.0247 16.2423 90.7642 16.2423C91.7694 16.2423 92.8378 15.8324 93.5059 15.0529L95.3056 16.5218C94.1951 17.8386 92.4375 18.5094 90.7401 18.5094C87.4116 18.5094 85.1484 15.8293 85.1484 12.4162C85.1484 9.00318 87.3875 6.55908 90.6559 6.55908C93.7346 6.55908 95.9316 9.30442 95.9316 12.4162V13.4535H87.6403H87.6433ZM93.3614 11.2703C93.0063 9.8448 92.1275 8.82927 90.5776 8.82927C88.9224 8.82927 88.0225 9.69262 87.6885 11.2703H93.3645H93.3614Z" fill="#034CF3"/>
                <path d="M107.387 18.2734V16.2641C106.487 17.7113 105.148 18.6181 103.159 18.6181C99.9749 18.6181 97.6094 16.0032 97.6094 12.7827C97.6094 9.15225 99.7672 6.55908 103.18 6.55908C104.898 6.55908 106.53 7.37896 107.39 8.78579V6.84169H109.714V18.2734H107.39H107.387ZM103.282 8.89449C101.251 8.89449 100.017 10.8168 100.017 12.6336C100.017 14.556 101.377 16.2641 103.282 16.2641C105.187 16.2641 106.611 14.3852 107.219 12.6119C106.653 10.9255 105.356 8.89449 103.282 8.89449Z" fill="#034CF3"/>
                <path d="M113.335 15.1399C114.235 15.9598 115.324 16.5001 116.561 16.5001C117.253 16.5001 118.74 16.2827 118.74 15.3324C118.74 14.7486 118.174 14.4256 117.608 14.1865C117 13.9287 116.185 13.7113 115.387 13.4287C113.774 12.8231 112.308 11.9598 112.308 9.92871C112.308 7.57467 114.319 6.42871 116.435 6.42871C118.005 6.42871 119.598 7.01256 120.726 8.15852L119.05 9.84486C118.358 9.17405 117.395 8.76411 116.432 8.76411C115.781 8.76411 114.632 8.95977 114.632 9.84486C114.632 10.4504 115.198 10.7517 115.784 10.9908L118.005 11.7486C119.598 12.3324 121.084 13.2175 121.084 15.3355C121.084 17.7113 118.677 18.8355 116.561 18.8355C114.719 18.8355 113.085 18.1865 111.725 16.8914L113.338 15.1399H113.335Z" fill="#034CF3"/>
                <path d="M124.706 13.4535C125.082 15.0746 126.087 16.2423 127.827 16.2423C128.832 16.2423 129.9 15.8324 130.568 15.0529L132.368 16.5218C131.258 17.8386 129.5 18.5094 127.803 18.5094C124.474 18.5094 122.211 15.8293 122.211 12.4162C122.211 9.00318 124.453 6.55908 127.718 6.55908C130.797 6.55908 132.994 9.30442 132.994 12.4162V13.4535H124.703H124.706ZM130.424 11.2703C130.069 9.8448 129.19 8.82927 127.64 8.82927C125.985 8.82927 125.085 9.69262 124.751 11.2703H130.427H130.424Z" fill="#034CF3"/>
                </svg>
                <p>beta</p>
             </div>
             </>
             );
        default:return( 
            
            <svg as={Link} to="/" xmlns="http://www.w3.org/2000/svg" width="100" height="17" viewBox="0 0 100 17" fill="none">
              <path d="M2.24681 8.71118C3.14666 9.53106 4.23611 10.0714 5.47303 10.0714C6.16522 10.0714 7.65192 9.85404 7.65192 8.90373C7.65192 8.31987 7.08613 7.99689 6.52034 7.75776C5.91242 7.5 5.09684 7.28261 4.29931 7C2.68621 6.39441 1.22057 5.53106 1.22057 3.5C1.22057 1.14596 3.23093 0 5.34663 0C6.9176 0 8.50964 0.583851 9.63821 1.72981L7.96191 3.41615C7.26971 2.74534 6.30667 2.3354 5.34362 2.3354C4.69356 2.3354 3.54392 2.53106 3.54392 3.41615C3.54392 4.02174 4.10971 4.32298 4.69657 4.56211L6.9176 5.31988C8.50964 5.90373 9.99635 6.78882 9.99635 8.90683C9.99635 11.2826 7.58872 12.4068 5.47303 12.4068C3.6312 12.4068 1.99702 11.7578 0.636719 10.4627L2.24983 8.71118H2.24681Z" fill="#034CF3"/>
              <path d="M14.2253 0.41308V2.4224C15.1251 0.975192 16.4463 0.0683594 18.4567 0.0683594C21.6197 0.0683594 23.9852 2.68327 23.9852 5.90376C23.9852 9.51246 21.8484 12.1274 18.4356 12.1274C16.7172 12.1274 15.086 11.3075 14.2253 9.90066V16.8789H11.8809V0.41308H14.2253ZM18.3092 9.77022C20.3617 9.77022 21.5956 7.8696 21.5956 6.03109C21.5956 4.19258 20.2142 2.4224 18.3303 2.4224C16.4463 2.4224 15.0017 4.30128 14.3727 6.07457C14.9596 7.76091 16.2567 9.77022 18.3092 9.77022Z" fill="#034CF3"/>
              <path d="M35.4392 11.8447V9.83534C34.5393 11.2825 33.1971 12.1894 31.2078 12.1894C28.0237 12.1894 25.6582 9.57447 25.6582 6.35397C25.6582 2.72354 27.816 0.130371 31.2288 0.130371C32.9473 0.130371 34.5784 0.950247 35.4392 2.35708V0.41298H37.7625V11.8447H35.4392ZM31.3342 2.46577C29.3027 2.46577 28.0688 4.38813 28.0688 6.20491C28.0688 8.12727 29.4291 9.83534 31.3342 9.83534C33.2392 9.83534 34.6627 7.95646 35.2706 6.18317C34.7048 4.49683 33.4077 2.46577 31.3342 2.46577Z" fill="#034CF3"/>
              <path d="M48.2747 4.26082C47.7511 3.26703 46.6616 2.61796 45.5511 2.61796C43.5407 2.61796 42.3279 4.26082 42.3279 6.18318C42.3279 7.99685 43.4805 9.66144 45.4066 9.66144C46.7038 9.66144 47.9617 9.03411 48.4011 7.6956L50.6613 8.32293C49.9691 10.677 47.8985 12.0403 45.53 12.0403C42.3249 12.0403 39.9805 9.51237 39.9805 6.18318C39.9805 2.85399 42.2828 0.242188 45.5511 0.242188C47.5825 0.242188 49.5086 1.38815 50.4085 3.26703L48.2717 4.26082H48.2747Z" fill="#034CF3"/>
              <path d="M54.0144 7.02478C54.3906 8.6459 55.3958 9.8136 57.1353 9.8136C58.1405 9.8136 59.2089 9.40366 59.877 8.62416L61.6767 10.0931C60.5662 11.4099 58.8086 12.0807 57.1112 12.0807C53.7827 12.0807 51.5195 9.40056 51.5195 5.98751C51.5195 2.57447 53.7586 0.130371 57.027 0.130371C60.1057 0.130371 62.3027 2.87571 62.3027 5.98751V7.02478H54.0114H54.0144ZM59.7325 4.84155C59.3774 3.41609 58.4986 2.40056 56.9487 2.40056C55.2935 2.40056 54.3936 3.26391 54.0596 4.84155H59.7355H59.7325Z" fill="#034CF3"/>
              <path d="M73.7584 11.8447V9.83534C72.8586 11.2825 71.5193 12.1894 69.53 12.1894C66.346 12.1894 63.9805 9.57447 63.9805 6.35397C63.9805 2.72354 66.1383 0.130371 69.5511 0.130371C71.2695 0.130371 72.9007 0.950247 73.7614 2.35708V0.41298H76.0848V11.8447H73.7614H73.7584ZM69.6534 2.46577C67.622 2.46577 66.3881 4.38813 66.3881 6.20491C66.3881 8.12727 67.7484 9.83534 69.6534 9.83534C71.5585 9.83534 72.982 7.95646 73.5899 6.18317C73.0241 4.49683 71.727 2.46577 69.6534 2.46577Z" fill="#034CF3"/>
              <path d="M79.7058 8.71118C80.6056 9.53106 81.6951 10.0714 82.932 10.0714C83.6242 10.0714 85.1109 9.85404 85.1109 8.90373C85.1109 8.31987 84.5451 7.99689 83.9793 7.75776C83.3714 7.5 82.5558 7.28261 81.7583 7C80.1452 6.39441 78.6796 5.53106 78.6796 3.5C78.6796 1.14596 80.6899 0 82.8056 0C84.3766 0 85.9686 0.583851 87.0972 1.72981L85.4209 3.41615C84.7287 2.74534 83.7657 2.3354 82.8026 2.3354C82.1525 2.3354 81.0029 2.53106 81.0029 3.41615C81.0029 4.02174 81.5687 4.32298 82.1556 4.56211L84.3766 5.31988C85.9686 5.90373 87.4553 6.78882 87.4553 8.90683C87.4553 11.2826 85.0477 12.4068 82.932 12.4068C81.0902 12.4068 79.456 11.7578 78.0957 10.4627L79.7088 8.71118H79.7058Z" fill="#034CF3"/>
              <path d="M91.0769 7.02478C91.4531 8.6459 92.4583 9.8136 94.1978 9.8136C95.203 9.8136 96.2714 9.40366 96.9395 8.62416L98.7392 10.0931C97.6287 11.4099 95.8711 12.0807 94.1737 12.0807C90.8452 12.0807 88.582 9.40056 88.582 5.98751C88.582 2.57447 90.8241 0.130371 94.0895 0.130371C97.1682 0.130371 99.3652 2.87571 99.3652 5.98751V7.02478H91.0739H91.0769ZM96.795 4.84155C96.4399 3.41609 95.5611 2.40056 94.0112 2.40056C92.356 2.40056 91.4561 3.26391 91.1221 4.84155H96.798H96.795Z" fill="#034CF3"/>
                      </svg>
                );
    }
    
}

export default Logo;
