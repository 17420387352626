import React from "react";

const es = {
	terms: {
		title: "Términos de uso",
		lastUpdate: "Actualizado por última vez: 21 de Noviembre, 2023",
		p1: "1.- Ámbito y Propósito de las Presentes Condiciones:",
		p1_1: "1.1. Área de Aplicación: Estas condiciones generales rigen la relación entre Spacease, la empresa que opera este negocio, y sus Usuarios o Clientes que realizan transacciones a través de la plataforma en http://spacease.com. La contratación en línea de servicios está sujeta a políticas legales aplicables, incluyendo el Aviso Legal, la Política de Privacidad y la Política de Cookies. En caso de conflicto, prevalecen estas condiciones generales.",
		p1_2: `1.2. Objeto de Estas Condiciones: Spacease funciona como un
    mercado y agregador de espacios, facilitando herramientas de
    reserva de espacios. Los Usuarios pueden subir espacios
    disponibles para que otros los reserven, actuando como mediador en
    la transacción. Los Usuarios pueden reservar un espacio o subir
    espacios para reservar, con Spacease gestionando la visualización
    de espacios disponibles en su plataforma.`,
		p1_3: `1.3. Términos Esenciales para una Mejor Comprensión: Plataforma:
    La página web que facilita el contacto entre Usuarios anfitriones
    e invitados para transacciones. Usuario Anfitrión: Proporciona
    espacios para reservar, incluyendo Usuario Anfitrión Dorado y
    Usuario de Administración Pública. Usuario Invitado: Reserva
    espacios disponibles, incluyendo Usuario Arrendador Frecuente.
    Usuario Registrado o Cliente: Usuario que se registra en el sitio
    web para subir espacios o reservas. Visitante: Persona que navega
    por el sitio web sin registrarse.`,
		p_1_4: `1.4. Tipos de Usuario y Contratación: La contratación de servicios
    está abierta a individuos o entidades con capacidad legal. Las
    personas naturales están sujetas a regulaciones de protección al
    consumidor, mientras que los profesionales autónomos o empresas
    están vinculados por estos términos. Los Usuarios pueden
    registrarse y operar como anfitrión o invitado indistintamente,
    aplicándose las condiciones generales, junto con condiciones
    específicas basadas en su rol. Estas condiciones establecen el
    marco para un compromiso profesional y transparente entre Spacease
    y sus Usuarios o Clientes, asegurando una comprensión clara de
    roles, responsabilidades y el proceso de transacción en general.`,
		p2: `2.- Procedimiento de Registro y Aceptación de Condiciones:`,
		p2_1: `2.1. Lectura y Aceptación de Condiciones Generales:`,
		p2_1_1: `2.1.1. Características Generales: Para utilizar ciertas funcionalidades en nuestra plataforma, los
    usuarios deben ser adultos y registrarse a través de la opción "Registrarse". El proceso de registro
    requiere proporcionar datos relevantes en cumplimiento con las regulaciones de protección de
    datos.`,
		p2_1_2: `2.1.2. Registro de Usuario: Antes de contratar servicios, es obligatorio el registro como Usuario,
    con los datos requeridos alineados con las leyes de protección de datos. Los usuarios pueden
    recibir ofertas o promociones si otorgan autorización durante el registro. Spacease mantiene una
    relación personalizada con los Usuarios para actualizaciones, cambios de servicios, etc.`,
		p2_2: `2.2. Aceptación de Condiciones: La confirmación del servicio implica activar el botón "Acepto
    los términos y condiciones" durante el proceso de registro. Tras completar los campos
    obligatorios y validar a través de un correo electrónico de verificación, el Usuario entra en un
    contrato con Spacease. Los términos y condiciones siempre están accesibles en el sitio web.`,
		p2_3: `2.3. Responsabilidades del Usuario en este Momento: El Usuario es responsable de la exactitud
    de la información proporcionada. Spacease no verifica las identidades de los Usuarios y no es
    responsable de la usurpación de identidad. Spacease se reserva el derecho de rechazar registros
    que no se alineen con los propósitos de la plataforma. El código de acceso del usuario es
    personal e intransferible. Los Usuarios deben notificar a Spacease de cualquier incidente de
    seguridad de inmediato.`,
		p2_4: `2.4. Significado de la Aceptación: La aceptación implica: Operar como Usuario anfitrión y/o
    invitado dentro de las pautas de la plataforma. Comprometerse a cumplir y respetar estas
    condiciones bajo responsabilidad única. La confirmación de la aceptación se registra durante el
    registro o la contratación para verificación legal.`,
		p2_5: `2.5. Requisito para Contratar: Edad Legal: Los Usuarios y/o Clientes deben ser mayores de edad
    según la legislación local para utilizar los servicios de la plataforma. Este proceso de registro y
    aceptación asegura una relación transparente y responsable entre Spacease y sus Usuarios,
    enfatizando la importancia de la información precisa, la seguridad y la adherencia a las pautas de
    la plataforma.`,
		p3: `3.- Servicios que Ofrecemos:`,
		p3_1: `3.1. Mediación en Transacciones de Reserva de Espacios: Como se indica en el punto 1.2, el
    principal servicio que ofrecemos desde Spacease, y el propósito de esta plataforma, es actuar
    como intermediario y poner en contacto al Usuario Anfitrión, que sube y publicita un
    determinado espacio, con los servicios adicionales o extras que desee, con el potencial Usuario
    Invitado, que lo reservaría.`,
		p4: `4.- Condiciones para los Servicios Contratados:`,
		p4_1: `4.1. Condiciones de los Servicios Descritos:`,
		p4_1_1: `4.1.1. Requisitos para la Publicidad de Espacios: Spacease publica espacios en la plataforma bajo
    condiciones específicas: Imágenes horizontales de espacios anunciados con calidad suficiente.
    Las imágenes deben estar libres de marcas de agua o información personal/de contacto.
    Imágenes bien enfocadas, sin pixelación y con iluminación adecuada. Spacease se reserva el
    derecho de retocar imágenes para una mejor cumplimentación, aceptada por los Usuarios
    Anfitriones.`,
		p4_2: `4.2. Condiciones Aplicables a la Plataforma:`,
		p4_2_1: `4.2.1. Exclusiones: Spacease explícitamente no vende, compra, ni alquila los espacios mostrados,
    y no garantiza la calidad de los espacios ofrecidos por los Usuarios Anfitriones en la Plataforma.`,
		p4_2_2: `4.2.2. Peculiaridades y Aclaraciones Importantes: Condiciones del Espacio Reservado: Spacease
    actúa como intermediario, presentando espacios subidos por Usuarios Anfitriones y sus extras.
    La responsabilidad se limita a lo expresamente expuesto e informado en la plataforma. Los
    detalles sobre la operación del espacio, condiciones y uso se acuerdan entre el Usuario Anfitrión
    y el Invitado. Cancelación: Spacease no garantiza reservas si un Usuario Anfitrión cancela
    debido a fuerza mayor, eventos fortuitos o razones legales. Se harán esfuerzos para encontrar
    soluciones alternativas para el espacio reservado, ubicación y capacidad planeada.`,
		p5: `5.- Uso y Operación de la Plataforma:`,
		p5_1: `5.1. Antes del Uso de la Plataforma:
    Antes de usar la plataforma, los Invitados con dudas o consultas pertinentes pueden contactar a
    Spacease a través del formulario de contacto correspondiente. Si es necesario, Spacease puede
    contactar al Anfitrión en caso de que el asunto se relacione con una reserva de espacio.`,
		p5_2: `5.2. Pasos para el Uso de la Plataforma:`,
		p5_2_1: `5.2.1. Aplicable a Ambos Usuarios:`,
		p5_2_1_1: `1. Acceder a la plataforma y hacer clic en la sección "Registrarse" en la parte superior derecha.`,
		p5_2_1_2: `2. Rellenar el formulario requerido, leer y aceptar las condiciones.`,
		p5_2_1_3: `3. Validar el registro a través de un enlace de correo electrónico enviado por Spacease.`,
		p5_2_1_4: `4. Acceder a la plataforma y recibir un correo electrónico de confirmación.`,
		p5_2_1_5: `5. Opcionalmente, registrarse conectando cuentas de Google o Facebook.`,
		p5_2_2: `5.2.2. Usuario Anfitrión:`,
		p5_2_2_0: `Pasos del Usuario Anfitrión:`,
		p5_2_2_1: `1. Registrarse y aceptar los términos y condiciones.`,
		p5_2_2_2: `2. Rellenar la información relacionada con el espacio: ubicación, indicaciones, descripción,
    fotos, horas de disponibilidad y política de cancelación.`,
		p5_2_2_3: `3. Solicitar la subida del espacio y completar la verificación de datos.`,
		p5_2_2_4: `4. Spacease verifica la información proporcionada y sube el espacio si es válido.`,
		p5_2_3: `5.2.3. Usuario Invitado:`,
		p5_2_3_0: `Pasos del Usuario Invitado:`,
		p5_2_3_1: `1. Buscar la actividad deseada, día y horas usando el buscador.`,
		p5_2_3_2: `2. Spacease proporciona información precisa basada en los criterios de búsqueda.`,
		p5_2_3_3: `3. Los espacios destacados se presentan en la página principal para comodidad del usuario.`,
		p6: `6.- Deberes y Obligaciones de los Usuarios:`,
		p6_1: `6.1. Deberes del Usuario Anfitrión:`,
		p6_1_1: `1. El Anfitrión debe tener el poder y la documentación para hacer disponibles los espacios en la
    plataforma.`,
		p6_1_2: `2. Especificar las condiciones de reserva y proporcionar los datos y fotos requeridos para los
    espacios`,
		p6_1_3: `3. Respetar las garantías legales y comerciales, así como el derecho de desistimiento cuando
    corresponda.`,
		p6_1_4: `4. Con la aceptación de la reserva, el Anfitrión asume las obligaciones derivadas del contrato de
    servicio.`,
		p6_1_5: `5. Proporcionar al Usuario Invitado derechos sobre datos personales, respondiendo a las
    regulaciones de protección de datos.`,
		p6_1_6: `6. Mantenerse actualizado con las obligaciones fiscales, tributarias y de alquiler, liberando a
    Spacease de responsabilidad.`,
		p6_2: `6.2. Deberes del Usuario Invitado:`,
		p6_2_1: `1. Pagar el precio acordado por la reserva del espacio con la debida diligencia y buena fe
    contractual.`,
		p6_2_2: `2. Reconocer pequeñas variaciones en los espacios respecto a las fotos de la plataforma.`,
		p6_2_3: `3. Hacer un uso adecuado de los espacios reservados, respetando las condiciones y
    comprometiéndose a pagar por cualquier daño material.`,
		p6_2_4: `4. Cumplir con las condiciones aplicables según estos términos y otros avisos legales.`,
		p6_3: `6.3. Obligaciones de Ambos Usuarios:`,
		p6_3_1: `1. Los Usuarios deben tener el equipo informático necesario y conexión a internet para acceder a
    la plataforma, con los gastos de conexión a cargo del Usuario.`,
		p6_3_2: `2. Hacer un uso adecuado de la plataforma para sus fines previstos, considerando las comisiones
    aplicables.`,
		p6_3_3: `3. Respetar todas las disposiciones en este texto y adherirse al Código de Conducta descrito en la
    sección 13.`,
		p7: `7.- Derechos Generales de Spacease:`,
		p7_1: `Declaración de Propiedad:`,
		p7_1_text: `- La inclusión de espacios en Spacease implica propiedad o el derecho a alquilar. Spacease se
    reserva el derecho de solicitar la documentación necesaria a los Anfitriones en cualquier
    momento para verificar la propiedad.`,
		p7_2: `Monitoreo de Contenido:`,
		p7_2_text: `- Spacease tiene el derecho de eliminar textos que contengan datos de contacto incorrectos o
    información errónea sobre el espacio. Se notificará a los Anfitriones para rectificar los anuncios
    y cumplir con las condiciones establecidas.`,
		p7_3: `Modificación de la Plataforma:`,
		p7_3_text: `- Spacease está facultado para modificar el diseño, presentación, contenido y funcionalidades de
    la plataforma sin previo aviso. Esto incluye la adición, eliminación o modificación de aspectos
    sin alterar acuerdos o relaciones entre Spacease y los Usuarios.`,
		p7_4: `Negación o Restricción de Acceso:
    `,
		p7_4_text: `- Spacease puede negar o restringir el acceso a Usuarios que no cumplan con las condiciones sin
    previo aviso, asegurando la efectividad de la Web.`,
		p7_5: `Establecimiento de Condiciones Específicas:`,
		p7_5_text: `- Spacease puede introducir condiciones específicas obligatorias para acceder a ciertos servicios
    en la plataforma, además de las condiciones generales.`,
		p7_6: `Cumplimiento de Textos Legales:`,
		p7_6_text: `- Los Usuarios deben adherirse a los textos legales indicados en estas condiciones, más allá de
    las condiciones generales, para acceder y utilizar la plataforma.`,
		p7_7: `Modificación o Interrupción del Acceso:`,
		p7_7_text: `- Spacease se reserva el derecho de modificar o interrumpir temporalmente el acceso al sistema
    para mantenimiento, actualizaciones o reparaciones. Se notificará a los Usuarios con suficiente
    antelación siempre que sea posible.`,
		p8: `8.- Responsabilidades Generales de los Usuarios:`,
		p8_0: `Como Clientes, los Usuarios se comprometen a utilizar nuestros servicios legalmente, en
    cumplimiento de la legislación vigente y sin infringir los derechos e intereses de terceros.
    Garantizan la exactitud de los datos proporcionados, evitando daños a Spacease o a otros
    Usuarios debido a inexactitudes.`,
		p8_0_1: `Los Usuarios son responsables de adherirse a las condiciones, la ejecución del servicio y los
    estándares comunitarios descritos en las condiciones particulares aplicables. Además:`,
		p8_1: `1. Responsabilidad por Daños Materiales y Personales:`,
		p8_1_1: `- Los Usuarios son responsables de los daños en el espacio reservado, directa o indirectamente
    atribuibles a su conducta (excluyendo eventos fortuitos o fuerza mayor).`,
		p8_1_2: `- Los Anfitriones son responsables de los daños debido a las condiciones del espacio y la
    disponibilidad a los Invitados y otros usuarios.`,
		p8_1_3: `- Los Invitados son responsables de los daños durante las horas reservadas para el
    espacio.`,
		p8_2: `2. Consecuencias del Incumplimiento:`,
		p8_2_1: `- El incumplimiento de estas condiciones puede resultar en que Spacease retire o cancele
    servicios sin previo aviso ni derecho a compensación.`,
		p8_final: `Estas responsabilidades mantienen un uso profesional y ético de los servicios de Spacease,
    fomentando un ambiente seguro y respetuoso para todos los Usuarios.`,
		p9: `9.- Limitaciones y Exenciones de Responsabilidad de Spacease:`,
		p9_1: `1. Calidad de los Espacios:`,
		p9_1_1: `- Spacease no es responsable de la calidad de los espacios reservados, ya que actúa únicamente
    como mediador, facilitando el contacto entre las partes.`,
		p9_2: `2. Control de los Datos Proporcionados por el Usuario:`,
		p9_2_1: `- Spacease no controla la actualidad, exactitud o veracidad de los datos proporcionados por el
    usuario. No es responsable de inexactitudes, falta de detalle o mal uso de la información, ya sea
    debido a errores en las fuentes originales o a la interpretación del usuario.`,
		p9_2_2: `Spacease no es responsable de:`,
		p9_2_3: `- Errores relacionados con nombres o datos derivados de errores en las fuentes
    originales.`,
		p9_2_4: `- Falta de información detallada sobre un espacio.`,
		p9_2_5: `- Mal uso o interpretación errónea de la información obtenida de los servicios de Spacease.    `,
		p9_2_6: `- Daños o pérdidas resultantes del uso de información extraída.`,
		p9_3: `3. Reclamaciones de Terceros:`,
		p9_3_1: `- En caso de reclamaciones de terceros con respecto a derechos de propiedad o alquiler de un
    espacio, Spacease abordará la reclamación, afirmando la buena fe y las declaraciones apropiadas
    obtenidas para la presentación del espacio.`,
		p9_3_2: `- Spacease informará al Usuario Anfitrión sobre la reclamación y podrá suspender temporal o
    permanentemente la presentación del espacio en la plataforma según el tipo de infracción.
    Spacease queda exento de responsabilidad ya que el Anfitrión asume la responsabilidad de subir
    espacios de acuerdo con las leyes aplicables.`,
		p9_final: `Estas limitaciones y exenciones protegen a Spacease de responsabilidades más allá de su papel
    como mediador, colocando la responsabilidad en los Usuarios por la exactitud de la información
    y el cumplimiento de las normas legales.`,
		p10: `10.- Código de Conducta:`,
		p10_usuarios: `Usuarios:`,
		p10_usuarios_1: `1. Todos los usuarios deben ser individuos legalmente capaces y mayores de edad.`,
		p10_usuarios_2: `2. Los Usuarios deben adherirse a un código de conducta para un uso responsable, respetuoso y
    diligente de la plataforma.`,
		p10_conducta: `Código de Conducta del Usuario:`,
		p10_conducta_general: `Deberes Generales:`,
		p10_conducta_general_1: `1. Actuar en cumplimiento de las regulaciones aplicables, de manera responsable, respetuosa y
    de buena fe.`,
		p10_conducta_general_2: `2. Los Anfitriones garantizan que los espacios subidos no violan los derechos de terceros.`,
		p10_conducta_general_3: `3. Usar la información confidencial exclusivamente para su propósito divulgado.`,
		p10_conducta_general_4: `4. Reportar anomalías o malfunciones a Spacease.`,
		p10_conducta_general_5: `5. Adherirse a las reglas para el uso del contenido, respetando los derechos de propiedad
    intelectual y las normas legales.`,
		p10_conducta_prohibida: `Conducta Prohibida:`,
		p10_conducta_prohibida_1: `1. No registrarse sin capacidad suficiente.`,
		p10_conducta_prohibida_2: `2. No crear identidades falsas ni usar cuentas de terceros sin autorización.`,
		p10_conducta_prohibida_3: `3. No introducir datos falsos; se requiere información precisa y veraz.`,
		p10_conducta_prohibida_4: `4. No realizar actividades ilegales o falsas, especialmente relacionadas con la explotación
    infantil.`,
		p10_conducta_prohibida_5: `5. No violar las reglas de la plataforma, procesos predeterminados o mal uso que cause daños.`,
		p10_conducta_prohibida_6: `6. No usar la plataforma para fines no autorizados.`,
		p10_conducta_prohibida_7: `7. No contactar ni usar datos proporcionados por otros usuarios sin autorización.`,
		p10_conducta_prohibida_8: `8. No transmitir spam, publicidad no solicitada o contenido inapropiado.`,
		p10_conducta_prohibida_9: `9. No usar material que viole los derechos de propiedad intelectual e industrial.`,
		p10_conducta_prohibida_10: `10. No recolectar información personal sin consentimiento y cumplimiento de las regulaciones
    de protección de datos.`,
		p10_conducta_prohibida_11: `11. No realizar actividades que afecten negativamente a personas o sistemas.`,
		p10_conducta_prohibida_12: `12. No realizar actividades engañosas que causen daño.`,
		p10_conducta_prohibida_13: `13. No utilizar la plataforma para fines ilícitos o actividades que violen los derechos de terceros
    o de Spacease.`,
		p10_conducta_prohibida_14: `14. No propagar discursos de odio, prejuicio o fomentar el crimen.`,
		p10_conducta_prohibida_15: `15. No incluir datos personales en las fotos de los espacios; utilizar el chat interno para
    comunicaciones.`,
		p10_conducta_spacease: `Conducta de Spacease:`,
		p10_conducta_spacease_1: `1. Apegarse a las reglas de confidencialidad y privacidad para los datos de los usuarios.`,
		p10_conducta_spacease_2: `2. Atender las solicitudes, dudas o reclamaciones de los usuarios con prontitud.`,
		p10_conducta_spacease_3: `3. Garantizar un funcionamiento correcto y seguro de la plataforma.`,
		p10_derechos_spacease: `Derechos de Spacease:`,
		p10_derechos_spacease_1: `1. Spacease no garantiza la calidad de los espacios; la responsabilidad recae en el Anfitrión.`,
		p10_derechos_spacease_2: `2. Spacease puede terminar el acceso a usuarios que no cumplan con los términos y condiciones.`,
		p10_derechos_spacease_3: `3. Spacease se reserva el derecho de eliminar comentarios o reseñas inapropiados.`,
		p10_final: `Este código de conducta garantiza el uso responsable y legal de la plataforma Spacease por parte
    de todos los usuarios`,
		p11: `11.- Condiciones de Entrega:`,
		p11_condiciones_anfitriones: `Condiciones para Usuarios Anfitriones:`,
		p11_condiciones_anfitriones_1: `1. Los servicios se entregan al hacer disponibles los espacios contratados.`,
		p11_condiciones_anfitriones_2: `2. La publicación y entrega de espacios a los Usuarios Anfitriones están sujetas a las condiciones
    de la plataforma y los textos legales.`,
		p11_condiciones_invitados: `Condiciones de Entrega de Producto para Usuarios Invitados:`,
		p11_condiciones_invitados_1: `1. La entrega del servicio para los invitados (reserva de un espacio) se considera completada en
    el día y horas reservados.`,
		p11_condiciones_invitados_2: `2. Spacease no es responsable de conflictos que surjan de la entrega; los Usuarios Invitados
    deben dirigirse al anfitrión para cualquier reclamación.`,
		p11_condiciones_invitados_3: `3. Spacease actúa como mediador y los usuarios pueden contactarlo para asistencia en caso de
    problemas.`,
		p11_final: `Estas condiciones de entrega describen el proceso de entrega de servicios tanto para usuarios
    anfitriones como invitados, con responsabilidades claras para la resolución de conflictos.`,
		p12: `12.- Idioma Aplicable:`,
		p12_text: `En caso de cualquier discrepancia entre el texto de estas condiciones y cualquier traducción,
    siempre prevalecerá la versión en inglés. Si hay discrepancias entre la versión en inglés y sus
    traducciones, la versión en inglés tendrá prioridad.`,
		p13: `13.- Propiedad Intelectual e Industrial:`,
		p13_text: `Todo el contenido en nuestro sitio web relacionado con los servicios que ofrecemos está
    protegido por derechos de autor y marcas registradas. Esto incluye artículos, imágenes, logotipos
    y otros materiales propiedad de Spacease o entidades autorizadas. La reproducción,
    transformación, distribución o comunicación pública no autorizada del contenido del sitio web
    está expresamente prohibida sin el consentimiento escrito de Spacease. Cualquier reproducción,
    parcial o total, está sujeta a autorización previa y explícita de Spacease, como se detalla en
    nuestra política de propiedad intelectual en el Aviso Legal en nuestra plataforma.`,
		p14: `14.- Protección de Datos:`,
		p14_text: `Proporcionar datos personales precisos es esencial para los servicios ofrecidos a través de esta
    plataforma. Al registrarse, usted acepta proporcionar datos válidos necesarios para la prestación
    del servicio, la identificación del usuario y la recepción de información relevante y anuncios de
    Spacease. Los fines del tratamiento de datos incluyen la prestación del servicio y facturación,
    notificación de acuerdos promocionales, informar a los usuarios sobre actividades relevantes,
    responder a consultas, habilitar el acceso a opciones registradas y proporcionar estadísticas
    trimestrales a los usuarios vendedores. Tenga la seguridad de que la información sobre acuerdos
    promocionales solo será compartida por Spacease y no por terceros.`,
		p15: `15.- Modificación de Condiciones:`,
		p15_text: `Spacease se reserva el derecho de modificar la presentación, configuración y Condiciones
    Generales de Contrato en el Sitio Web sin previo aviso. Los usuarios siempre encontrarán estas
    condiciones en un lugar visible y libremente accesible para su consulta. Es crucial que los
    usuarios lean y acepten cuidadosamente estas condiciones cada vez que accedan al sitio web, ya
    que la aceptación es un requisito previo para contratar cualquier servicio disponible.`,
		p16: `16.- Jurisdicción Aplicable:`,
		p16_text: `En caso de cualquier conflicto o discrepancia en la interpretación de estas condiciones, los
    Tribunales y Juzgados competentes serán determinados por las regulaciones legales aplicables.
    Los consumidores finales estarán sujetos a los tribunales correspondientes a su domicilio. Para
    entidades legales, las partes se adherirán a los tribunales de España.`,
		p17: `17.- Información de Contacto de la Política de Privacidad:`,
		p17_text: `Si tiene alguna pregunta o inquietud sobre la política de privacidad o el manejo de datos, por
    favor contáctenos en hello@spacease.com. Su uso de Spacease implica su acuerdo con los
    términos y condiciones descritos en esta Política de Privacidad.`,
	},
}
const en = {
	terms: {
		title: "Terms of Services",
		lastUpdate: "Last updated: November 21, 2023",
		p1: "1.- Scope and Purpose of the Present Conditions:",
		p1_1: `1.1. Area of Application: These general conditions govern the relationship between
    Spacease, the company operating this business, and its Users or Clients engaging in transactions
    through the platform at http://spacease.com. The online contracting of services is subject to
    applicable legal policies, including the Legal Notice, Privacy Policy, and Cookie Policy. In case
    of conflict, these general conditions prevail.`,
		p1_2: `1.2. Object of These Conditions: Spacease functions as a marketplace and space
    aggregator, facilitating space reservation tools. Users can upload available spaces for others to
    reserve, acting as a mediator in the transaction. Users can either reserve a space or upload spaces
    for reservation, with Spacease managing the display of available spaces on its platform.`,
		p1_3: `1.3. Essential Terms for Better Understanding: Platform: The webpage facilitating contact
    between host and guest Users for transactions. Host User: Provides spaces for reservation,
    including User Golden Host and Public Administration User. Guest User: Reserves available
    spaces, including User Frequent Renter. Registered User or Client: User who registers on the
    website for space uploads or reservations. Visitor: Person browsing the website without
    registration.`,
		p_1_4: `1.4. Types of User and Contracting: The contracting of services is open to individuals or
    entities with legal capacity. Natural persons are subject to consumer protection regulations, while
    self-employed professionals or companies are bound by these terms. Users can register and
    operate as host or guest indistinctly, with general conditions applying, along with specific
    conditions based on their role. These conditions establish the framework for a professional and
    transparent engagement between Spacease and its Users or Clients, ensuring a clear
    understanding of roles, responsibilities, and the overall transaction process.`,
		p2: `2.- Registration Procedure and Acceptance of Conditions:`,
		p2_1: `2.1. Reading and Acceptance of General Conditions:`,
		p2_1_1: `2.1.1. General Features: To utilize certain functionalities on our platform, users
    must be adults and register through the "Register" option. The registration process
    requires providing relevant data in compliance with data protection regulations.`,
		p2_1_2: `2.1.2. User Registration: Prior to contracting services, registration as a User is
    mandatory, with the required data aligned with data protection laws. Users may receive
    offers or promotions if authorization is granted during registration. Spacease maintains a
    personalized relationship with Users for updates, services changes, etc.`,
		p2_2: `2.2. Acceptance of Conditions: Service confirmation involves activating the "I accept the
    terms and conditions'' button during the registration process. After completing mandatory fields
    and validating through a verification email, the User enters into a contract with Spacease. Terms
    and conditions are always accessible on the website.`,
		p2_3: `2.3. Responsibilities of the User at this Time: The User is responsible for the accuracy of
    provided information. Spacease does not verify User identities and is not liable for identity
    usurpation. Spacease reserves the right to reject registrations not aligning with platform
    purposes. The user's access code is personal and non-transferable. Users must notify Spacease of
    any security incidents immediately.`,
		p2_4: `2.4. Meaning of Acceptance: Acceptance implies: Operating as a host and/or guest User
    within platform guidelines. Undertaking to comply with and respect these conditions under sole
    responsibility. Confirmation of acceptance is recorded during registration or contracting for legal
    verification.`,
		p2_5: `2.5. Requirement for Hiring: Legal Age: Users and/or Clients must be of legal age
    according to local legislation to use platform services. This registration and acceptance process
    ensures a transparent and accountable relationship between Spacease and its Users, emphasizing
    the importance of accurate information, security, and adherence to platform guidelines.`,
		p3: `3.- Services we offer:`,
		p3_1: `3.1. Mediation in space reservation transactions As indicated in point 1.2, the main
    service that we offer from Spacease, and the purpose of this platform, is to act as an intermediary
    and put the host User, who uploads and advertises a certain space, with the additional or extra
    services that wish, in contact with the potential guest User, who would reserve it.`,
		p4: `4.- Conditions for Contracted Services:`,
		p4_1: `4.1. Services Described Conditions:`,
		p4_1_1: `4.1.1. Requirements for Space Advertisement: Spacease publishes spaces on the
    platform under specific conditions: Horizontal images of advertised spaces with sufficient
    quality. Images must be free from watermarks or personal/contact information.
    Well-focused, non-pixelated images with adequate lighting. Spacease reserves the right to
    retouch images for better fulfillment, accepted by Host Users.`,
		p4_2: `4.2. Platform Applicable Conditions:`,
		p4_2_1: `4.2.1. Exclusions: Spacease explicitly does not sell, buy, or lease displayed
    spaces, and does not guarantee the quality of spaces offered by host Users on the
    Platform.`,
		p4_2_2: `4.2.2. Peculiarities and Important Clarifications: Conditions of Reserved Space:
    Spacease acts as an intermediary, presenting spaces uploaded by host Users and their
    extras. Responsibility is limited to what is expressly exposed and reported on the
    platform. Details about space operation, conditions, and use are agreed upon by the Host
    User and the Guest. Cancellation: Spacease does not guarantee reservations if a host User
    cancels due to force majeure, fortuitous events, or legal reasons. Efforts will be made to
    find alternative solutions for the reserved space, location, and planned capacity.`,
		p5: `5.- Use and Operation of the Platform:`,
		p5_1: `5.1. Prior to Platform Use:
    Before using the platform, Guests with doubts or pertinent inquiries can contact Spacease
    through the relevant contact form. If necessary, Spacease may contact the Host in case the matter
    relates to a space reservation.`,
		p5_2: `5.2. Steps for Platform Usage:`,
		p5_2_1: `5.2.1. Applicable to Both Users:`,
		p5_2_1_1: `1. Access the platform and click on the "Register" section in the upper
    right.`,
		p5_2_1_2: `2. Fill in the required form, read and accept the conditions.`,
		p5_2_1_3: `3. Validate registration through an email link sent by Spacease.`,
		p5_2_1_4: `4. Access the platform and receive a confirmation email.`,
		p5_2_1_5: `5. Optionally, register by connecting Google or Facebook accounts.`,
		p5_2_2: `5.2.2. Host User:`,
		p5_2_2_0: `Host User steps:    `,
		p5_2_2_1: `1. Register and accept terms and conditions.`,
		p5_2_2_2: `2. Fill in space-related information: location, directions,
    description, photos, availability hours, and cancellation policy.`,
		p5_2_2_3: `3. Request space upload and complete data verification.`,
		p5_2_2_4: `4. Spacease verifies provided information and uploads the space if
    valid.`,
		p5_2_3: `5.2.3. Guest User:`,
		p5_2_3_0: `Guest User steps:`,
		p5_2_3_1: `1. Search for desired activity, day, and hours using the browser.`,
		p5_2_3_2: `2. Spacease provides precise information based on search criteria.`,
		p5_2_3_3: `3. Highlighted spaces are featured on the main page for user
    convenience.`,
		p6: `6.- Duties and Obligations of Users:`,
		p6_1: `6.1. Host User Duties:`,
		p6_1_1: `1. Host must have the power and documentation to make spaces available on the
    platform.`,
		p6_1_2: `2. Specify reservation conditions and provide required data and photos for spaces.`,
		p6_1_3: `3. Respect legal and commercial guarantees, as well as the right of withdrawal
    when applicable.`,
		p6_1_4: `4. With reservation acceptance, the Host assumes obligations derived from the
    service contract.`,
		p6_1_5: `5. Provide Guest User with rights to personal data, responding to data protection
    regulations.`,
		p6_1_6: `6. Stay up-to-date with tax, fiscal, and lease obligations, relieving Spacease of
    responsibility.`,
		p6_2: `6.2. Duties of Guest User:`,
		p6_2_1: `1. Pay the agreed price for space reservation with due diligence and contractual
    good faith.`,
		p6_2_2: `2. Acknowledge minor variations in spaces from platform photos.`,
		p6_2_3: `3. Make proper use of reserved spaces, respecting conditions and committing to
    pay for any material damage`,
		p6_2_4: `4. Comply with applicable conditions as per these terms and other legal notices.`,
		p6_3: `6.3. Obligations of Both Users:`,
		p6_3_1: `1. Users must have necessary computer equipment and internet connection for
    platform access, with connection expenses borne by the User.`,
		p6_3_2: `2. Make proper use of the platform for its intended purposes, considering
    applicable commissions.`,
		p6_3_3: `3. Respect all provisions in this text and adhere to the Code of Conduct outlined
    in section 13.`,
		p7: `7.- General Rights of Spacease:`,
		p7_1: `Ownership Declaration:`,
		p7_1_text: `- The inclusion of spaces on Spacease implies ownership or the right to lease.
    Spacease reserves the right to request necessary documentation from Hosts at any time to
    verify ownership.`,
		p7_2: `Content Monitoring:`,
		p7_2_text: `- Spacease has the right to remove texts containing incorrect contact data or
    misinformation about the space. Hosts will be notified to rectify advertisements to meet
    established conditions.`,
		p7_3: `Platform Modification:`,
		p7_3_text: `- Spacease is empowered to modify the platform's design, presentation, content,
    and functionalities without prior notice. This includes the addition, removal, or
    modification of aspects without altering agreements or relationships between Spacease
    and Users.`,
		p7_4: `Access Denial or Restriction:`,
		p7_4_text: `- Spacease may deny or restrict access to Users not adhering to conditions without
    prior notice, ensuring the Web's effectiveness.`,
		p7_5: `Establishment of Specific Conditions:`,
		p7_5_text: `- Spacease may introduce specific conditions mandatory for accessing certain
    services on the platform, in addition to the general conditions.`,
		p7_6: `Compliance with Legal Texts:`,
		p7_6_text: `- Users must adhere to the legal texts indicated in these conditions, beyond the
    general conditions, for accessing and using the platform.`,
		p7_7: `Modification or Interruption of Access:`,
		p7_7_text: `- Spacease reserves the right to modify or temporarily interrupt access to the
    system for maintenance, updates, or repairs. Users will be notified sufficiently in advance
    whenever possible.`,
		p8: `8.- General Responsibilities of Users:`,
		p8_0: `As Clients, Users commit to using our services lawfully, in compliance with current
    legislation, and without infringing on the rights and interests of third parties. They ensure the
    accuracy of provided data, preventing harm to Spacease or other Users due to inaccuracies.`,
		p8_0_1: `Users are responsible for adhering to the conditions, service execution, and community
    standards outlined in applicable particular conditions. Additionally:`,
		p8_1: `1. Material and Personal Damage Responsibility:`,
		p8_1_1: `- Users are responsible for damage in the reserved space, directly or indirectly
    attributable to their conduct (excluding fortuitous events or force majeure).`,
		p8_1_2: `- Hosts are liable for damages due to space conditions and availability to Guests
    and other users.`,
		p8_1_3: `- Guests are responsible for damages during reserved space hours.`,
		p8_2: `2. Consequences of Non-Compliance:`,
		p8_2_1: `- Failure to comply with these conditions may result in Spacease withdrawing or
    canceling services without advance notice or entitlement to compensation.`,
		p8_final: `These responsibilities uphold a professional and ethical use of Spacease services,
    fostering a secure and respectful environment for all Users.`,
		p9: `9.- Limitations and Exemptions to Spacease's Liability:`,
		p9_1: `1. Quality of Spaces:`,
		p9_1_1: `- Spacease is not responsible for the quality of reserved spaces, as it acts solely as
    a mediator, facilitating contact between parties.`,
		p9_2: `2. Control of User-Provided Data:`,
		p9_2_1: `- Spacease does not control the timeliness, accuracy, or veracity of user-provided
    data. It is not liable for inaccuracies, lack of detail, or misuse of information,
    whether due to errors in original sources or user interpretation.`,
		p9_2_2: `Spacease is not responsible for:`,
		p9_2_3: `- Errors related to names or data derived from errors in the original sources.`,
		p9_2_4: `- Lack of detailed information about a space.`,
		p9_2_5: `- User misuse or misinterpretation of information obtained from Spacease
    services.`,
		p9_2_6: `- Damages or losses resulting from the use of extracted information.    `,
		p9_3: `3. Claims from Third Parties:`,
		p9_3_1: `- In the event of third-party claims regarding ownership or lease rights of a space,
    Spacease will address the claim, asserting good faith and appropriate statements
    obtained for displaying the space.`,
		p9_3_2: `- Spacease will inform the host User of the claim and may temporarily or
    permanently suspend the presentation of the space on the platform based on the
    type of infraction. Spacease is exempt from liability as the Host assumes
    responsibility for uploading spaces in accordance with applicable laws.`,
		p9_final: `These limitations and exemptions safeguard Spacease from liabilities beyond its role as a
    mediator, placing responsibility on Users for the accuracy of information and compliance with
    legal standards.`,
		p10: `10.- Code of Conduct:`,
		p10_usuarios: `Users:`,
		p10_usuarios_1: `1. All users must be legally capable individuals of the age of majority.`,
		p10_usuarios_2: `2. Users must adhere to a code of conduct for responsible, respectful, and diligent
    use of the platform.`,
		p10_conducta: `User Code of Conduct:`,
		p10_conducta_general: `General Duties:`,
		p10_conducta_general_1: `1. Act in compliance with applicable regulations, responsibly, respectfully,
    and in good faith.`,
		p10_conducta_general_2: `2. Hosts guarantee that uploaded spaces do not violate third-party rights.`,
		p10_conducta_general_3: `3. Use confidential information exclusively for its disclosed purpose.`,
		p10_conducta_general_4: `4. Report anomalies or malfunctions to Spacease.`,
		p10_conducta_general_5: `5. Adhere to rules for content use, respecting intellectual property rights
    and legal standards.`,
		p10_conducta_prohibida: `Prohibited Conduct:`,
		p10_conducta_prohibida_1: `1. No registration without sufficient capacity.`,
		p10_conducta_prohibida_2: `2. No creation of false identities or unauthorized use of third-party accounts.    `,
		p10_conducta_prohibida_3: `3. No introduction of false data; accurate and truthful information is required.`,
		p10_conducta_prohibida_4: `4. No unlawful or false activities, especially related to child exploitation.`,
		p10_conducta_prohibida_5: `5. No violation of platform rules, predetermined processes, or misuse causing
    damage.`,
		p10_conducta_prohibida_6: `6. No use of the platform for unauthorized purposes.`,
		p10_conducta_prohibida_7: `7. No unauthorized contact or use of data provided by other users.`,
		p10_conducta_prohibida_8: `8. No transmission of spam, unsolicited advertising, or inappropriate content.`,
		p10_conducta_prohibida_9: `9. No use of material violating intellectual and industrial property rights.`,
		p10_conducta_prohibida_10: `10. No collection of personal information without consent and compliance with
    data protection regulations.`,
		p10_conducta_prohibida_11: `11. No activities affecting people or systems negatively, including denial of
    service attacks.`,
		p10_conducta_prohibida_12: `12. No deceptive activities causing harm.`,
		p10_conducta_prohibida_13: `13. No illicit purposes or activities violating rights of third parties or Spacease.`,
		p10_conducta_prohibida_14: `14. No propagation of hate speech, prejudice, or advocacy of crime.`,
		p10_conducta_prohibida_15: `15. No inclusion of personal data in space photos; use internal chat for
    communications.`,
		p10_conducta_spacease: `Spacease Conduct:`,
		p10_conducta_spacease_1: `1. Adhere to confidentiality and privacy rules for user data.`,
		p10_conducta_spacease_2: `2. Address user requests, doubts, or claims promptly.`,
		p10_conducta_spacease_3: `3. Ensure correct and safe platform operation.`,
		p10_derechos_spacease: `Spacease Rights:`,
		p10_derechos_spacease_1: `1. Spacease does not guarantee space quality; responsibility lies with the Host.`,
		p10_derechos_spacease_2: `2. Spacease may terminate access to users not adhering to terms and conditions.`,
		p10_derechos_spacease_3: `3. Spacease reserves the right to remove inappropriate comments or reviews.`,
		p10_final: `This code of conduct ensures responsible and lawful use of the Spacease platform by all
    users.`,
		p11: `11.- Delivery Conditions:`,
		p11_condiciones_anfitriones: `Conditions for Host Users:`,
		p11_condiciones_anfitriones_1: `1. Services are delivered by making contracted spaces available.`,
		p11_condiciones_anfitriones_2: `2. Publication and delivery of spaces to host Users are subject to platform
    conditions and legal texts.`,
		p11_condiciones_invitados: `Product Delivery Conditions for Guest Users:`,
		p11_condiciones_invitados_1: `1. Service delivery for guests (reservation of a space) is considered completed on
    the reserved day and hours.`,
		p11_condiciones_invitados_2: `2. Spacease is not responsible for conflicts arising from delivery; guest Users
    must address the host for any claims.`,
		p11_condiciones_invitados_3: `3. Spacease serves as a mediator, and users can contact for assistance in case of
    problems.`,
		p11_final: `These delivery conditions outline the process of service delivery for both host and guest
    users, with clear responsibilities for conflict resolution.`,
		p12: `12.- Applicable Language:`,
		p12_text: `In the event of any discrepancy between the text of these conditions and any translation,
    the English version shall always prevail. If there are discrepancies between the English version
    and its translations, the English version will take precedence.`,
		p13: `13.- Intellectual and Industrial Property:`,
		p13_text: `All content on our website related to the services we offer is protected by copyright and
    trademarks. This includes articles, images, logos, and other materials owned by Spacease or
    authorized entities. Unauthorized reproduction, transformation, distribution, or public
    communication of the website's content is expressly prohibited without written consent from
    Spacease. Any reproduction, in part or in full, is subject to prior and explicit authorization from
    Spacease, as detailed in our intellectual property policy in the Legal Notice on our platform.`,
		p14: `14.- Data Protection:`,
		p14_text: `Providing accurate personal data is essential for the services offered through this
    platform. By registering, you agree to furnish valid data necessary for service provision, user
    identification, and receiving relevant information and advertisements from Spacease. The
    purposes of data processing include service provision and billing, notification of promotional
    agreements, informing users of relevant activities, responding to queries, enabling access to
    registered options, and providing quarterly statistics to seller users. Rest assured, information
    regarding promotional agreements will be shared solely by Spacease and not by third parties.`,
		p15: `15.- Modification of Conditions:`,
		p15_text: `Spacease retains the right to modify the presentation, configuration, and General
    Conditions of Contract on the Website without prior notice. Users will always find these
    conditions in a visible and freely accessible place for reference. It is crucial for users to carefully
    read and accept these conditions each time they access the website, as acceptance is a
    prerequisite for contracting any available service.`,
		p16: `16.- Applicable Jurisdiction:`,
		p16_text: `In case of any conflict or discrepancy in interpreting these conditions, the competent
    Courts and Tribunals will be determined by applicable legal regulations. Final consumers will be
    subject to the courts corresponding to their domicile. For legal entities, the parties will adhere to
    the courts of Spain.`,
		p17: `17.- Privacy Policy Contact Information:`,
		p17_text: `If you have any questions or concerns about the privacy policy or data handling, please
    contact us at hello@spacease.com. Your use of Spacease implies your agreement with the terms
    and conditions outlined in this Privacy Policy.`,
	},
};

function useLanguage(language, data) {
	const selectedLanguage = language === "es" ? es : en;

	return selectedLanguage[data];
}

export { useLanguage };
