import React from "react";
import "../css/landingCreateSpace.css";
import cssLandingCreateSpace from "../css/landingCreateSpace.module.css"
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import StillHavingQuestions from "../components/StillHavingQuestions"
import {
  URL_ABOUT_US,
  URL_RULES,
  URL_UPLOAD_SPACE,
} from "../context/urlPath.js";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { upload_space_landing_1,upload_space_landing_2,upload_space_landing_3,home_1 } from "../Assets";
import { SVG } from "../helpers/Svgs";
import { accordionSummary_owners, accordion_blue_background } from "../components/Styles";
export const LandingCreateSpace = () => {
  const location = useLocation();
  return (
    <>
      <Helmet>
                {/* Título básico */}
                <title>Sube tu anuncio a Spacease</title>

                {/* Caracteres y idioma */}
                <meta charSet="utf-8" />
                <html lang="es" />

                {/* Descripción de la página */}
                <meta name="description" content="Da a conocer tu local comercial a cientos de marcas que confían en Spacease. Recibe nuevos inquilinos fácilmente." />

                {/* Mobile Meta */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {/* Open Graph (para Facebook y LinkedIn) */}
                <meta property="og:title" content="Sube tu anuncio a Spacease" />
                <meta property="og:description" content="Da a conocer tu local comercial a cientos de marcas que confían en Spacease. Recibe nuevos inquilinos fácilmente." />
                <meta property="og:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Spacease" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@Spacease_es" />
                <meta name="twitter:title" content="Sube tu anuncio a Spacease" />
                <meta name="twitter:description" content="Da a conocer tu local comercial a cientos de marcas que confían en Spacease. Recibe nuevos inquilinos fácilmente." />
                <meta name="twitter:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png" />

                {/* Favicon */}
                <link rel="icon" type="image/png" href="https://www.spacease.com/favicon.ico" />

                {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
                <link rel="canonical" href={`https://www.spacease.com${location.pathname}`} />

                
      </Helmet>
      <div>
        <div className={cssLandingCreateSpace["main__container"]}>
            <header className={cssLandingCreateSpace["main__container__header"]}>
              <aside className={cssLandingCreateSpace["main__container__header__aside"]}>
                <h2 className={cssLandingCreateSpace["main__container__header__aside__title"]}>Pon tu espacio en Spacease 📝</h2>
                <p className={cssLandingCreateSpace["main__container__header__aside__subTitle"]}>
                  Únete gratuitamente a la mejor comunidad de propietarios de locales comerciales en España. 
                  Promociona tu espacio para tiendas o eventos.
                </p>
                <Link className="simple_blue_button w_fit-content" to={URL_UPLOAD_SPACE}>Publicar tu anuncio</Link>
              </aside>
              <div className={cssLandingCreateSpace["main__container__image-container"]}>
                <img className={cssLandingCreateSpace["main__container__image-container__image"]} src={upload_space_landing_1} alt="" />
              </div>
            </header>
            <main  className={cssLandingCreateSpace["main__container__content"]}>
              <section className={cssLandingCreateSpace["main__container__content__card"]}>
                <aside className={cssLandingCreateSpace["main__container__content__card__aside"]}>
                  <p className={cssLandingCreateSpace["main__container__content__card__step"]}>Paso 1</p>
                  <h3 className={cssLandingCreateSpace["main__container__content__card__title"]}>Completa tu anuncio</h3>
                  <p className={cssLandingCreateSpace["main__container__content__card__subTitle"]}>
                    Establece tus precios, métricas, información relevante, fotos y más detalles. 
                    Cuanto más detallado sea tu anuncio más visibilidad tendrá.
                  </p>
                </aside>
                <div className={cssLandingCreateSpace["main__container__content__card__image-container"]}>
                  <img className={cssLandingCreateSpace["main__container__content__card__image-container__image"]} src={upload_space_landing_2} alt="" />
                </div>
              </section>
              <section className={cssLandingCreateSpace["main__container__content__card"]}>
                <aside className={cssLandingCreateSpace["main__container__content__card__aside"]}>
                  <p className={cssLandingCreateSpace["main__container__content__card__step"]}>Paso 2</p>
                  <h3 className={cssLandingCreateSpace["main__container__content__card__title"]}>Publícalo</h3>
                  <p className={cssLandingCreateSpace["main__container__content__card__subTitle"]}>
                    Una vez completado tu anuncio, lo revisaremos manualmente y te avisaremos si hemos encontrado fallos. 
                    Cuando todo esté listo lo publicaremos inmediatamente en la plataforma.
                  </p>
                </aside>
                <div className={cssLandingCreateSpace["main__container__content__card__image-container"]}>
                  <img className={cssLandingCreateSpace["main__container__content__card__image-container__image"]} src={home_1} alt="" />
                </div>
              </section>
              <section className={cssLandingCreateSpace["main__container__content__card"]}>
                <aside className={cssLandingCreateSpace["main__container__content__card__aside"]}>
                  <p className={cssLandingCreateSpace["main__container__content__card__step"]}>Paso 3</p>
                  <h3 className={cssLandingCreateSpace["main__container__content__card__title"]}>Recibe clientes</h3>
                  <p className={cssLandingCreateSpace["main__container__content__card__subTitle"]}>
                    Los usuarios registrados de Spacease podrán contactarte mediante la 
                    información de contacto que dejes al configurar el anuncio.
                  </p>
                </aside>
                <div className={cssLandingCreateSpace["main__container__content__card__image-container"]}>
                  <img className={cssLandingCreateSpace["main__container__content__card__image-container__image"]} src={upload_space_landing_3} alt="" />
                </div>
              </section>
            </main>
        </div>
      </div>
      <section className={cssLandingCreateSpace["questions__container"]}>
        <div className="w-100" >
          <h2 className={cssLandingCreateSpace["questions__container__title"]}>
            Preguntas frecuentes
          </h2>
        </div>
        <aside>
          <div>
            <Accordion sx={accordion_blue_background}>
              <AccordionSummary
                sx={accordionSummary_owners}
                expandIcon={SVG.SHOW_MORE_ICON}
              >
                <p className={cssLandingCreateSpace["questions__container__question"]}>
                  ¿Qué es Spacease?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className={cssLandingCreateSpace["questions__container__answer"]}>
                  Spacease es una startup tecnológica española que pretende
                  conectar empresas e individuales que buscan alquilar locales
                  comerciales con dueños de espacios.
                </p>
                <Link
                  to={URL_ABOUT_US}
                  target="_blank"
                  className={cssLandingCreateSpace["questions__container__link"]}
                >
                  Quiero saber más {SVG.FLECHA_DERECHA_AZUL}
                </Link>
              </AccordionDetails>
            </Accordion>
            <Accordion
            sx={accordion_blue_background}>
              <AccordionSummary 
              sx={accordionSummary_owners}
              expandIcon={SVG.SHOW_MORE_ICON}>
                <p className={cssLandingCreateSpace["questions__container__question"]}>
                  ¿Cuáles son los requisitos para que mi espacio sea aceptado?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className={cssLandingCreateSpace["questions__container__answer"]}>
                  Establecemos requisitos mínimos para publicar anuncios en la
                  plataforma con el objetivo de mejorar la experiencia de
                  usuario
                </p>
                <Link
                  to={URL_RULES}
                  target="_blank"
                  className={cssLandingCreateSpace["questions__container__link"]}
                >
                  Visitar requisitos <strong></strong>
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                >
                  <g clip-path="url(#clip0_750_1131)">
                    <path
                      d="M9.75 0.992188L15.25 6.24219L9.75 11.4922"
                      stroke="#034CF3"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15 6.24219H0.75"
                      stroke="#034CF3"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_750_1131">
                      <rect
                        width="16"
                        height="12"
                        fill="white"
                        transform="translate(0 0.241699)"
                      />
                    </clipPath>
                  </defs>
                  </svg>
                </Link>
              </AccordionDetails>
            </Accordion>
            <Accordion
            sx={accordion_blue_background}>
              <AccordionSummary
                sx={accordionSummary_owners}
                expandIcon={SVG.SHOW_MORE_ICON}
              >
                <p className={cssLandingCreateSpace["questions__container__question"]}>
                  ¿Cuánto cuesta el servicio?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className={cssLandingCreateSpace["questions__container__answer"]}>
                  Publicar anuncios en Spacease es gratuito por el momento ya
                  que estamos en las primeras fases de desarrollo y seguimos
                  explorando posibles opciones de monetización para el futuro
                </p>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={accordion_blue_background}>
              <AccordionSummary
                sx={accordionSummary_owners}
                expandIcon={SVG.SHOW_MORE_ICON}
              >
                <p className={cssLandingCreateSpace["questions__container__question"]}>
                  ¿Cómo me pueden contactar los usuarios?
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <p className={cssLandingCreateSpace["questions__container__answer"]}>
                  Puedes definir datos de contacto a la hora de crear el espacio
                  que los usuarios registrados podrán consultar en caso de estar
                  interesdos
                </p>
              </AccordionDetails>
            </Accordion>
          </div>
        </aside>
      </section>
      <section className={cssLandingCreateSpace["footer__container"]}>
          <StillHavingQuestions/>
      </section>
    </>
  );
};
