import React, { useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "../css/mapLegend.css";

const Map = (props) => {
  const { className, coordinatesIn , showSpace, showRadious} = props;
  const mapDiv = useRef(null);
  let coordinates;
  if(coordinatesIn[0] !== 0 && coordinatesIn[1] !== 0){
    coordinates = { lat: coordinatesIn[0], lng: coordinatesIn[1] };
  }else{
    coordinates = {lat:40.416580, lng:-3.703731}
  }

  const addLegend = (map) => {
    const legend = L.control({ position: "bottomright" });

    legend.onAdd = function () {
      const div = L.DomUtil.create("div", "info legend");
      div.innerHTML +=
        '<i style="background: #007BFF; opacity: 0.35;"></i> Area aproximada de la ubicación<br>';
      return div;
    };

    legend.addTo(map);
  };

  useEffect(() => {
    const map = L.map(mapDiv.current, { zoom: 100 }).setView(
      [coordinates.lat, coordinates.lng],
      15
    ); // Ajusta el zoom para mostrar mejor el área

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map);

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: "/images/marker-icon-2x.png",
      iconUrl: "/images/marker-icon.png",
      shadowUrl: "/images/marker-shadow.png",
    });
    
    if (showRadious === true) {
      const displacement = 0.001; // Valor de desplazamiento ajustable Def 0.0005
    
      const randomLatDisplacement = (Math.random() - 0.5) * 2 * displacement;
      const randomLngDisplacement = (Math.random() - 0.5) * 2 * displacement;
    
      const modifiedLat = coordinates.lat + randomLatDisplacement;
      const modifiedLng = coordinates.lng + randomLngDisplacement;
    
      L.circle([modifiedLat, modifiedLng], {
        color: "#007BFF", // Color azul de Bootstrap
        fillColor: "#007BFF",
        fillOpacity: 0.35,
        weight: 1, // Remueve el borde
        radius: 300,
      }).addTo(map);
    }
    else{

      L.marker([coordinates.lat, coordinates.lng]).addTo(map);

    }

    if (showSpace) {
      addLegend(map); // Añade la leyenda
    }

    return () => {
      map.remove();
    };
  }, [coordinatesIn,showRadious]);

  
  return (
    <div
      className={`br_0-93rem ${className}`}
      ref={mapDiv}
      style= {{width: "100%",height:"25rem"}}
    ></div>
  );
};

export default Map;
