import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Error from "./Error.js";
import {
  customTab,
  customTextField,
  errorLabel,
  errorLabel1Rem,
  toogleButtonGroupSimple,
  toogleButtonGroupSimpleGap,
  toogleButtonSimple,
} from "../components/Styles";
import { getVisibilityInfo } from "../context/modelHelper";
import { useImageUploader } from "../hooks/useImageUploader";
import {
  FieldNames,
  MinMaxLenghtValues,
  Categories_Data,
  initialSpace,
  StatusValues,
  VisibilityValues,
  AttributesEnum,
} from "../helpers/Consts";
import Divider from "../components/Divider";
import { SVG } from "../helpers/Svgs";
import useSpaceValidation from "../hooks/useSpaceValidation.js";
import Map from "../components/Map";
import "../css/editSpace.css";
import {
  Tabs,
  Tab,
  TextField,
  Alert,
  Checkbox,
  Dialog,
  FormControlLabel,
  ImageList,
  InputAdornment,
  InputLabel,
  Paper,
  Snackbar,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  CircularProgress
} from "@mui/material";
import { Button } from "react-bootstrap";
import { useAppContext } from "../context/appContext.js";
import cssProfileConfig from "../css/profileConfig.module.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className={cssProfileConfig['verticalPanel']}
    >
     
      {value === index && (
        <main
          className={cssProfileConfig['tabPanel__Container']}
          style={{maxWidth:"70rem"}}
        >
          {children}
        </main>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function BotonContenido({ isLoading }) {
  return isLoading ? <CircularProgress color="inherit" size={19} thickness={7}/>  : <>Guardar Cambios</>;
}

const EditSpace = () => {
  const { id } = useParams();
  const categories = Object.values(Categories_Data);
  const [value, setValue] = useState(0);
  const [error, setError] = useState("");
  const [errorToSave, setErrorToSave] = useState({
    showError: false,
    errorMessage: "",
    errorType: "",
  });
  const {
    newSpace,
    defaultSpace,
    saveActiveStep,
    getSpaceOnlyByUser,
    validateNextStep,
    resaveNewSpace,
    selectedMapOption,
    reInicializeNewSpace,
    handleRadiousMap,
    HandleMapOptionClick,
    mapOptions,
    HandleSelectedIdMapOption,
    selectedIdMapOption,
    isOpenValidateLocation,
    errors,
    temporalCoordinates,
    toogleIsOpenValidateLocation,
    handleStatus,
    handleVisibility,
    setValueNewSpace,
    handleAddressValidated,
    handleAtrributesCheckboxChange,
    handleSpaceTypesCheckboxChange,
    setDefaultSpace,
    stateInfoValues,
    handleMapOptions,
  } = useSpaceValidation(initialSpace);

  const {updateSpace,isLoading,alertType,alertText,showAlert,disableAlert} = useAppContext();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    saveActiveStep(newValue);
  };

  const {
    images,
    handleImageChange,
    countAllImages,
    resetCountAllImages,
    handleRemoveImageUrl,
    handleRemoveImage,
    errorMessageImages,
    imagesToRemove,
  } = useImageUploader();
  

  async function fetchData() {
    try {
      const spaceResult = await getSpaceOnlyByUser(id);
      const parsedSpace = {
        ...spaceResult,
        avgVisitors: spaceResult.avgVisitors != null ? spaceResult.avgVisitors.toString() : null,
        dailyPrice: spaceResult.dailyPrice != null ? spaceResult.dailyPrice.toString() : null,
        maxDays: spaceResult.maxDays != null ? spaceResult.maxDays.toString() : null,
        minDays: spaceResult.minDays != null ? spaceResult.minDays.toString() : null,
        monthlyPrice: spaceResult.monthlyPrice != null ? spaceResult.monthlyPrice.toString() : null,
        spaceSize: spaceResult.spaceSize != null ? spaceResult.spaceSize.toString() : null,
        weeklyPrice: spaceResult.weeklyPrice != null ? spaceResult.weeklyPrice.toString() : null,
      };
      reInicializeNewSpace(parsedSpace);
      resetCountAllImages(parsedSpace.images.length, true);
    } catch (error) {
      if (error?.request?.status === 401) {
        setError("No tiene permisos para acceder");
      }
    }
  }

  useEffect(() => {
    fetchData();
    return () => {
      setError("");
    };
  }, []);

  const validateChangesAndSave = (countImages = 0) => {
    let newChanges = false;

    if (validateNextStep(countImages)) {
      let spaceToUpdate = { _id: newSpace._id };

      switch (value) {
        case 0: {
          if (defaultSpace.name !== newSpace.name) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.name]: newSpace.name,
            };
            newChanges = true;
          }

          if (defaultSpace.description !== newSpace.description) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.description]: newSpace.description,
            };
            newChanges = true;
          }

          if (
            !defaultSpace.spaceType.every((defaultItem, index) => {
              return defaultItem === newSpace.spaceType.at(index);
            })
          ) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.spaceType]: newSpace.spaceType,
            };
            newChanges = true;
          }

          break;
        }
        case 1: {
          console.log(defaultSpace.phoneNumber)
          if (defaultSpace.phoneNumber !== newSpace.phoneNumber) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.phoneNumber]: newSpace.phoneNumber,
            };
            newChanges = true;
          }
          if (defaultSpace.email !== newSpace.email) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.email]: newSpace.email,
            };
            newChanges = true;
          }
          break;
        }
        case 2: {
          if (defaultSpace.street !== newSpace.street) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.street]: newSpace.street,
            };
            newChanges = true;
          }
          if (defaultSpace.numberStreet !== newSpace.numberStreet) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.numberStreet]: newSpace.numberStreet,
            };
            newChanges = true;
          }
          if (defaultSpace.extraInfoStreet !== newSpace.extraInfoStreet) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.extraInfoStreet]: newSpace.extraInfoStreet,
            };
            newChanges = true;
          }
          if (defaultSpace.zipCode !== newSpace.zipCode) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.zipCode]: newSpace.zipCode,
            };
            newChanges = true;
          }
          if (defaultSpace.city !== newSpace.city) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.city]: newSpace.city,
            };
            newChanges = true;
          }
          if (defaultSpace.coordinates !== newSpace.coordinates) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.coordinates]: newSpace.coordinates,
            };
            newChanges = true;
          }
          break;
        }
        case 3: {
          if (defaultSpace.visibility !== newSpace.visibility) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.visibility]: newSpace.visibility,
            };
            newChanges = true;
          }
          break;
        }
        case 4: {
          if (
            !defaultSpace.attributes.every((defaultItem, index) => {
              return defaultItem === newSpace.attributes.at(index);
            })
          ) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.attributes]: newSpace.attributes,
            };
            newChanges = true;
          }
          break;
        }
        case 5: {
          if (
            defaultSpace.neighbourDescription !== newSpace.neighbourDescription
          ) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.neighbourDescription]: newSpace.neighbourDescription,
            };
            newChanges = true;
          }
          break;
        }
        case 6: {
          if (defaultSpace.avgVisitors !== newSpace.avgVisitors) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.avgVisitors]: newSpace.avgVisitors,
            };
            newChanges = true;
          }

          if (defaultSpace.spaceSize !== newSpace.spaceSize) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.spaceSize]: newSpace.spaceSize,
            };
            newChanges = true;
          }
          break;
        }
        case 7: {
          if (defaultSpace.state !== newSpace.state) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.state]: newSpace.state,
            };
            newChanges = true;
          }
          if (defaultSpace.minDays !== newSpace.minDays) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.minDays]: newSpace.minDays,
            };
            newChanges = true;
          }
          if (defaultSpace.maxDays !== newSpace.maxDays) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.maxDays]: newSpace.maxDays,
            };
            newChanges = true;
          }
          break;
        }
        case 8: {
          if (defaultSpace.dailyPrice !== newSpace.dailyPrice) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.dailyPrice]: newSpace.dailyPrice,
            };
            newChanges = true;
          }
          if (defaultSpace.weeklyPrice !== newSpace.weeklyPrice) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.weeklyPrice]: newSpace.weeklyPrice,
            };
            newChanges = true;
          }
          if (defaultSpace.monthlyPrice !== newSpace.monthlyPrice) {
            spaceToUpdate = {
              ...spaceToUpdate,
              [FieldNames.monthlyPrice]: newSpace.monthlyPrice,
            };
            newChanges = true;
          }
          break;
        }
        case 9: {
          if (images.length !== 0 || imagesToRemove.length !== 0) {
            newChanges = true;
          }
          break;
        }
        default:
          break;
      }

      if (newChanges) {
        if (updateSpace(spaceToUpdate, imagesToRemove, images)) {
          setDefaultSpace(newSpace);
          setErrorToSave({
            showError: true,
            errorType: "success",
            errorMessage: "Guardado con exito",
          });
        } else {
          setErrorToSave({
            showError: true,
            errorType: "error",
            errorMessage: "Hubo un problema al guardar",
          });
        }
      } else {
        setErrorToSave({
          showError: true,
          errorType: "success",
          errorMessage: "Guardado con exito",
        });
      }
    }
  };

  const handleErroToSave = () => {
    // setErrorToSave({
    //   showError: false,
    //   errorType: "",
    //   errorMessage: "",
    // });
    disableAlert();
  };

  const minor1200px = useMediaQuery('(max-width:1200px)');
  const theme = useTheme()
  return (
    <div className="p-0">
      {error === "" ? (
        <main className="editSpace__container">
          <Snackbar
            open={showAlert}
            onClose={handleErroToSave}
            autoHideDuration={1500}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert severity={alertType}>
              {alertText}
            </Alert>
          </Snackbar>
          <Tabs
            allowScrollButtonsMobile
          // orientation="vertical"
            orientation={minor1200px ? "horizontal" : "vertical"}
            variant={minor1200px ? "scrollable" : "standard"}
            value={value}
            onChange={handleChange}
            sx={{
              minWidth:minor1200px ? "unset": "fit-content",
              marginTop:"1rem",
              ".MuiTabs-flexContainer":{
                paddingTop:minor1200px ? "0" : "1rem",
              },
              ".Mui-disabled":{
                display:"none"
              },
              ".MuiTabs-indicator":{
                backgroundColor:"#034CF3"
              }
              
            }}
          >
            <Tab sx={customTab} label="Informacion Basica" {...a11yProps(0)} />
            <Tab sx={customTab} label="Email / Telefono" {...a11yProps(1)} />
            <Tab sx={customTab} label="Direccion" {...a11yProps(2)} />
            <Tab sx={customTab} label="Visibilidad" {...a11yProps(3)} />
            <Tab
              sx={customTab}
              label="Que ofrece tu espacio"
              {...a11yProps(4)}
            />
            <Tab sx={customTab} label="Descripcion barrio" {...a11yProps(5)} />
            <Tab sx={customTab} label="Estadisticas" {...a11yProps(6)} />
            <Tab
              sx={customTab}
              label="Situacion de tu Espacio"
              {...a11yProps(7)}
            />
            <Tab sx={customTab} label="Precio" {...a11yProps(8)} />
            <Tab sx={customTab} label="Fotos" {...a11yProps(9)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <>
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  Empecemos con la información básica
                </h1>
                <h2 className="editSpace__container__step__container__section__subHeader">
                  ¿Cuál es el título de tu anuncio?
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  Este es el título visible que aparecerá en los resultados de
                  búsqueda
                </p>
                <TextField
                  className={errors.name.shake ? "shake" : ""}
                  error={errors.name.hasError}
                  sx={customTextField}
                  id={FieldNames.name}
                  value={newSpace.name}
                  onChange={setValueNewSpace}
                  placeholder="Título de tu espacio"
                />
                <div className="d-flex justify-content-between">
                  <p className="editSpace__container__step__container__section__helper">
                    {MinMaxLenghtValues.name.max} caracteres max.
                  </p>
                  <p className="editSpace__container__step__container__section__helper">
                    {newSpace.name?.length}
                  </p>
                </div>
              </section>
              <Divider />
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  ¿Cómo describirías tu espacio?
                </h2>
                {errors.description.message && (
                  <InputLabel sx={errorLabel} error>
                    {errors.description.message}
                  </InputLabel>
                )}
                <TextField
                  className={errors.description.shake ? "shake" : ""}
                  error={errors.description.hasError}
                  sx={customTextField}
                  id={FieldNames.description}
                  value={newSpace.description}
                  onChange={setValueNewSpace}
                  multiline
                  rows={4}
                  placeholder="Describe lo que hace tu espacio único"
                />
                <div className="d-flex justify-content-between">
                  <p className="editSpace__container__step__container__section__helper">
                    {MinMaxLenghtValues.description.min} caracteres min.{" "}
                    {MinMaxLenghtValues.description.max} caracteres max.
                  </p>
                  <p className="editSpace__container__step__container__section__helper">
                    {newSpace.description?.length}
                  </p>
                </div>
              </section>
              <Divider />
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  ¿Cuál de estas opciones representa mejor tu espacio?
                </h1>
                {errors.spaceType.hasError ? (
                  <InputLabel
                    sx={errorLabel}
                    className={errors.spaceType.shake ? "shake" : ""}
                    error
                  >
                    Selecciona una o varias opciones
                  </InputLabel>
                ) : (
                  <p className="editSpace__container__step__container__section__description">
                    Selecciona una o varias opciones
                  </p>
                )}
                <div className="d-flex flex-column">
                  {categories.map((attribute, index) => (
                    <FormControlLabel
                      checked={newSpace.spaceType[index] === 1 ? true : false}
                      key={index}
                      control={
                        <Checkbox
                          disableRipple
                          onChange={() => {
                            handleSpaceTypesCheckboxChange(index);
                          }}
                        />
                      }
                      label={attribute}
                    />
                  ))}
                </div>

              <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
              </section>
            </>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <>
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  Datos de contacto para alquilar el espacio
                </h1>
                <p className="editSpace__container__step__container__section__description">
                  Los usuarios pueden solicitar contactar con el dueño del
                  espacio, cuando lo hagan se mostrarán estos datos
                </p>
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Número de teléfono
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  Usa un número de teléfono al que tengas acceso y donde te
                  puedan llamar
                </p>
                <TextField
                  className={errors.phoneNumber.shake ? "shake" : ""}
                  error={errors.phoneNumber.hasError}
                  sx={customTextField}
                  id={FieldNames.phoneNumber}
                  value={newSpace.phoneNumber}
                  onChange={setValueNewSpace}
                  placeholder="Número de teléfono"
                />
              </section>
              <Divider />
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Email
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  Usa un email al que tengas acceso y que revises regularmente
                </p>
                {errors.email.hasError && (
                  <InputLabel sx={errorLabel} error>
                    Debes de introducir un email valido
                  </InputLabel>
                )}
                <TextField
                  className={errors.email.shake ? "shake" : ""}
                  error={errors.email.hasError}
                  sx={customTextField}
                  type="email"
                  id={FieldNames.email}
                  value={newSpace.email}
                  onChange={setValueNewSpace}
                  placeholder="Email"
                />
                <Button
                  onClick={() => {
                    validateChangesAndSave();
                  }}
                  className="w_fit-content fw_500 button-black"
                  style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
                >
                  <BotonContenido isLoading={isLoading}/>
                </Button>
              </section>
            </>
          </TabPanel>

          <TabPanel value={value} index={2}>
            <>
              <h1 className="editSpace__container__step__container__section__header">
                ¿Dónde se encuentra tu espacio?
              </h1>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Nombre de la vía
                </h2>
                <TextField
                  className={errors.street.shake ? "shake" : ""}
                  error={errors.street.hasError}
                  sx={customTextField}
                  id={FieldNames.street}
                  value={newSpace.street}
                  onChange={setValueNewSpace}
                  placeholder="P.Ej.: Calle Alcalá"
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Número
                </h2>
                <TextField
                  sx={customTextField}
                  className={errors.numberStreet.shake ? "shake" : ""}
                  error={errors.numberStreet.hasError}
                  id={FieldNames.numberStreet}
                  value={newSpace.numberStreet}
                  onChange={setValueNewSpace}
                  placeholder="P.Ej.: 20"
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Edificio, portal, puerta, etc. (opcional)
                </h2>
                <TextField
                  sx={customTextField}
                  id={FieldNames.extraInfoStreet}
                  value={newSpace.extraInfoStreet}
                  onChange={setValueNewSpace}
                  placeholder="P.Ej.: 6D"
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Código postal
                </h2>
                <TextField
                  sx={customTextField}
                  className={errors.zipCode.shake ? "shake" : ""}
                  error={errors.zipCode.hasError}
                  id={FieldNames.zipCode}
                  value={newSpace.zipCode}
                  onChange={setValueNewSpace}
                  placeholder="Escribe el código postal"
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Ciudad/Pueblo
                </h2>
                <TextField
                  sx={customTextField}
                  className={errors.city.shake ? "shake" : ""}
                  error={errors.city.hasError}
                  id={FieldNames.city}
                  value={newSpace.city}
                  onChange={setValueNewSpace}
                  placeholder="P.Ej.: Madrid"
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Mostrar ubicación aproximada
                </h2>
                <div className="d-flex gap-2 align-items-center">
                  <p className="editSpace__container__step__container__section__description">
                    No mostraremos públicamente la ubicación exacta de tu
                    espacio, únicamente la zona aproximada donde se encuentra.
                  </p>
                  <Switch
                    checked={newSpace.radiousMap}
                    onChange={handleRadiousMap}
                  />
                </div>
              </section>
              <Button
                className={`${
                  errors.buttonMaps.shake ? "shake" : ""
                } fs_0-875rem fw_500 simple_blue_button`}
                onClick={handleMapOptions}
              >
                Confirmar dirección
              </Button>
              <Dialog
                sx={{
                  "& .MuiDialog-paper": {
                    borderRadius: "1.3rem",
                  },
                }}
                open={isOpenValidateLocation}
                onClose={toogleIsOpenValidateLocation}
              >
                <Paper className="dialog__validation">
                  <section className="editSpace__container__step__container__section align-items-center">
                    <h1 className="editSpace__container__step__container__section__header">
                      Confirma tu dirección
                    </h1>
                    <p className="editSpace__container__step__container__section__description">
                      Selecciona una de las siguientes opciones{" "}
                    </p>
                  </section>
                  <ToggleButtonGroup
                    className="toogleButton"
                    exclusive
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.62rem",
                    }}
                    value={selectedIdMapOption}
                    onChange={(event, option) => {
                      HandleSelectedIdMapOption(option);
                    }}
                  >
                    {mapOptions?.map((mapOption) => (
                      <ToggleButton
                        key={mapOption.place_id}
                        onClick={(e) => {
                          HandleMapOptionClick(mapOption);
                        }}
                        value={mapOption.place_id}
                      >
                        {mapOption.display_name}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>

                  <Map
                    coordinatesIn={temporalCoordinates}
                    showRadious={newSpace.radiousMap}
                  />
                  <Button
                    disabled={selectedIdMapOption === null ? true : false}
                    className="fs_0-875rem fw_500 simple_blue_button"
                    onClick={(e) => {
                      toogleIsOpenValidateLocation();
                      handleAddressValidated(selectedMapOption);
                    }}
                  >
                    Confirmar
                  </Button>
                </Paper>
              </Dialog>

              {newSpace.coordinates[0] !== 0 &&
              newSpace.coordinates[1] !== 0 ? (
                <Map
                  coordinatesIn={newSpace.coordinates}
                  showRadious={newSpace.radiousMap}
                ></Map>
              ) : null}
            </>
              <Button
                  onClick={() => {
                    validateChangesAndSave();
                  }}
                  className="w_fit-content fw_500 button-black"
                  style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
                >
                  <BotonContenido isLoading={isLoading}/>
                </Button>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <>
              <h1 className="editSpace__container__step__container__section__header">
                ¿Qué tipo de visibilidad tiene tu espacio?
              </h1>
              {errors.visibility.hasError ? (
                <InputLabel
                  sx={errorLabel}
                  className={`${errors.visibility.shake ? "shake" : ""}`}
                  error
                >
                  Selecciona una opción
                </InputLabel>
              ) : (
                <p
                  className={`editSpace__container__step__container__section__description `}
                >
                  Selecciona una opción
                </p>
              )}
              <ToggleButtonGroup
                sx={toogleButtonGroupSimpleGap}
                exclusive
                value={newSpace.visibility}
                onChange={(event, option) => {
                  handleVisibility(option);
                }}
              >
                {Object.values(VisibilityValues).map((item, index) => {
                  const visibilityInfo = getVisibilityInfo(item);
                  return (
                    <ToggleButton
                      sx={toogleButtonSimple}
                      key={item}
                      value={item}
                    >
                      <div className="d-flex align-items-center gap_1-25rem">
                        {visibilityInfo.svg}
                        <div className="d-flex flex-column w-100 align-items-start">
                          <p className="editSpace__container__step__container__section__subHeader">
                            {visibilityInfo.mainText}
                          </p>
                          <p className="editSpace__container__step__container__section__description">
                            {visibilityInfo.secondaryText}
                          </p>
                        </div>
                      </div>
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            </>
            <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <>
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  ¿Qué ofrece tu espacio?
                </h1>
                {errors.attributes.hasError ? (
                  <InputLabel
                    className={`${errors.attributes.shake ? "shake" : ""}`}
                    sx={errorLabel}
                    error
                  >
                    Selecciona una o varias opciones
                  </InputLabel>
                ) : (
                  <p className="editSpace__container__step__container__section__description">
                    Selecciona una o varias opciones
                  </p>
                )}
              </section>
              <section className="editSpace__container__step__container__section">
                <div style={{display:"grid",gridTemplateColumns:"repeat(auto-fill, minmax(14rem, 1fr))"}}>
                  {Object.values(AttributesEnum).map((attribute, index) => (
                    <FormControlLabel
                      checked={newSpace.attributes[index] === 1 ? true : false}
                      key={index}
                      control={
                        <Checkbox
                          value={true}
                          disableRipple
                          onChange={() => {
                            handleAtrributesCheckboxChange(index);
                          }}
                        />
                      }
                      label={attribute.text}
                    />
                  ))}
                </div>
              </section>
            </>
            <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <>
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  ¿Cómo es el área/barrio en el que se encuentra tu espacio?
                </h1>
                <p className="editSpace__container__step__container__section__description">
                  TIP: Las marcas y empresas se fijan mucho en el área a la hora
                  de escoger un espacio. Escribe un pequeño texto donde
                  expliques por qué el área donde está tu espacio es el sitio
                  perfecto para lanzar una tienda{" "}
                </p>
              </section>
              <section className="editSpace__container__step__container__section">
                {errors.neighbourDescription.hasError && (
                  <InputLabel sx={errorLabel} error>
                    Minimo {MinMaxLenghtValues.neighbourDescription.min}{" "}
                    caracteres
                  </InputLabel>
                )}
                <TextField
                  className={errors.neighbourDescription.shake ? "shake" : ""}
                  error={errors.neighbourDescription.hasError}
                  sx={customTextField}
                  id={FieldNames.neighbourDescription}
                  value={newSpace.neighbourDescription}
                  onChange={setValueNewSpace}
                  multiline
                  rows={4}
                  placeholder="Describe el área"
                />
                <div className="d-flex justify-content-between">
                  <p className="editSpace__container__step__container__section__helper">
                    100 caracteres min. 1000 caracteres max.
                  </p>
                  <p className="editSpace__container__step__container__section__helper">
                    {newSpace.neighbourDescription
                      ? newSpace.neighbourDescription.length
                      : 0}{" "}
                    caracteres
                  </p>
                </div>
              </section>
              <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
            </>
          </TabPanel>
          <TabPanel value={value} index={6}>
            <>
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  Estadísticas sobre tu espacio
                </h1>
                <h2 className="editSpace__container__step__container__section__subHeader">
                  ¿Cuántos visitantes de media tiene tu espacio al día?
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  TIP: Las marcas y empresas son más propensas a alquilar
                  espacios que muestran esta información
                </p>
                <TextField
                  className={errors.avgVisitors.shake ? "shake" : ""}
                  error={errors.avgVisitors.hasError}
                  type="number"
                  sx={customTextField}
                  id={FieldNames.avgVisitors}
                  value={newSpace.avgVisitors}
                  onChange={setValueNewSpace}
                  placeholder="P.Ej.: 20500"
                />
                <p className="editSpace__container__step__container__section__helper">
                  Si no lo sabes pon “0”
                </p>
              </section>
              <Divider />
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  ¿Cuánto mide tu espacio?
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  Superficie utilizable del espacio
                </p>
                <TextField
                  className={errors.spaceSize.shake ? "shake" : ""}
                  error={errors.spaceSize.hasError}
                  type="number"
                  sx={customTextField}
                  id={FieldNames.spaceSize}
                  value={newSpace.spaceSize}
                  onChange={setValueNewSpace}
                  placeholder="Tamaño en metros cuadrados. P.Ej.: 152"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p className="m-0">m²</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </section>
            </>
            <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
          </TabPanel>
          <TabPanel value={value} index={7}>
            <>
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  ¿En qué situación se encuentra tu espacio?
                </h1>
                {errors.state.hasError ? (
                  <InputLabel
                    className={`${errors.state.shake ? "shake" : ""}`}
                    sx={errorLabel}
                    error
                  >
                    Selecciona una opción
                  </InputLabel>
                ) : (
                  <p className="editSpace__container__step__container__section__description">
                    Selecciona una opción
                  </p>
                )}
              </section>
              <ToggleButtonGroup
                sx={toogleButtonGroupSimple}
                exclusive
                value={newSpace.state}
                onChange={(event, option) => {
                  handleStatus(option);
                }}
              >
                <ToggleButton
                  sx={toogleButtonSimple}
                  key={StatusValues.Ready}
                  value={StatusValues.Ready}
                >
                  <div className="d-flex align-items-center gap_1-25rem">
                    {stateInfoValues[0].svg}
                    <div className="d-flex flex-column w-100 align-items-start">
                      <p className="editSpace__container__step__container__section__subHeader">
                        {stateInfoValues[0].mainText}
                      </p>
                      <p className="editSpace__container__step__container__section__description">
                        {stateInfoValues[0].secondaryText}
                      </p>
                    </div>
                  </div>
                </ToggleButton>
                <p className="editSpace__container__step__container__section__helper">
                  {stateInfoValues[0].description}
                </p>
                <ToggleButton
                  sx={toogleButtonSimple}
                  key={StatusValues.Empty}
                  value={StatusValues.Empty}
                >
                  <div className="d-flex align-items-center gap_1-25rem">
                    {stateInfoValues[1].svg}
                    <div className="d-flex flex-column w-100 align-items-start">
                      <p className="editSpace__container__step__container__section__subHeader">
                        {stateInfoValues[1].mainText}
                      </p>
                      <p className="editSpace__container__step__container__section__description">
                        {stateInfoValues[1].secondaryText}
                      </p>
                    </div>
                  </div>
                </ToggleButton>
                <p className="editSpace__container__step__container__section__helper">
                  {stateInfoValues[1].description}
                </p>
                <ToggleButton
                  sx={toogleButtonSimple}
                  key={StatusValues.Conditioning}
                  value={StatusValues.Conditioning}
                >
                  <div className="d-flex align-items-center gap_1-25rem">
                    {stateInfoValues[2].svg}
                    <div className="d-flex flex-column w-100 align-items-start">
                      <p className="editSpace__container__step__container__section__subHeader">
                        {stateInfoValues[2].mainText}
                      </p>
                      <p className="editSpace__container__step__container__section__description">
                        {stateInfoValues[2].secondaryText}
                      </p>
                    </div>
                  </div>
                </ToggleButton>
                <p className="editSpace__container__step__container__section__helper">
                  {stateInfoValues[2].description}
                </p>
              </ToggleButtonGroup>
              <Divider />
              <section className="editSpace__container__step__container__section">
                <h1 className="editSpace__container__step__container__section__header">
                  Días máximos y mínimos de reserva
                </h1>
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Días máximos (opcional)
                </h2>
                <TextField
                  sx={customTextField}
                  type="number"
                  id={FieldNames.maxDays}
                  value={newSpace.maxDays}
                  onChange={setValueNewSpace}
                  placeholder="Dejar en blanco si no hay requerimiento máximo"
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Días mínimos (opcional)
                </h2>
                <TextField
                  sx={customTextField}
                  type="number"
                  id={FieldNames.minDays}
                  value={newSpace.minDays}
                  onChange={setValueNewSpace}
                  placeholder="Dejar en blanco si no hay requerimiento mínimo"
                />
              </section>
            </>
            <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
          </TabPanel>
          <TabPanel value={value} index={8}>
            <>
              <h1 className="editSpace__container__step__container__section__header">
                Precio del espacio
              </h1>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Precio diario
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  Sin IVA
                </p>
                <TextField
                  sx={customTextField}
                  type="number"
                  id={FieldNames.dailyPrice}
                  value={newSpace.dailyPrice}
                  onChange={setValueNewSpace}
                  placeholder="Precio en euros (EUR)"
                  className={errors.dailyPrice.shake ? "shake" : ""}
                  error={errors.dailyPrice.hasError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p className="m-0">€</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Precio semanal
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  Sin IVA
                </p>
                <TextField
                  sx={customTextField}
                  type="number"
                  id={FieldNames.weeklyPrice}
                  value={newSpace.weeklyPrice}
                  onChange={setValueNewSpace}
                  placeholder="Precio en euros (EUR)"
                  className={errors.weeklyPrice.shake ? "shake" : ""}
                  error={errors.weeklyPrice.hasError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p className="m-0">€</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </section>
              <section className="editSpace__container__step__container__section">
                <h2 className="editSpace__container__step__container__section__subHeader">
                  Precio mensual
                </h2>
                <p className="editSpace__container__step__container__section__description">
                  Sin IVA
                </p>
                <TextField
                  sx={customTextField}
                  type="number"
                  id={FieldNames.monthlyPrice}
                  value={newSpace.monthlyPrice}
                  onChange={setValueNewSpace}
                  placeholder="Precio en euros (EUR)"
                  className={errors.monthlyPrice.shake ? "shake" : ""}
                  error={errors.monthlyPrice.hasError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <p className="m-0">€</p>
                      </InputAdornment>
                    ),
                  }}
                />
              </section>
            </>
            <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
          </TabPanel>
          <TabPanel value={value} index={9}>
            <>
              <Snackbar
                open={errorMessageImages !== null}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert severity="error">{errorMessageImages}</Alert>
              </Snackbar>
              <h1 className="editSpace__container__step__container__section__header">
                Fotos del espacio
              </h1>
              <section className="editSpace__container__step__container__section">
                {errors.images.hasError ? (
                  <InputLabel
                    className={errors.images.shake ? "shake" : ""}
                    sx={errorLabel1Rem}
                    error
                  >
                    Sube al menos {MinMaxLenghtValues.images.min} fotos que
                    representen bien tu espacio
                  </InputLabel>
                ) : (
                  <h2 className="editSpace__container__step__container__section__subHeader">
                    Sube al menos {MinMaxLenghtValues.images.min} fotos que
                    representen bien tu espacio
                  </h2>
                )}

                <p className="editSpace__container__step__container__section__description">
                  TIP: Haz fotografías bien iluminadas y de buena calidad
                  tomadas desde diferentes ángulos para mostrar claramente las
                  capacidades del espacio
                </p>
                {countAllImages < 10 ? (
                  <div
                    className={`button__upload-images ${
                      errors.images.shake ? "shake" : ""
                    }`}
                  >
                    <section className="editSpace__container__step__container__section align-items-center">
                      <p className="button__upload-images_mainText">
                        + Subir imágenes
                      </p>
                      <p className="editSpace__container__step__container__section__description">
                        .JPG o .PNG. Máx 10MB
                      </p>
                    </section>
                    <input
                      accept=".png, .jpg"
                      type="file"
                      multiple
                      onChange={(e) => {
                        handleImageChange(e);
                      }}
                    ></input>
                  </div>
                ) : null}
                <p className="editSpace__container__step__container__section__helper">
                  Maximo {MinMaxLenghtValues.images.max} imagenes.
                </p>
                <ImageList cols={1} sx={{ gap: "15px" }}>
                  {images.map((image, index) => (
                    <div key={index} className="uploaded-image__container">
                      <img
                        className="uploaded-image__image"
                        src={URL.createObjectURL(image)}
                        alt={`${index}`}
                      />
                      {countAllImages > 5 ? (
                        <Button
                          className="uploaded-image__close"
                          onClick={() => handleRemoveImage(index)}
                        >
                          {SVG.X}
                        </Button>
                      ) : null}
                    </div>
                  ))}
                  {newSpace?.images?.map((image, index) => (
                    <div key={image} className="uploaded-image__container">
                      <img
                        className="uploaded-image__image"
                        src={image}
                        alt={"ALOG"}
                      />
                      {countAllImages > 5 ? (
                        <Button
                          className="uploaded-image__close"
                          onClick={() =>
                            handleRemoveImageUrl(
                              index,
                              newSpace,
                              resaveNewSpace
                            )
                          }
                        >
                          {SVG.X}
                        </Button>
                      ) : null}
                    </div>
                  ))}
                </ImageList>
              </section>
            </>
            <Button
                onClick={() => {
                  validateChangesAndSave();
                }}
                className="w_fit-content fw_500 button-black"
                style={isLoading ? {lineHeight:"1",minWidth:"7.9rem"}:{}}
              >
                <BotonContenido isLoading={isLoading}/>
              </Button>
          </TabPanel>
        </main>
      ) : (
        <Error />
      )}
    </div>
  );
};

export default EditSpace;
