import {
  CircularProgress,
  ImageList,
  Tabs,
  Tab,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import SpaceCard from "../components/SpaceCard.js";
import { useAppContext } from "../context/appContext";
import { URL_UPLOAD_SPACE } from "../context/urlPath";
import cssControlPanel from "../css/controlPanel.module.css";
import { PlatformVisibilityTypes } from "../helpers/Consts";
import { SVG } from "../helpers/Svgs.js";

const ControlPanel = () => {
  const { user, spaces, getSpacesUser, isLoading, updateSpace } =
    useAppContext();
  const [category, setcategory] = useState("Todos");
  const location = useLocation();

  const changePlatFormVisibilityValue = async (id, platformValue) => {
    await updateSpace({ _id: id, platformVisibility: platformValue });
    getSpacesUser(category);
  };


  const handleCategory = (event, newCategory) => {
    if (newCategory != null) {
      setcategory(newCategory);
      getSpacesUser(newCategory);
    }
  };
  useEffect(() => {
    getSpacesUser(category);
  }, [user]);

  const theme = useTheme();

  return (
    <>
      <div className="">
        <Helmet>
          {/* Título básico */}
          <title>Panel de control de espacios</title>

          {/* Caracteres y idioma */}
          <meta charSet="utf-8" />
          <html lang="es" />

          {/* Descripción de la página */}
          <meta name="description" content="Panel de control de espacios" />

          {/* Mobile Meta */}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          {/* Favicon */}
          <link
            rel="icon"
            type="image/png"
            href="https://www.spacease.com/favicon.ico"
          />

          {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
          <link
            rel="canonical"
            href={`https://www.spacease.com${location.pathname}`}
          />
        </Helmet>
        <div className={cssControlPanel["main__container"]}>
          <h1 className={cssControlPanel["main__container__title"]}>Hola, {user ? user.name : "Eiyi"}</h1>
          <h2 className={cssControlPanel["main__container__subTitle"]}>Mis espacios</h2>
          <div style={{maxWidth:"100%"}}>
            <Tabs
              value={category}
              onChange={handleCategory}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{
                alignItems:"center",
                
                "& .Mui-disabled":{
                  display:"none"
                },
                "& .MuiTabs-indicator":{
                  display:"none"
                },
                "& .MuiTabs-flexContainer":{
                  gap:".75rem"
                },
                [theme.breakpoints.down('sm')]: {
                  "& .MuiTabScrollButton-root":{
                    width:"20px"
                  }
                },
              }}
            >
                {Object.keys(PlatformVisibilityTypes).map((item) => (
                  <Tab 
                  // disableRipple 
                  key={item}
                  sx={{
                    fontFamily:"inherit",
                    color:"#717171",
                    fontWeight:"500",
                    minHeight:"unset",
                    padding:".5rem 1.5rem",
                    textTransform:"none",
                    borderRadius:".6rem",
                    border:"1px solid rgba(34, 34, 34, 0.25)",
                    backgroundColor:"#FFF",
                    "&.Mui-selected":{
                      color:"#034CF3",
                      border:"1px solid #034CF3",
                      backgroundColor:"rgba(3, 76, 243, 0.05)"
                    },
                    [theme.breakpoints.down('sm')]: {
                      // Estilos específicos para pantallas más grandes (a partir de 'sm')
                      // Puedes ajustar esto según tus necesidades
                      // Por ejemplo, cambia 'sm' a 'md' para pantallas medianas
                      // color:"red"
                    },
                    
                  }} 
                  label={item} 
                  value={item}>
                    
                  </Tab>
                  
                ))}
            </Tabs>
          </div>
          <Link to={URL_UPLOAD_SPACE} className={cssControlPanel["main__container__uploadSpace"]}>
            {SVG.CIRCULO_MAS_DENTRO}
            <p className="mb-0 mt-2">Subir un nuevo espacio</p>
          </Link>
          {isLoading ? (
            <div className="w-100 d-flex justify-content-center">
              <CircularProgress />{" "}
            </div>
          ) : (
            <div className="w-100">
              <ImageList
              sx={{
                gridTemplateColumns:
                  "repeat(auto-fill,minmax(20rem,1fr))!important",
                  gap:"24px !important"
              }}
              >
                
              {spaces.length > 0
                ? spaces.map((item) => (
                      <SpaceCard id={item._id} type="control" space={item} desactivate={changePlatFormVisibilityValue} />
                  ))
                : null}
              </ImageList>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ControlPanel;
