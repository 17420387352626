import { StatusValues, VisibilityValues } from '../helpers/Consts.js';
import { SVG } from '../helpers/Svgs.js';

export const getVisibilityInfo = (value) => {
    switch (value) {
      case VisibilityValues.Street:
        return {
          mainText: "A pie de calle",
          svg: SVG.OJO,
          secondaryText: "Alta visibilidad",
        };
      case VisibilityValues.Mall:
        return {
            mainText: "En un centro comercial",
            svg: SVG.TIENDA,
            secondaryText: "El espacio se beneficia del tráfico de un centro comercial",
          };
      case VisibilityValues.Building:
        return {
            mainText: "Dentro de un edificio",
            svg:SVG.EDIFICIO ,
            secondaryText: "Para acceder al espacio primero tienes que entrar en un edificio",
          };
      default:
        return {
            mainText: "Street",
            svg: <svg>Street SVG</svg>,
            secondaryText: 1,
          };
    }
};

export const getStateInfo = (value) => {
    switch (value) {
      case StatusValues.Ready:
        return {
          mainText: "Listo para entrar",
          svg: SVG.ENTRAR_FLECHA_DENTRO,
          secondaryText: "Disponible con muebles y accesorios",
          description:"El espacio está completamente preparado y amueblado, lo que permite al inquilino comenzar a operar de inmediato sin necesidad de realizar ningún trabajo adicional"
        };
      case StatusValues.Empty:
        return {
            mainText: "Vacío y despejado ",
            svg:SVG.CAMION,
            secondaryText: "No cuenta con muebles o accesorios",
            description:"El inquilino puede necesitar algún tiempo para instalar sus propios muebles y configurar el espacio según sus necesidades antes de comenzar a operar"
          };
      case StatusValues.Conditioning:
        return {
            mainText: "Requiere acondicionamiento",
            svg: SVG.CIRCULO_EXCLAMACION,
            secondaryText: "Puede requerir trabajos de renovación o reparación",
            description:"Puede requerir trabajos de construcción, renovación o reparación, lo que lleva más tiempo antes de que el inquilino pueda comenzar a operar en el lugar"
          };
      default:
        return {
            mainText: "Street",
            svg: <svg>Street SVG</svg>,
            secondaryText: 1,
          };
    }
};


export const AvgVisitorData = (avgVisitors) => {

    return{
        mainText: `${avgVisitors} visitantes diarios`,
        svg: SVG.DOS_USUARIOS,
        secondaryText: "Tráfico del espacio",
    }
}


export const SizeSpaceData = (size) => {
    
    return{
        mainText: `${size} m2`,
        svg: SVG.CUADRADO_SOLO_ESQUINAS,
        secondaryText: "Área utilizable",
    }
}

export const DaysInfo = (min,max) =>{
    return{
        mainText: `${min} a ${max} días de alquiler `,
        svg: SVG.CALENDARIO,
        secondaryText: "Alquiler flexible",
    }
}



export const getInitialArray = (quantity) => {
  if (typeof quantity !== 'number' || quantity < 0) {
    throw new Error('La cantidad debe ser un número positivo');
  }

  return Array.from({ length: quantity }, () => 0);
}




//EXPRESIONES REGULARES PARA VALIDACIONES 

;
export const onlyNumbersAllowed = (value)=>{
  return value.replace(/[^\d]/g, '')
}

