import { SVG } from './Svgs.js';
export const StatusValues = {
    Ready: "Ready",
    Empty: "Empty",
    Conditioning: "Conditioning",
  };
  export const VisibilityValues = {
    Street: "Street",
    Mall: "Mall",
    Building: "Building",
  };
  export const AttributesEnum = {
      Iluminación: { text: "Iluminación", svg: SVG.SOL},
      Escaparate: { text: "Escaparate", svg: SVG.TIENDA},
      Wifi: { text: "Wifi", svg: SVG.WIFI},
      Baños: { text: "Baños", svg: SVG.FLECHA_DERECHA},
      Sótano: { text: "Sótano", svg: SVG.FLECHA_DERECHA},
      Parking: { text: "Parking", svg: SVG.FLECHA_DERECHA},
      Estanterías: { text: "Estanterías", svg:SVG.ESTANTERIA},
      Mostradores: { text: "Mostradores", svg: SVG.BOLSO},
      Cambiadores: { text: "Cambiadores", svg: SVG.FLECHA_DERECHA},
      Cocina: { text: "Cocina", svg: SVG.FLECHA_DERECHA},
      Calefacción: { text: "Calefacción", svg: SVG.FLECHA_DERECHA},
      AireAcondicionado: { text: "Aire acondicionado", svg: SVG.FLECHA_DERECHA},
      SistemasDeSeguridad: { text: "Sistemas de seguridad", svg:SVG.ESCUDO_EXCLAMACION },
      PersianasDeSeguridad: { text: "Persianas de seguridad", svg: SVG.ESCUDO},
      AccesibilidadPorSillaDeRuedas: { text: "Accesibilidad por silla de ruedas", svg: SVG.FLECHA_DERECHA},
      EspacioCompartido: { text: "Espacio compartido", svg: SVG.ESPACIO_COMPARTIDO },
      Almacén: { text: "Almacén", svg:SVG.CAJA},
      BañoParaEmpleados: { text: "Baño para empleados", svg: SVG.FLECHA_DERECHA},
      Terraza: {text: "Posibilidad de terraza", svg: SVG.FLECHA_DERECHA},
  };

  export const Categories_Data = {
    Pop_upStores: "Pop-up Stores",
    Moda: "Moda",
    Showrooms: "Showrooms",
    CentrosComerciales: "Centros Comerciales",
    EspaciosCreativos: "Espacios creativos",
    ComidaYBebida: "Comida y bebida",
    Cafeterías: "Cafeterías",
    Mercadillos: "Mercadillos",
  };
  

  
export const MinMaxLenghtValues = {
    name: { min: 0, max: 50 },
    description: { min: 100, max: 5000 },
    spaceType: { min: 0, max: 0 },
    phoneNumber: { min: 9, max: 9 },
    email: { min: 0, max: 100 },
    street: { min: 0, max: 300 },
    numberStreet: { min: 0, max: 10 },
    extraInfoStreet: { min: 0, max: 50 },
    zipCode: { min: 0, max: 10 },
    city: { min: 0, max: 100 },
    coordinates: { min: 0, max: 0 },
    visibility: { min: 0, max: 0 },
    attributes: { min: 0, max: 0 },
    neighbourDescription: { min: 100, max: 1000 },
    avgVisitors: { min: 0, max: 0 },
    spaceSize: { min: 0, max: 0 },
    state: { min: 0, max: 0 },
    minDays: { min: 0, max: 3 },
    maxDays: { min: 0, max: 3 },
    dailyPrice: { min: 0, max: 0 },
    weeklyPrice: { min: 0, max: 0 },
    monthlyPrice: { min: 0, max: 0 },
    province: { min: 0, max: 0 },
    images: { min: 0, max: 10 },
    // images: { min: 5, max: 10 },
  };
  
  export const FieldNames = {
    name: "name",
    description: "description",
    spaceType: "spaceType",
    phoneNumber: "phoneNumber",
    email: "email",
    street: "street",
    numberStreet: "numberStreet",
    extraInfoStreet: "extraInfoStreet",
    zipCode: "zipCode",
    city: "city",
    radiousMap: "radiousMap",
    coordinates: "coordinates",
    visibility: "visibility",
    attributes: "attributes",
    neighbourDescription: "neighbourDescription",
    avgVisitors: "avgVisitors",
    spaceSize: "spaceSize",
    state: "state",
    minDays: "minDays",
    maxDays: "maxDays",
    dailyPrice: "dailyPrice",
    weeklyPrice: "weeklyPrice",
    monthlyPrice: "monthlyPrice",
    province: "province",
    images: "images",
    buttonMaps:"buttonMaps"
  };
  export const errorsInitialState = {
    name: { hasError: false, message: "", shake: false },
    description: { hasError: false, message: "", shake: false },
    spaceType: { hasError: false, message: "", shake: false },
    phoneNumber: { hasError: false, message: "", shake: false },
    email: { hasError: false, message: "", shake: false },
    street: { hasError: false, message: "", shake: false },
    numberStreet: { hasError: false, message: "", shake: false },
    extraInfoStreet: { hasError: false, message: "", shake: false },
    zipCode: { hasError: false, message: "", shake: false },
    city: { hasError: false, message: "", shake: false },
    radiousMap: { hasError: false, message: "", shake: false },
    coordinates: { hasError: false, message: "", shake: false },
    visibility: { hasError: false, message: "", shake: false },
    attributes: { hasError: false, message: "", shake: false },
    neighbourDescription: { hasError: false, message: "", shake: false },
    avgVisitors: { hasError: false, message: "", shake: false },
    spaceSize: { hasError: false, message: "", shake: false },
    state: { hasError: false, message: "", shake: false },
    minDays: { hasError: false, message: "", shake: false },
    maxDays: { hasError: false, message: "", shake: false },
    dailyPrice: { hasError: false, message: "", shake: false },
    weeklyPrice: { hasError: false, message: "", shake: false },
    monthlyPrice: { hasError: false, message: "", shake: false },
    province: { hasError: false, message: "", shake: false },
    images: { hasError: false, message: "", shake: false },
    buttonMaps:{hasError:false,message:"",shake:false}
  };

  // export const initialSpace = {
  //   name: "AAAASDASDSBFVBVB",
  //   description: "AAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVB",
  //   spaceType: Array(Object.keys(Categories_Data).length).fill(1), //Array 0 o 1
  //   phoneNumber: "623423423",
  //   email: "aaolc@ho.com",
  //   street: "San Damian",
  //   numberStreet: "9",
  //   extraInfoStreet: "",
  //   zipCode: "16002",
  //   city: "Cuenca",
  //   province: "",
  //   radiousMap: false,
  //   coordinates: [0, 0],
  //   visibility: "Street", //Street Mall Building
  //   attributes: Array(Object.keys(AttributesEnum).length).fill(1), //Array 0 o 1
  //   neighbourDescription: "AAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVBAAAASDASDSBFVBVB",
  //   avgVisitors: "2", //Number
  //   spaceSize: "3", //Number
  //   state: "Ready", //Enum Ready, Empty Conditioning
  //   minDays: "3", //Number
  //   maxDays: "4", //Number
  //   dailyPrice: "12", //Number
  //   weeklyPrice: "23", //Number
  //   monthlyPrice: "354", //Number
  // };

  export const initialSpace = {
    name: "",
    description: "",
    spaceType: Array(Object.keys(Categories_Data).length).fill(0), //Array 0 o 1
    phoneNumber: "",
    email: "",
    street: "",
    numberStreet: "",
    extraInfoStreet: "",
    zipCode: "",
    city: "",
    province: "",
    radiousMap: false,
    coordinates: [0, 0],
    visibility: "", //Street Mall Building
    attributes: Array(Object.keys(AttributesEnum).length).fill(0), //Array 0 o 1
    neighbourDescription: "",
    avgVisitors: "", //Number
    spaceSize: "", //Number
    state: "", //Enum Ready, Empty Conditioning
    minDays: "", //Number
    maxDays: "", //Number
    dailyPrice: "", //Number
    weeklyPrice: "", //Number
    monthlyPrice: "", //Number
  };

  export const PlatformVisibilityTypes = {
    Todos: "Todos",
    Pendiente: "Pendiente",
    Borrador: "Borrador",
    Publicado: "Publicado",
    Rechazado: "Rechazado",
    Inactivo: "Inactivo",
    Desactivado: "Desactivado",
  };