import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import { URL_DASHBOARD } from '../context/urlPath';

const Error = () => {
  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row>
        <Col xs={12} className="text-center">
          <Alert variant="danger">
            <Alert.Heading>¡Vaya! ¡Parece que te has perdido!</Alert.Heading>
            <p>
              No te preocupes, puedes volver al inicio haciendo clic en el botón de abajo.
            </p>
            <hr />
            <div className="d-flex justify-content-center">
              <Button as={Link} to={URL_DASHBOARD}variant="outline-success">
                Volver al inicio
              </Button>
            </div>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
};

export default Error;
