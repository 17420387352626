import { initialState } from "./appContext";
import { useCallback } from "react";

import {
  DISABLE_ALERT,
  RESET_VALUE,
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_ERROR,
  GET_CURRENT_USER_SUCCESS,
  GET_DASHBOARD_BEGIN,
  GET_DASHBOARD_ERROR,
  GET_DASHBOARD_SUCCESS,
  GET_MAP_OPTIONS_BEGGIN,
  GET_MAP_OPTIONS_ERROR,
  GET_MAP_OPTIONS_SUCCESS,
  GET_SPACES_BY_ID_BEGGIN,
  GET_SPACES_BY_ID_ERROR,
  GET_SPACES_BY_ID_SUCCESS,
  GET_SPACES_USER_BEGGIN,
  GET_SPACES_USER_ERROR,
  GET_SPACES_USER_SUCCESS,
  LOGOUT_USER_BEGGIN,
  LOGOUT_USER_ERROR,
  LOGOUT_USER_SUCCESS,
  SETUP_USER_BEGIN,
  SETUP_USER_ERROR,
  SETUP_USER_SUCCESS,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_CITY,
  UPDATE_PASSWORD_BEGIN,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PROFILEPIC_BEGIN,
  UPDATE_PROFILEPIC_ERROR,
  UPDATE_PROFILEPIC_SUCCESS,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  CREATE_SPACE_BEGGIN,
  CREATE_SPACE_SUCCESS,
  CREATE_SPACE_ERROR,
  GET_SPACE_ONLY_BY_USER_BEGGIN,
  GET_SPACE_ONLY_BY_USER_SUCCESS,
  GET_SPACE_ONLY_BY_USER_ERROR
} from "./actions";

const reducer = (state, action) => {

  switch (action.type) {
    case UPDATE_USER_BEGIN: {
      return {
        ...state,
        isLoading: true,
        userLoading:false,
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userLoading:false,
        user: action.payload.user,
        showAlert: true,
        alertType: "success",
        alertText: "Se han guardado los cambios correctamente",
      };
    }
    case UPDATE_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        userLoading: false,
        alertType: "error",
        alertText: action.payload.msg,
      };
    }
    case UPDATE_PROFILEPIC_BEGIN: {
      return {
        ...state,
        isLoading: true,
        userLoading:false,
      };
    }
    case UPDATE_PROFILEPIC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userLoading:false,
        user: action.payload.user,
        showAlert: true,
        alertText: "Se han guardado los cambios correctamente",
      };
    }
    case UPDATE_PROFILEPIC_ERROR: {
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        userLoading: false,
        alertType: "error",
        alertText: action.payload.msg,
      };
    }
    case UPDATE_PASSWORD_BEGIN: {
      return {
        ...state,
        isLoading: true,
        userLoading:false,
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userLoading:false,
        user: action.payload.user,
        showAlert: true,
        alertText: "Se han guardado los cambios correctamente",
      };
    }
    case UPDATE_PASSWORD_ERROR: {
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        userLoading: false,
        alertType: "error",
        alertText: action.payload.msg,
      };
    }
    case SETUP_USER_BEGIN: {
      return {
        ...state,
        isLoading: true,
        userLoading:true,
      };
    }
    case SETUP_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userLoading:false,
        user: action.payload.user,
      };
    }
    case SETUP_USER_ERROR: {
      return {
        ...state,
        isLoading: false,
        showAlert: true,
        userLoading:false,
        alertType: "error",
        alertText: action.payload.msg,
      };
    }
    case GET_DASHBOARD_BEGIN:{
      return {
        ...state,
        isLoading:true,
      };
    }
    case GET_DASHBOARD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        spaces:action.payload.spaces,
        totalSpaces: action.payload.totalSpaces,
        numOfPages:action.payload.numOfPages
      };
    }
    case GET_DASHBOARD_ERROR:{
      return{
        ...state,
        isLoading:false,
        showAlert:true,
        alertType:"error",
        alertText: action.payload.msg,
      }
    }
    case GET_SPACES_USER_BEGGIN:{
      return{
        ...state,
        isLoading:true
      }
    }
    case GET_SPACES_USER_SUCCESS:{
      return{
        ...state,
        isLoading:false,
        userLoading:false,
        spaces:action.payload
      }
    }
    case GET_SPACES_USER_ERROR:{
      return{
        ...state,
        isLoading:false,
        showAlert:true,
        alertType:"error",
        alertText:action.payload.msg
      }
    }

    case CREATE_SPACE_BEGGIN:{
      return{
        ...state,
        isLoading:true
      }
    }
    case CREATE_SPACE_SUCCESS:{
      return{
        ...state,
        isLoading:false,
        successPost:true,
      }
    }
    case CREATE_SPACE_ERROR:{
      return{
        ...state,
        isLoading: false,
        successPost:false,
        alertText: action.payload.msg,
      }
    }
    case GET_SPACE_ONLY_BY_USER_BEGGIN:{
      return{
        ...state,
        isLoading:true,
      }
    }
    case GET_SPACE_ONLY_BY_USER_SUCCESS:{
      return{
        ...state,
        isLoading:false,
        showAlert:true,
        alertType:"success",
        alertText:action.payload.msg
      }
    }
    case GET_SPACE_ONLY_BY_USER_ERROR:{
      return{
        ...state,
        isLoading:false,
        showAlert:true,
        alertType:"error",
        alertText:action.payload.msg
      }
    }
    case GET_SPACES_BY_ID_BEGGIN:{
      return{
        ...state,
        isLoading:true,
      }
    }
    case GET_SPACES_BY_ID_SUCCESS:{
      return{
        ...state,
        isLoading:false,
        spaceById:action.payload
      }
    }
    case GET_SPACES_BY_ID_ERROR:{
      return{
        ...state,
        isLoading: false,
        alertText: action.payload.msg,
      }
    }
    case GET_CURRENT_USER_BEGIN: {
      return {
        ...state,
        userLoading: true,
        showAlert: false,
      }
    }
    case GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        userLoading: false,
        user: action.payload.user,
      }
    }
    case GET_CURRENT_USER_ERROR: {
      return {
        ...state,
        userLoading: false,
      }
    }
    case LOGOUT_USER_BEGGIN: {
      return {
        ...state,
        userLoading: true,
        isLoading:true
      };
    }
    case LOGOUT_USER_SUCCESS:{
      return{
        ...state,
        user:null,
        userLoading:false,
        isLoading:false,
      }
    }
    case LOGOUT_USER_ERROR:{
      return{
        ...state,
        userLoading:false,
        isLoading:false,
        showAlert:true,
        alertType:"error",
        alertText:action.payload.msg
      }
    }

    case SET_SELECTED_CITY:{
      return{
        ...state,
        selectedCity:action.payload
      }
    }
    case SET_SELECTED_CATEGORY:{
      return{
        ...state,
        selectedCategory:action.payload
      }
    }

    case GET_MAP_OPTIONS_BEGGIN:{
      return{
        ...state,
        isLoading:true
      }
    }
    case GET_MAP_OPTIONS_SUCCESS:{
      return{
        ...state,
        isLoading:false,
        mapOptions:action.payload,
      }
    }
    case GET_MAP_OPTIONS_ERROR:{
      return{
        ...state,
        isLoading:false,
        showAlert:true,
        alertType:"error",
        alertText:action.payload.msg
      }
    }

    case RESET_VALUE:{
      return{
        ...state,
        [action.field]:action.value,
      }
    }
    case DISABLE_ALERT:{
      return{
        ...state,
        showAlert:false,
        alertText: ""
      }
    }
    default: {
      throw new Error(`no such action: ${action.type}`);
    }
  }
};



export default reducer;
