export const URL_DASHBOARD = "/"
export const URL_LOGIN = "/login"
export const URL_REGISTER = "/register"
export const URL_PROFILE = "/profile"
export const URL_PANELCONTROL ="/control-panel"
export const URL_UPLOAD_SPACE = "/upload-space"
export const URL_PRIVACY_POLICY = "/privacy-policy"
export const URL_TERMS_OF_USE = "/terms-of-use"
export const URL_CONTACT = "/contact"
export const URL_HELP_CENTER = "/help-center"
export const URL_HOW_DOES_IT_WORK = "/how-does-it-work"
export const URL_ABOUT_US = "/about-us"
export const URL_CITIES = "/cities"
export const URL_RULES = "/owners/rules"
export const URL_LANDING_CREATE_SPACE = "/owners"
export const URL_LANDING = "/home"
export const URL_EDIT_SPACE = (id,onlyUrl)=>{

    if(id){
       return `/edit-space/${id}`
    }
    if(onlyUrl){
        return 'edit-space'
    }
    return "/edit-space/:id"
}
export const URL_RESET_PASSWORD = "/reset-pwd"
export const URL_RESET_PASSWORD_REQUEST = "/reset-pwd/request"

export const URL_GETSPACE = (id,onlyUrl) =>{
    if(id){
        return `/space/${id}`
    }
    if(onlyUrl){
        return "/space/"
    }
    return "/space/:id"
}