export const ENDPOINT_LOGIN = "/auth/login"
export const ENDPOINT_REGISTER = "/auth/register"
export const ENDPOINT_UPDATE_USER = "/auth/updateUser"
export const ENDPOINT_UPDATE_PASSWORD = "/auth/updatePassword"
export const ENDPOINT_UPDATE_PROFILE_PIC = "/auth/updateProfilePic"
export const ENDPOINT_SPACES = "/spaces/"
export const ENDPOINT_GET_SPACES_BY_USER = "/spaces/listSpaces"
export const ENDPOINT_LOGOUT = "/auth/logout"
export const ENDPOINT_GET_CURRENT_USER = "/auth/getCurrentUser"
export const ENDPOINT_MAP_OPTIONS = "/spaces/searchAddress"
export const ENDPOINT_CONTACT_INFO = "/spaces/contact-info"
export const ENDPOINT_GET_SPACE_ONLY_BY_USER = "/spaces/edit-space/"