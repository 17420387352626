import {
  Alert,
  Checkbox,
  Dialog,
  FormControlLabel,
  ImageList,
  InputAdornment,
  InputLabel,
  MobileStepper,
  Paper,
  Snackbar,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Divider from "../components/Divider";
import Map from "../components/Map";
import {
  customTextField,
  errorLabel,
  errorLabel1Rem,
} from "../components/Styles.js";
import { getVisibilityInfo } from "../context/modelHelper";
import { URL_PANELCONTROL } from "../context/urlPath";
import "../css/uploadSpace.css";
import {
  AttributesEnum,
  Categories_Data,
  FieldNames,
  MinMaxLenghtValues,
  StatusValues,
  VisibilityValues,
  initialSpace,
} from "../helpers/Consts";
import { SVG } from "../helpers/Svgs.js";
import useSpaceValidation from "../hooks/useSpaceValidation";
import { useImageUploader } from "../hooks/useImageUploader";
import { useAppContext } from "../context/appContext";
import mixpanel from '../helpers/Mixpanel.js';

const UploadSpace = () => {
  const {
    images,
    handleImageChange,
    countAllImages,
    handleRemoveImage,
    errorMessageImages,
  } = useImageUploader();
  const {
    newSpace,
    alertText,
    successPost,
    selectedMapOption,
    handleRadiousMap,
    HandleMapOptionClick,
    mapOptions,
    HandleSelectedIdMapOption,
    selectedIdMapOption,
    isOpenValidateLocation,
    activeStep,
    errors,
    handleBack,
    temporalCoordinates,
    toogleIsOpenValidateLocation,
    handleNext,
    handleStatus,
    handleVisibility,
    setValueNewSpace,
    handleAddressValidated,
    handleAtrributesCheckboxChange,
    handleSpaceTypesCheckboxChange,
    stateInfoValues,
    handleMapOptions,
  } = useSpaceValidation(initialSpace);
  const categories = Object.values(Categories_Data);
  const { createSpace, isLoading } = useAppContext();

  const minor1130px = useMediaQuery("(max-width:70.625em)");

  const saveNewSpace = () => {
    createSpace(newSpace, images);
  };

  return (
    <div className="uploadSpace__container">
      <div className="uploadSpace__container__step__container">
        {activeStep === 0 &&(
            <>
              <section className="uploadSpace__container__step__container__section">
                <h1 className="uploadSpace__container__step__container__section__header">
                  Empecemos con la información básica
                </h1>
                <h2 className="uploadSpace__container__step__container__section__subHeader">
                  ¿Cuál es el título de tu anuncio?
                </h2>
                <p className="uploadSpace__container__step__container__section__description">
                  Este es el título visible que aparecerá en los resultados de
                  búsqueda
                </p>
                <TextField
                  className={errors.name.shake ? "shake" : ""}
                  error={errors.name.hasError}
                  sx={customTextField}
                  id={FieldNames.name}
                  value={newSpace.name}
                  onChange={setValueNewSpace}
                  placeholder="Título de tu espacio"
                />
                <div className="d-flex justify-content-between">
                  <p className="uploadSpace__container__step__container__section__helper">
                    {MinMaxLenghtValues.name.max} caracteres max.
                  </p>
                  <p className="uploadSpace__container__step__container__section__helper">
                    {newSpace.name.length}
                  </p>
                </div>
              </section>
              <Divider />
              <section className="uploadSpace__container__step__container__section">
                <h2 className="uploadSpace__container__step__container__section__subHeader">
                  ¿Cómo describirías tu espacio?
                </h2>
                {errors.description.message && (
                  <InputLabel sx={errorLabel} error>
                    {errors.description.message}
                  </InputLabel>
                )}
                <TextField
                  className={errors.description.shake ? "shake" : ""}
                  error={errors.description.hasError}
                  sx={customTextField}
                  id={FieldNames.description}
                  value={newSpace.description}
                  onChange={setValueNewSpace}
                  multiline
                  rows={4}
                  placeholder="Describe lo que hace tu espacio único"
                />
                <div className="d-flex justify-content-between">
                  <p className="uploadSpace__container__step__container__section__helper">
                    {MinMaxLenghtValues.description.min} caracteres min.{" "}
                    {MinMaxLenghtValues.description.max} caracteres max.
                  </p>
                  <p className="uploadSpace__container__step__container__section__helper">
                    {newSpace.description.length}
                  </p>
                </div>
              </section>
              <Divider />
              <section className="uploadSpace__container__step__container__section">
                <h1 className="uploadSpace__container__step__container__section__header">
                  ¿Cuál de estas opciones representa mejor tu espacio?
                </h1>
                {errors.spaceType.hasError ? (
                  <InputLabel
                    sx={errorLabel}
                    className={errors.spaceType.shake ? "shake" : ""}
                    error
                  >
                    {/* Debes de seleccionar al menos una opcion */}
                    Selecciona una o varias opciones
                  </InputLabel>
                ) : (
                  <p className="uploadSpace__container__step__container__section__description">
                    Selecciona una o varias opciones
                  </p>
                )}
                <div className="d-flex flex-column">
                  {categories.map((attribute, index) => (
                    <FormControlLabel
                      checked={newSpace.spaceType[index] === 1 ? true : false}
                      key={index}
                      control={
                        <Checkbox
                          disableRipple
                          onChange={() => {
                            handleSpaceTypesCheckboxChange(index);
                          }}
                        />
                      }
                      label={attribute}
                    />
                  ))}
                </div>
              </section>
            </>
          )}

        {activeStep === 1 && (
          <>
            <section className="uploadSpace__container__step__container__section">
              <h1 className="uploadSpace__container__step__container__section__header">
                Datos de contacto para alquilar el espacio
              </h1>
              <p className="uploadSpace__container__step__container__section__description">
                Los usuarios pueden solicitar contactar con el dueño del
                espacio, cuando lo hagan se mostrarán estos datos
              </p>
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Número de teléfono
              </h2>
              <p className="uploadSpace__container__step__container__section__description">
                Usa un número de teléfono al que tengas acceso y donde te puedan
                llamar
              </p>
              <TextField
                className={errors.phoneNumber.shake ? "shake" : ""}
                error={errors.phoneNumber.hasError}
                sx={customTextField}
                id={FieldNames.phoneNumber}
                value={newSpace.phoneNumber}
                onChange={setValueNewSpace}
                placeholder="Número de teléfono"
              />
            </section>
            <Divider />
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Email
              </h2>
              <p className="uploadSpace__container__step__container__section__description">
                Usa un email al que tengas acceso y que revises regularmente
              </p>
              {errors.email.hasError && (
                <InputLabel sx={errorLabel} error>
                  Debes de introducir un email valido
                </InputLabel>
              )}
              <TextField
                className={errors.email.shake ? "shake" : ""}
                error={errors.email.hasError}
                sx={customTextField}
                type="email"
                id={FieldNames.email}
                value={newSpace.email}
                onChange={setValueNewSpace}
                placeholder="Email"
              />
            </section>
          </>
        )}

        {activeStep === 2 && (
          <>
            <h1 className="uploadSpace__container__step__container__section__header">
              ¿Dónde se encuentra tu espacio?
            </h1>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Nombre de la vía
              </h2>
              <TextField
                className={errors.street.shake ? "shake" : ""}
                error={errors.street.hasError}
                sx={customTextField}
                id={FieldNames.street}
                value={newSpace.street}
                onChange={setValueNewSpace}
                placeholder="P.Ej.: Calle Alcalá"
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Número
              </h2>
              <TextField
                sx={customTextField}
                className={errors.numberStreet.shake ? "shake" : ""}
                error={errors.numberStreet.hasError}
                id={FieldNames.numberStreet}
                value={newSpace.numberStreet}
                onChange={setValueNewSpace}
                placeholder="P.Ej.: 20"
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Edificio, portal, puerta, etc. (opcional)
              </h2>
              <TextField
                sx={customTextField}
                id={FieldNames.extraInfoStreet}
                value={newSpace.extraInfoStreet}
                onChange={setValueNewSpace}
                placeholder="P.Ej.: 6D"
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Código postal
              </h2>
              <TextField
                sx={customTextField}
                className={errors.zipCode.shake ? "shake" : ""}
                error={errors.zipCode.hasError}
                id={FieldNames.zipCode}
                value={newSpace.zipCode}
                onChange={setValueNewSpace}
                placeholder="Escribe el código postal"
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Ciudad/Población
              </h2>
              <TextField
                sx={customTextField}
                className={errors.city.shake ? "shake" : ""}
                error={errors.city.hasError}
                id={FieldNames.city}
                value={newSpace.city}
                onChange={setValueNewSpace}
                placeholder="P.Ej.: Madrid"
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Mostrar ubicación aproximada
              </h2>
              <div className="d-flex gap-2 align-items-center">
                <p className="uploadSpace__container__step__container__section__description">
                  No mostraremos públicamente la ubicación exacta de tu espacio,
                  únicamente la zona aproximada donde se encuentra.
                </p>
                <Switch
                  checked={newSpace.radiousMap}
                  onChange={handleRadiousMap}
                />
              </div>
            </section>
            <Button
              className={`${
                errors.buttonMaps.shake ? "shake" : ""
              } fs_0-875rem fw_500 simple_blue_button`}
              onClick={handleMapOptions}
            >
              Confirmar dirección
            </Button>
            <Dialog
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "1.3rem",
                },
              }}
              open={isOpenValidateLocation}
              onClose={toogleIsOpenValidateLocation}
            >
              <Paper className="dialog__validation">
                <section className="uploadSpace__container__step__container__section align-items-center">
                  <h1 className="uploadSpace__container__step__container__section__header">
                    Confirma tu dirección
                  </h1>
                  <p className="uploadSpace__container__step__container__section__description">
                    Selecciona una de las siguientes opciones{" "}
                  </p>
                </section>
                <ToggleButtonGroup
                  className="toogleButton"
                  exclusive
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.62rem",
                  }}
                  value={selectedIdMapOption}
                  onChange={(event, option) => {
                    HandleSelectedIdMapOption(option);
                  }}
                >
                  {mapOptions?.map((mapOption) => (
                    <ToggleButton
                      sx={{
                        border: "1px solid red",
                        "&.Mui-selected": {
                          border: "2px solid blue !important",
                          backgroundColor: "rgba(3, 76, 243, 0.05) !important",
                        },
                      }}
                      key={mapOption.place_id}
                      onClick={(e) => {
                        HandleMapOptionClick(mapOption);
                      }}
                      value={mapOption.place_id}
                    >
                      {mapOption.display_name}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>

                <Map
                  coordinatesIn={temporalCoordinates}
                  showRadious={newSpace.radiousMap}
                />
                <Button
                  disabled={selectedIdMapOption === null ? true : false}
                  className="fs_0-875rem fw_500 simple_blue_button"
                  onClick={(e) => {
                    toogleIsOpenValidateLocation();
                    handleAddressValidated(selectedMapOption);
                  }}
                >
                  Confirmar
                </Button>
              </Paper>
            </Dialog>

            {newSpace.coordinates[0] !== 0 && newSpace.coordinates[1] !== 0 ? (
              <Map
                coordinatesIn={newSpace.coordinates}
                showRadious={newSpace.radiousMap}
              ></Map>
            ) : null}
          </>
        )}
        {activeStep === 3 && (
          <>
            <h1 className="uploadSpace__container__step__container__section__header">
              ¿Qué tipo de visibilidad tiene tu espacio?
            </h1>
            {errors.visibility.hasError ? (
              <InputLabel
                sx={errorLabel}
                className={`${errors.visibility.shake ? "shake" : ""}`}
                error
              >
                Selecciona una opción
              </InputLabel>
            ) : (
              <p
                className={`uploadSpace__container__step__container__section__description `}
              >
                Selecciona una opción
              </p>
            )}
            <ToggleButtonGroup
              exclusive
              value={newSpace.visibility}
              onChange={(event, option) => {
                handleVisibility(option);
              }}
            >
              {Object.values(VisibilityValues).map((item, index) => {
                const visibilityInfo = getVisibilityInfo(item);
                return (
                  <ToggleButton key={item} value={item}>
                    <div className="d-flex align-items-center gap_1-25rem">
                      {visibilityInfo.svg}
                      <div className="d-flex flex-column w-100 align-items-start">
                        <p className="uploadSpace__container__step__container__section__subHeader">
                          {visibilityInfo.mainText}
                        </p>
                        <p className="uploadSpace__container__step__container__section__description">
                          {visibilityInfo.secondaryText}
                        </p>
                      </div>
                    </div>
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </>
        )}
        {activeStep === 4 && (
          <>
            <section className="uploadSpace__container__step__container__section">
              <h1 className="uploadSpace__container__step__container__section__header">
                ¿Qué ofrece tu espacio?
              </h1>
              {errors.attributes.hasError ? (
                <InputLabel
                  className={`${errors.attributes.shake ? "shake" : ""}`}
                  sx={errorLabel}
                  error
                >
                  Selecciona una o varias opciones
                </InputLabel>
              ) : (
                <p className="uploadSpace__container__step__container__section__description">
                  Selecciona una o varias opciones
                </p>
              )}
            </section>
            <section className="uploadSpace__container__step__container__section">
              <div className="d-flex flex-column">
                {Object.values(AttributesEnum).map((attribute, index) => (
                  <FormControlLabel
                    checked={newSpace.attributes[index] === 1 ? true : false}
                    key={index}
                    control={
                      <Checkbox
                        value={true}
                        disableRipple
                        onChange={() => {
                          handleAtrributesCheckboxChange(index);
                        }}
                      />
                    }
                    label={attribute.text}
                  />
                ))}
              </div>
            </section>
          </>
        )}

        {activeStep === 5 && (
          <>
            <section className="uploadSpace__container__step__container__section">
              <h1 className="uploadSpace__container__step__container__section__header">
                ¿Cómo es el área/barrio en el que se encuentra tu espacio?
              </h1>
              <p className="uploadSpace__container__step__container__section__description">
                TIP: Las marcas y empresas se fijan mucho en el área a la hora
                de escoger un espacio. Escribe un pequeño texto donde expliques
                por qué el área donde está tu espacio es el sitio perfecto para
                lanzar una tienda{" "}
              </p>
            </section>
            <section className="uploadSpace__container__step__container__section">
              {errors.neighbourDescription.hasError && (
                <InputLabel sx={errorLabel} error>
                  Minimo {MinMaxLenghtValues.neighbourDescription.min}{" "}
                  caracteres
                </InputLabel>
              )}
              <TextField
                className={errors.neighbourDescription.shake ? "shake" : ""}
                error={errors.neighbourDescription.hasError}
                sx={customTextField}
                id={FieldNames.neighbourDescription}
                value={newSpace.neighbourDescription}
                onChange={setValueNewSpace}
                multiline
                rows={4}
                placeholder="Describe el área"
              />
              <div className="d-flex justify-content-between">
                <p className="uploadSpace__container__step__container__section__helper">
                  100 caracteres min. 1000 caracteres max.
                </p>
                <p className="uploadSpace__container__step__container__section__helper">
                  {newSpace.neighbourDescription.length} caracteres
                </p>
              </div>
            </section>
          </>
        )}
        {activeStep === 6 && (
          <>
            <section className="uploadSpace__container__step__container__section">
              <h1 className="uploadSpace__container__step__container__section__header">
                Estadísticas sobre tu espacio
              </h1>
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                ¿Cuántos visitantes de media tiene tu espacio al día?
              </h2>
              <p className="uploadSpace__container__step__container__section__description">
                TIP: Las marcas y empresas son más propensas a alquilar espacios
                que muestran esta información
              </p>
              <TextField
                className={errors.avgVisitors.shake ? "shake" : ""}
                error={errors.avgVisitors.hasError}
                type="number"
                sx={customTextField}
                id={FieldNames.avgVisitors}
                value={newSpace.avgVisitors}
                onChange={setValueNewSpace}
                placeholder="P.Ej.: 20500"
              />
              <p className="uploadSpace__container__step__container__section__helper">
                Si no lo sabes pon “0”
              </p>
            </section>
            <Divider />
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                ¿Cuánto mide tu espacio?
              </h2>
              <p className="uploadSpace__container__step__container__section__description">
                Superficie utilizable del espacio
              </p>
              <TextField
                className={errors.spaceSize.shake ? "shake" : ""}
                error={errors.spaceSize.hasError}
                type="number"
                sx={customTextField}
                id={FieldNames.spaceSize}
                value={newSpace.spaceSize}
                onChange={setValueNewSpace}
                placeholder="Tamaño en metros cuadrados. P.Ej.: 152"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="m-0">m²</p>
                    </InputAdornment>
                  ),
                }}
              />
            </section>
          </>
        )}

        {activeStep === 7 && (
          <>
            <section className="uploadSpace__container__step__container__section">
              <h1 className="uploadSpace__container__step__container__section__header">
                ¿En qué situación se encuentra tu espacio?
              </h1>
              {errors.state.hasError ? (
                <InputLabel
                  className={`${errors.state.shake ? "shake" : ""}`}
                  sx={errorLabel}
                  error
                >
                  Selecciona una opción
                </InputLabel>
              ) : (
                <p className="uploadSpace__container__step__container__section__description">
                  Selecciona una opción
                </p>
              )}
            </section>
            <ToggleButtonGroup
              exclusive
              value={newSpace.state}
              onChange={(event, option) => {
                handleStatus(option);
              }}
            >
              <ToggleButton key={StatusValues.Ready} value={StatusValues.Ready}>
                <div className="d-flex align-items-center gap_1-25rem">
                  {stateInfoValues[0].svg}
                  <div className="d-flex flex-column w-100 align-items-start">
                    <p className="uploadSpace__container__step__container__section__subHeader">
                      {stateInfoValues[0].mainText}
                    </p>
                    <p className="uploadSpace__container__step__container__section__description">
                      {stateInfoValues[0].secondaryText}
                    </p>
                  </div>
                </div>
              </ToggleButton>
              <p className="uploadSpace__container__step__container__section__helper">
                {stateInfoValues[0].description}
              </p>
              <ToggleButton key={StatusValues.Empty} value={StatusValues.Empty}>
                <div className="d-flex align-items-center gap_1-25rem">
                  {stateInfoValues[1].svg}
                  <div className="d-flex flex-column w-100 align-items-start">
                    <p className="uploadSpace__container__step__container__section__subHeader">
                      {stateInfoValues[1].mainText}
                    </p>
                    <p className="uploadSpace__container__step__container__section__description">
                      {stateInfoValues[1].secondaryText}
                    </p>
                  </div>
                </div>
              </ToggleButton>
              <p className="uploadSpace__container__step__container__section__helper">
                {stateInfoValues[1].description}
              </p>
              <ToggleButton
                key={StatusValues.Conditioning}
                value={StatusValues.Conditioning}
              >
                <div className="d-flex align-items-center gap_1-25rem">
                  {stateInfoValues[2].svg}
                  <div className="d-flex flex-column w-100 align-items-start">
                    <p className="uploadSpace__container__step__container__section__subHeader">
                      {stateInfoValues[2].mainText}
                    </p>
                    <p className="uploadSpace__container__step__container__section__description">
                      {stateInfoValues[2].secondaryText}
                    </p>
                  </div>
                </div>
              </ToggleButton>
              <p className="uploadSpace__container__step__container__section__helper">
                {stateInfoValues[2].description}
              </p>
            </ToggleButtonGroup>
            <Divider />
            <section className="uploadSpace__container__step__container__section">
              <h1 className="uploadSpace__container__step__container__section__header">
                Días máximos y mínimos de reserva
              </h1>
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Días máximos (opcional)
              </h2>
              <TextField
                sx={customTextField}
                type="number"
                id={FieldNames.maxDays}
                value={newSpace.maxDays}
                onChange={setValueNewSpace}
                placeholder="Dejar en blanco si no hay requerimiento máximo"
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Días mínimos (opcional)
              </h2>
              <TextField
                sx={customTextField}
                type="number"
                id={FieldNames.minDays}
                value={newSpace.minDays}
                onChange={setValueNewSpace}
                placeholder="Dejar en blanco si no hay requerimiento mínimo"
              />
            </section>
          </>
        )}

        {activeStep === 8 && (
          <>
            <h1 className="uploadSpace__container__step__container__section__header">
              Precio del espacio
            </h1>
            <p className="uploadSpace__container__step__container__section__description">
              Introduce al menos uno de los precios
            </p>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Precio diario
              </h2>
              <p className="uploadSpace__container__step__container__section__description">
                Sin IVA
              </p>
              <TextField
                sx={customTextField}
                type="number"
                id={FieldNames.dailyPrice}
                value={newSpace.dailyPrice}
                onChange={setValueNewSpace}
                placeholder="Precio en euros (EUR)"
                className={errors.dailyPrice.shake ? "shake" : ""}
                error={errors.dailyPrice.hasError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="m-0">€</p>
                    </InputAdornment>
                  ),
                }}
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Precio semanal
              </h2>
              <p className="uploadSpace__container__step__container__section__description">
                Sin IVA
              </p>
              <TextField
                sx={customTextField}
                type="number"
                id={FieldNames.weeklyPrice}
                value={newSpace.weeklyPrice}
                onChange={setValueNewSpace}
                placeholder="Precio en euros (EUR)"
                className={errors.weeklyPrice.shake ? "shake" : ""}
                error={errors.weeklyPrice.hasError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="m-0">€</p>
                    </InputAdornment>
                  ),
                }}
              />
            </section>
            <section className="uploadSpace__container__step__container__section">
              <h2 className="uploadSpace__container__step__container__section__subHeader">
                Precio mensual
              </h2>
              <p className="uploadSpace__container__step__container__section__description">
                Sin IVA
              </p>
              <TextField
                sx={customTextField}
                type="number"
                id={FieldNames.monthlyPrice}
                value={newSpace.monthlyPrice}
                onChange={setValueNewSpace}
                placeholder="Precio en euros (EUR)"
                className={errors.monthlyPrice.shake ? "shake" : ""}
                error={errors.monthlyPrice.hasError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="m-0">€</p>
                    </InputAdornment>
                  ),
                }}
              />
            </section>
          </>
        )}
        {activeStep === 9 && (
          <>
            <Snackbar
              open={errorMessageImages}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity="error">{errorMessageImages}</Alert>
            </Snackbar>
            <h1 className="uploadSpace__container__step__container__section__header">
              Fotos del espacio
            </h1>
            <section className="uploadSpace__container__step__container__section">
              {errors.images.hasError ? (
                <InputLabel
                  className={errors.images.shake ? "shake" : ""}
                  sx={errorLabel1Rem}
                  error
                >
                  Sube al menos {MinMaxLenghtValues.images.min} fotos que
                  representen bien tu espacio
                </InputLabel>
              ) : (
                <h2 className="uploadSpace__container__step__container__section__subHeader">
                  Sube al menos {MinMaxLenghtValues.images.min} fotos que
                  representen bien tu espacio
                </h2>
              )}

              <p className="uploadSpace__container__step__container__section__description">
                TIP: Haz fotografías bien iluminadas y de buena calidad tomadas
                desde diferentes ángulos para mostrar claramente las capacidades
                del espacio
              </p>
              <div
                className={`button__upload-images ${
                  errors.images.shake ? "shake" : ""
                }`}
              >
                <section className="uploadSpace__container__step__container__section align-items-center">
                  <p className="button__upload-images_mainText">
                    + Subir imágenes
                  </p>
                  <p className="uploadSpace__container__step__container__section__description">
                    .JPG o .PNG. Máx 10MB
                  </p>
                </section>
                <input
                  accept=".png, .jpg"
                  type="file"
                  multiple
                  onChange={(e) => {
                    handleImageChange(e);
                  }}
                ></input>
              </div>
              <p className="uploadSpace__container__step__container__section__helper">
                Maximo {MinMaxLenghtValues.images.max} imagenes.
              </p>
              <ImageList cols={1} sx={{ gap: "15px" }}>
                {images.map((image, index) => (
                  <div key={index} className="uploaded-image__container">
                    <img
                      className="uploaded-image__image"
                      src={URL.createObjectURL(image)}
                      alt={`${index}`}
                    />
                    <Button
                      className="uploaded-image__close"
                      onClick={() => handleRemoveImage(index)}
                    >
                      {SVG.X}
                    </Button>
                  </div>
                ))}
              </ImageList>
            </section>
          </>
        )}

        {activeStep === 10 && (
          <>
            <Snackbar
              open={successPost === false}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert severity="error">{alertText}</Alert>
            </Snackbar>
            <div className="d-flex flex-column gap_1-25rem align-items-center">
              <h1 className="uploadSpace__container__step__container__section__header">
                ¡Tu anuncio está listo para ser revisado!
              </h1>
              <h2 className="uploadSpace__container__step__container__section__description">
                Todos los espacios que se suben a la plataforma deben ser
                revisados por el equipo de Spacease para asegurarnos de que
                cumple con los requisitos.
              </h2>
              <Button
                disabled={isLoading}
                onClick={saveNewSpace}
                className="w-100 fs_0-875rem fw_500 simple_blue_button"
              >
                Mandar a revisión y salir
              </Button>
              <p className="uploadSpace__container__step__container__section__helper">
                Nos pondremos en contacto contigo en caso de encontrar problemas
              </p>
              <p className="uploadSpace__container__step__container__section__helper uploadSpace__container__step__container__section__helper-black">
                Si tienes preguntas sobre el proceso, no dudes en escribir un
                email a{" "}
                <span className="uploadSpace__container__step__container__section__helper-blue">
                  hello@spacease.com
                </span>{" "}
                y responderemos lo antes posible
              </p>
            </div>
          </>
        )}

        {activeStep === 12 ? (
          <>
            <h1 className="uploadSpace__container__step__container__section__header">
              ¡Tu <span className="text-blue">Espacio</span> ha sido creado con
              exito!
            </h1>
            <section>
              <p className="uploadSpace__container__step__container__section__subHeader">
                Tu <span className="text-blue">Espacio</span> ha sido registrado
                y está pendiente de validación.
              </p>
              <p className="uploadSpace__container__step__container__section__subHeader">
                Nuestro equipo revisará la información proporcionada y te
                mantendremos informado sobre el estado de validación en breve.
              </p>
              <p className="uploadSpace__container__step__container__section__subHeader">
                Apreciamos tu interés en formar parte de nuestra comunidad y te
                agradecemos por tu paciencia.
              </p>
              <p className="uploadSpace__container__step__container__section__subHeader">
                Esperamos poder brindarte noticias positivas muy pronto.
              </p>
              <p className="uploadSpace__container__step__container__section__subHeader">
                Si tienes alguna pregunta adicional o necesitas asistencia, no
                dudes en ponerte en contacto con nuestro equipo de soporte.
              </p>
              <p className="uploadSpace__container__step__container__section__subHeader">
                ¡Gracias por unirte a nosotros y por confiar en nuestro
                servicio!
              </p>
            </section>
            <Link to={URL_PANELCONTROL}>
              <Button className="simple_white_button p_simple_white_button">
                Ir a tus <span className="text-blue">Espacios</span>
              </Button>
            </Link>
          </>
        ) : null}
      </div>
      {activeStep !== 12 ? (
        <MobileStepper
          variant={minor1130px ? "text" : "progress"}
          steps={11}
          activeStep={activeStep}
          position="bottom"
          backButton={
            <>
              {activeStep !== 0 ? (
                <div className="d-flex justify-content-end button__container-left">
                  <Button
                    onClick={handleBack}
                    className="uploadSpace__container__stepButton uploadSpace__container__stepButton-white"
                  >
                    {SVG.FLECHA_IZQUIERDA}
                    Atrás
                  </Button>
                </div>
              ) : (
                <div
                  style={
                    minor1130px
                      ? { height: "10px", width: "8rem", marginRight: "auto" }
                      : { height: "47px", width: "179px" }
                  }
                ></div>
              )}
            </>
          }
          nextButton={
            <>
              {activeStep !== 10 ? (
                <Button
                  onClick={() => {
                    handleNext(countAllImages);
                  }}
                  className="uploadSpace__container__stepButton uploadSpace__container__stepButton-black"
                >
                  Siguiente paso
                  {SVG.FLECHA_DERECHA_BLANCA}
                </Button>
              ) : (
                <div
                  style={
                    minor1130px
                      ? {
                          height: "10px",
                          width: "11.25rem",
                          marginLeft: "auto",
                        }
                      : { width: "179px", height: "47px" }
                  }
                ></div>
              )}
            </>
          }
        ></MobileStepper>
      ) : null}
    </div>
  );
};

export default UploadSpace;
