import React, { useEffect, useRef, useState } from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  ImageList,
  useMediaQuery,
} from "@mui/material";
import { Button, } from "react-bootstrap";
import SpaceCard from "../components/SpaceCard.js";
import SpaceCardLoading from "../components/SpaceCardLoading.js"
import { useAppContext } from "../context/appContext";
import "../css/dashboard.css";
import { Link } from "react-router-dom";
import { URL_GETSPACE, URL_LANDING } from "../context/urlPath.js";
import { SVG } from "../helpers/Svgs.js";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Categories_Data } from "../helpers/Consts.js";
import { slidder_blue_toogleButton } from "../components/Styles.js";

const Dashboard = () => {
  const {
    getAllSpaces,
    setSelectedCategory,
    numOfPages,
    selectedCity,
    selectedCategory,
    isLoading
  } = useAppContext();

  //Para cargar un espacio en la barra de busqueda si se redirije desde /cities

  const [category, setcategory] = useState("Todos");
  const [pageCounter, setPageCounter] = useState(1);
  const [allSpaces, setAllSpaces] = useState([]);
  const location = useLocation();
  const handleCategory = async (event, newCategory) => {
    if (newCategory !== null) {
      setcategory(newCategory);
      setSelectedCategory(newCategory);
      window.scrollTo(0,0)
    }
  };

  const categories = Object.values(Categories_Data); // Obtener los valores del enum
  categories.unshift("Todos"); // Agregar "Todos" al principio del array

  useEffect(() => {
    const fetchSpaces = async () => {
      const result = await getAllSpaces(pageCounter);
      if (result.length > 0) {
        setAllSpaces((prev) => [...prev, ...result]);
      }
    };

    if (pageCounter !== 1) {
      fetchSpaces();
    }
  }, [pageCounter]);

  useEffect(() => {
    const fetchSpaces = async () => {
      const result = await getAllSpaces(1);
      setAllSpaces(result);
  };

    fetchSpaces();

    return () => {
      setAllSpaces([]);
      setPageCounter(1);
    };
  }, [selectedCategory, selectedCity]);

  const tabsBoxRef = useRef();
  const [isDragging, setIsDragging] = useState(false);


  const handleIcons = (scrollVal) => {
    const tabsBox = tabsBoxRef.current;
    const arrowIcons = document.querySelectorAll(".icon button");
    const maxScrollableWidth = tabsBox.scrollWidth - tabsBox.clientWidth;
    arrowIcons[0].parentElement.style.display = scrollVal <= 0 ? "none" : "flex";
    arrowIcons[1].parentElement.style.display =
      maxScrollableWidth - scrollVal <= 1 ? "none" : "flex";
  };

  const smallThan820px = useMediaQuery('(max-width:820px)');

  const handleArrowClick = (direction) => {
    const tabsBox = tabsBoxRef.current;
    const pxToLeft = smallThan820px ? -120 : -340;
    const pxToRight = smallThan820px ? 120 : 120;
    let scrollWidth =
      tabsBox.scrollLeft + (direction === "left" ? pxToLeft : pxToRight);
    tabsBox.scrollLeft = scrollWidth;
    handleIcons(scrollWidth)
  };

  const handleMouseMove = (e) => {
    //Desactivado el scroll para las categorias en PC!
    // if (!isDragging) return;
    // const tabsBox = tabsBoxRef.current;
    // tabsBox.classList.add("dragging");
    // tabsBox.scrollLeft -= e.movementX;
    // handleIcons(tabsBox.scrollLeft);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };
  const handleMouseUp = () => {
    setIsDragging(false);
    const tabsBox = tabsBoxRef.current;
    tabsBox.classList.remove("dragging");
  };

  return (
    <div className="gap-3">
      <Helmet>
        {/* Título básico */}
        <title>Alquila las mejores tiendas de tu ciudad - Spacease</title>

        {/* Caracteres y idioma */}
        <meta charSet="utf-8" />
        <html lang="es" />

        {/* Descripción de la página */}
        <meta
          name="description"
          content="Encuentra tiendas para alquilar en Madrid y Barcelona. Espacios en calles de prestigio, retail y pop-up stores, centros comerciales y salas de exposición."
        />

        {/* Mobile Meta */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />

        {/* Open Graph (para Facebook y LinkedIn) */}
        <meta
          property="og:title"
          content="Alquila las mejores tiendas de tu ciudad - Spacease"
        />
        <meta
          property="og:description"
          content="Encuentra tiendas para alquilar en Madrid y Barcelona. Espacios en calles de prestigio, retail y pop-up stores, centros comerciales y salas de exposición."
        />
        <meta
          property="og:image"
          content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png"
        />
        <meta property="og:url" content="https://www.spacease.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Spacease" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@Spacease_es" />
        <meta
          name="twitter:title"
          content="Alquila las mejores tiendas de tu ciudad - Spacease"
        />
        <meta
          name="twitter:description"
          content="Encuentra tiendas para alquilar en Madrid y Barcelona. Espacios en calles de prestigio, retail y pop-up stores, centros comerciales y salas de exposición."
        />
        <meta
          name="twitter:image"
          content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png"
        />

        {/* Favicon */}
        <link
          rel="icon"
          type="image/png"
          href="https://www.spacease.com/favicon.ico"
        />

        {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
        <link
          rel="canonical"
          href={`https://www.spacease.com${location.pathname}`}
        />
      </Helmet>
      <div className="slider" onMouseLeave={handleMouseUp}>
        <div className="icon">
          <Button onClick={()=>{handleArrowClick("left")}} variant="flat" disableRipple className="chevron__button-left">
            {SVG.CHEVRON_IZQUIERDA}
          </Button>
        </div>
        <ToggleButtonGroup
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          ref={tabsBoxRef}
          exclusive
          sx={{
            gap: "1.5rem",
            maxWidth: "calc(100vw - 2 * (var(--global-padding-sides) + 1rem))",
            display: "flex",
            overflowX: "auto",
            borderRadius:"0",
            // overflowX: "hidden", //Esta linea quita el scroll en movil
            scrollBehavior:"smooth",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&.dragging":{
              cursor:"grab",
              scrollBehavior:"auto"
            },
            "&.dragging .MuiToggleButton-root":{
              userSelect:"none",
              pointerEvents:"none"
            }
          }}
          f
          value={category}
          onChange={handleCategory}
        >
          {categories?.map((item) => (
            <ToggleButton
              disableRipple={true}
              sx={slidder_blue_toogleButton}
              key={item}
              value={item}
            >
              {item}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <div className="icon">
          <Button  onClick={()=>{handleArrowClick("right")}} variant="flat" className="chevron__button-right">
            {SVG.CHEVRON_DERECHA}
          </Button>
        </div>
      </div>
      <div className="col-12 banner__container position-relative">
        <div className="d-flex gap-2 banner__container-text position-absolute">
          <div className="d-flex gap_1-25rem flex-column p-3 flex-sm-row">
            <div>
              <h1>Alquila los mejores locales comerciales</h1>
              <p>
                Descubre cómo te ayudamos a triunfar en el mundo del retail, para
                que puedas concentrarte en construir y lanzar tu idea.
              </p>
            </div>
            <aside className="align-self-center">
              <a className="simple_white_button text-decoration-none fs_1rem fw_600" style={{whiteSpace:"nowrap"}} alt="Boton conocer mas" href={URL_LANDING}>Conocer más</a>
            </aside>
          </div>
        </div>
      </div>
      
      <div className="w-100">
        {isLoading ? <>
        <ImageList 
          sx={{
            gridTemplateColumns:
              "repeat(auto-fill,minmax(21rem,1fr))!important",
            gap: "24px !important",
          }}
        >
          <SpaceCardLoading/>
          <SpaceCardLoading/>
          <SpaceCardLoading/>
        </ImageList>
        </>
        :<>
        {allSpaces?.length > 0 ? (
          <>
        <ImageList
          sx={{
            gridTemplateColumns:
              "repeat(auto-fill,minmax(21rem,1fr))!important",
            gap: "24px !important",
          }}
        >
              {allSpaces.map((item) => (
                <Link
                  key={item._id}
                  style={{ textDecoration: "none" }}
                  to={URL_GETSPACE(item._id)}
                >
                  <SpaceCard key={item._id} space={item} />
                </Link>
              ))}
              
        </ImageList>
            </>
          ) : (
            <div className="w-100 text-center mt-3 text-black fw_600">
              <p>No se han encontrado espacios</p>
            </div>
          )}
        {pageCounter < numOfPages ? (
          <div className="dashboard__button__loadMoreSpaces__container">
            <Button
              className="dashboard__button__loadMoreSpaces__button upload-space"
              onClick={() => setPageCounter(pageCounter + 1)}
            >
              {SVG.CIRCULO_MAS_DENTRO_SMALL}
              Cargar más espacios
            </Button>
          </div>
        ) : null}

        </>}
      </div>
    </div>
  );
};

export default Dashboard;
