import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/footer.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import { AppProvider } from './context/appContext';
import App from './App';
import { PopUp } from './components/PopUp';

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
      <PopUp/>
    </AppProvider>
  </React.StrictMode>
);

