import React, { useState, useEffect } from "react";
import { Container, Form, Button, FormCheck } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useAppContext } from "../context/appContext";
import { Alert, Snackbar } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { URL_DASHBOARD, URL_REGISTER, URL_LOGIN, URL_GETSPACE, URL_TERMS_OF_USE } from "../context/urlPath";
import "../css/register.css";
import { ENDPOINT_LOGIN, ENDPOINT_REGISTER } from "../context/endpoints";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import mixpanel from "../helpers/Mixpanel.js";

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [password, setPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState(false);

  const [businessAccount, setBusinessAccount] = useState(null);
  const [businessError, setBusinessError] = useState(false);

  const [businessNameError, setBusinessNameError] = useState(false);

  const navigate = useNavigate();

  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  const [isRegister, setRegister] = useState(false);

  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [passwordError, setPasswordError] = useState(false);

  const location = useLocation();

  const { login, register } = props;
  const { showAlert, disableAlert, alertText, alertType, user, setUpUser } =
    useAppContext();

  const timeHideAlert = 1000;
  const params = new URLSearchParams(location.search);
  const spaceId = params.get('spaceId');
  useEffect(() => {
    if (login) {
      setRegister(true);
    }
    if (register) {
      setRegister(false);
    }
    if (user) {
      if(spaceId){
        navigate(URL_GETSPACE(spaceId));
      }else{
        navigate(URL_DASHBOARD);
      }
    }

  }, [user, navigate]);

  const handleClick = (option) => {
    setBusinessAccount(option);
    setBusinessError(false);
  };

  const handleClose = () => {
    disableAlert();
  };

  const limpiarForm = () => {
    setEmail("");
    setPassword("");
    setBusinessName("");
    setUsername("");
  };

  const toogleRegister = () => {
    setEmailError(false);
    setPasswordError(false);
    setNameError(false);
    limpiarForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let lowercaseEmail = email.toLowerCase();

    if (isRegister) {
      if (!email || !formatoemail.test(email)) {
        setEmailError(true);
      }
      if (!password) {
        setPasswordError(true);
      }
      if (!password || !email || !formatoemail.test(email)) {
        return;
      }
      const currentUser = { email: lowercaseEmail, password };
      setUpUser(currentUser, ENDPOINT_LOGIN, "Te has logeado correctamente");
    } else {
      if (!username) {
        setNameError(true);
      } else {
        setNameError(false);
      }
      if (businessAccount == null) {
        setBusinessError(true);
      } else {
        setBusinessError(false);
      }
      if (!businessName) {
        setBusinessNameError(true);
      } else {
        setBusinessNameError(false);
      }
      if (!password || !isPasswordValid || !isCheckPasswordValid) {
        setPasswordError(true);
      } else {
        setPasswordError(false);

        if (password !== checkPassword) {
          setCheckPasswordError(true);
        } else {
          setCheckPasswordError(false);
        }
      }
      if (!email || !formatoemail.test(email)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
      if (!captchaToken) {
        setCaptchaError(true);
      } else {
        setCaptchaError(false);
      }
      if (
        !username ||
        businessAccount == null ||
        !businessName ||
        !password ||
        !email ||
        !captchaToken ||
        !formatoemail.test(email) ||
        !isPasswordValid ||
        password !== checkPassword
      ) {
        return;
      } else {
        const currentUser = {
          email: lowercaseEmail,
          username,
          businessName,
          password,
          businessAccount,
        };
        setUpUser(
          currentUser,
          ENDPOINT_REGISTER,
          "Te has registrado correctamente"
        );
      }
    }
  };
  const formatoemail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isPasswordValid = password.length >= 8 && /\d/.test(password);
  const isCheckPasswordValid =
    checkPassword.length >= 8 && /\d/.test(checkPassword);

  if (isRegister) {
    return (
      <>
        <Helmet>
          {/* Título básico */}
          <title>Iniciar sesión en Spacease</title>

          {/* Caracteres y idioma */}
          <meta charSet="utf-8" />
          <html lang="es" />

          {/* Descripción de la página */}
          <meta
            name="description"
            content="Accede a la comunidad más grande de españa que conecta marcas y propietarios de espacios comerciales. Inicia sesión con tu dirección de correo electrónico."
          />

          {/* Mobile Meta */}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          {/* Open Graph (para Facebook y LinkedIn) */}
          <meta property="og:title" content="Iniciar sesión en Spacease" />
          <meta
            property="og:description"
            content="Accede a la comunidad más grande de españa que conecta marcas y propietarios de espacios comerciales. Inicia sesión con tu dirección de correo electrónico."
          />
          <meta
            property="og:image"
            content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Spacease" />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@Spacease_es" />
          <meta name="twitter:title" content="Iniciar sesión en Spacease" />
          <meta
            name="twitter:description"
            content="Accede a la comunidad más grande de españa que conecta marcas y propietarios de espacios comerciales. Inicia sesión con tu dirección de correo electrónico."
          />
          <meta
            name="twitter:image"
            content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg"
          />

          {/* Favicon */}
          <link
            rel="icon"
            type="image/png"
            href="https://www.spacease.com/favicon.ico"
          />

          {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
          <link
            rel="canonical"
            href={`https://www.spacease.com${location.pathname}`}
          />
        </Helmet>
        <div className="container__main">
          <Snackbar
            onClose={handleClose}
            open={showAlert}
            autoHideDuration={timeHideAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              variant="filled"
              severity={alertType ? alertType : ""}
            >
              <strong>{alertText ? alertText : ""} </strong>
            </Alert>
          </Snackbar>

          <Form onSubmit={handleSubmit} className="form__login">
            <h2>Inicia sesión en Spacease</h2>
            <Form.Group className="form__login-inputs">
              <Form.Control
                type="email"
                placeholder="Introduce tu correo electrónico"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="text-truncate"
              />
              {emailError && !formatoemail.test(email) && (
                <div className="error-message">
                  Por favor, introduce un email válido.
                </div>
              )}
              <Form.Control
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordError && !isPasswordValid && (
                <div className="error-message">
                  Por favor, introduce una contraseña.
                </div>
              )}
              <Link to="/reset-pwd/request">Resetear contraseña</Link>
            </Form.Group>
            <div className="justify-content-center d-flex">
              <Button
                className="button-blue"
                variant="primary"
                type="submit"
                onClick={() => {
                  mixpanel.identify(email); 
                  mixpanel.track("Login", {
                    Acción: "User Logged In",
                  });
                  // Identifica al usuario con el ID único generado en el registro
                }}
              >
                Iniciar sesión
              </Button>
            </div>
            <div className="form__login-isMember">
              <p>¿Aún no estás registrado?</p>
              <Link
                to={URL_REGISTER}
                className="text-blue ms-1"
                onClick={toogleRegister}
              >
                Regístrate
              </Link>
            </div>
          </Form>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Helmet>
          {/* Título básico */}
          <title>Regístrate en Spacease</title>

          {/* Caracteres y idioma */}
          <meta charSet="utf-8" />
          <html lang="es" />

          {/* Descripción de la página */}
          <meta
            name="description"
            content="Únete a la comunidad más grande de españa que conecta marcas y propietarios de espacios comerciales. Regístrate con tu dirección de correo electrónico."
          />

          {/* Mobile Meta */}
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          {/* Open Graph (para Facebook y LinkedIn) */}
          <meta property="og:title" content="Regístrate en Spacease" />
          <meta
            property="og:description"
            content="Únete a la comunidad más grande de españa que conecta marcas y propietarios de espacios comerciales. Regístrate con tu dirección de correo electrónico."
          />
          <meta
            property="og:image"
            content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Spacease" />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content="@Spacease_es" />
          <meta name="twitter:title" content="Regístrate en Spacease" />
          <meta
            name="twitter:description"
            content="Únete a la comunidad más grande de españa que conecta marcas y propietarios de espacios comerciales. Regístrate con tu dirección de correo electrónico."
          />
          <meta
            name="twitter:image"
            content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg"
          />

          {/* Favicon */}
          <link
            rel="icon"
            type="image/png"
            href="https://www.spacease.com/favicon.ico"
          />

          {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
          <link
            rel="canonical"
            href={`https://www.spacease.com${location.pathname}`}
          />
        </Helmet>
        <div className="container__main">
          <Snackbar
            onClose={handleClose}
            open={showAlert}
            autoHideDuration={timeHideAlert}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              variant="filled"
              severity={alertType ? alertType : ""}
            >
              <strong>{alertText ? alertText : ""} </strong>
            </Alert>
          </Snackbar>
          <Container className="container__rowForm">
            <Container className="container__text">
              <h2 className="text-blue">
                Los mejores espacios de retail en un solo lugar
              </h2>
              <h3>
                Sube tu espacio o contacta con dueños de espacios para contarles
                tu idea.{" "}
              </h3>
            </Container>
            <Container className="container__form">
              <Form onSubmit={handleSubmit} className="form">
                <h1>Regístrate en Spacease</h1>
                <Form.Group controlId="formbasic" className="form__inputs">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    className="text-truncate"
                    onChange={(e) => setEmail(e.target.value)}
                    onInvalid={(e) =>
                      e.target.setCustomValidity(
                        "Introduce un correo electrónico válido"
                      )
                    }
                    onInput={(e) => e.target.setCustomValidity("")}
                  />
                  {emailError && !formatoemail.test(email) && (
                    <div className="error-message">
                      Por favor, introduce un email válido.
                    </div>
                  )}
                  <Form.Control
                    type="text"
                    className="text-truncate"
                    placeholder="Nombre de tu empresa"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                  {businessNameError && !businessName && (
                    <div className="error-message">
                      Por favor, introduce un nombre de empresa.
                    </div>
                  )}
                  <Form.Control
                    type="text"
                    placeholder="Tu nombre"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  {nameError && !username && (
                    <div className="error-message">
                      Por favor, introduce un nombre de usuario.
                    </div>
                  )}
                  <Form.Control
                    type="password"
                    placeholder="Contraseña (8 caracteres y al menos un número por favor)"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && !isPasswordValid && (
                    <div className="error-message">
                      Por favor, introduce una contraseña con más de 8
                      caracteres y al menos un número.
                    </div>
                  )}
                  {!passwordError && checkPasswordError && (
                    <div className="error-message">
                      Las contraseñas no coinciden.
                    </div>
                  )}
                  <Form.Control
                    type="password"
                    placeholder="Confirmar contraseña"
                    value={checkPassword}
                    onChange={(e) => setCheckPassword(e.target.value)}
                  />
                  {passwordError && !isCheckPasswordValid && (
                    <div className="error-message">
                      Por favor, introduce una contraseña con más de 8
                      caracteres y al menos un número.
                    </div>
                  )}
                  {!passwordError && checkPasswordError && (
                    <div className="error-message">
                      Las contraseñas no coinciden.
                    </div>
                  )}
                </Form.Group>

                <Form.Group className="form__objectives">
                  <p>Mi objetivo es...</p>
                  <Container className="form__objectives-container-buttons">
                    <button
                      type="button"
                      className={businessAccount === false ? "selected" : ""}
                      onClick={() => handleClick(false)}
                    >
                      Alquilar espacios
                    </button>
                    <button
                      type="button"
                      className={businessAccount === true ? "selected" : ""}
                      onClick={() => handleClick(true)}
                    >
                      Subir espacios
                    </button>
                  </Container>
                  {businessError && (
                    <div className="error-message">
                      Por favor, selecciona una opción.
                    </div>
                  )}
                  <div className="captcha-container">
                    <ReCAPTCHA
                      sitekey="6LdGb9IlAAAAABf6T4yPbvHNbc9QuKI0sWMsQrbo"
                      onChange={(token) => {
                        setCaptchaToken(token);
                        setCaptchaError(false);
                      }}
                    />
                    {captchaError && (
                      <div className="error-message">
                        Por favor, completa el CAPTCHA.
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Container className="form__container-policy">
                  <Form.Group className="form__policy">
                    <FormCheck
                      className="selected"
                      checked={acceptedTerms}
                      onChange={(e) => setAcceptedTerms(e.target.checked)}
                    />
                    <p>
                      Acepto la <a href={URL_TERMS_OF_USE} target="_blank">Política de Privacidad y los Términos de Uso</a>
                    </p>
                    {/* <p>
                      Acepto los <a href={URL_TERMS_OF_USE}>Términos de Uso</a>
                    </p> */}
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    className={`form__button-submit button-blue ${
                      !acceptedTerms
                        ? "form__button-submit button-disabled"
                        : ""
                    }`}
                    disabled={!acceptedTerms}
                    onClick={() => {
                      if (acceptedTerms) {
                        mixpanel.track("Registration Ends", {
                          Acción: "Registration Ends",
                        });
                        mixpanel.identify(email);
                        mixpanel.people.set({
                          $email: email,
                          $name: username,
                        });
                      }
                    }}
                  >
                    Regístrame
                  </Button>
                </Container>

                <div className="form__isMember">
                  <p>¿Ya estas registrado?</p>
                  <Link
                    className="text-blue ms-1"
                    to={URL_LOGIN}
                    onClick={toogleRegister}
                  >
                    Inicia sesión
                  </Link>
                </div>
              </Form>
            </Container>
          </Container>
        </div>
      </>
    );
  }
};

export default Register;
