import axios from "axios";
import React, { useCallback, useContext, useEffect, useReducer } from "react";
import reducer from "./reducer";

import {
  CREATE_SPACE_BEGGIN,
  CREATE_SPACE_ERROR,
  CREATE_SPACE_SUCCESS,
  DISABLE_ALERT,
  DISPLAY_ALERT,
  GET_CURRENT_USER_BEGIN,
  GET_CURRENT_USER_ERROR,
  GET_CURRENT_USER_SUCCESS,
  GET_DASHBOARD_BEGIN,
  GET_DASHBOARD_ERROR,
  GET_DASHBOARD_SUCCESS,
  GET_MAP_OPTIONS_BEGGIN,
  GET_MAP_OPTIONS_ERROR,
  GET_MAP_OPTIONS_SUCCESS,
  GET_SPACES_BY_ID_BEGGIN,
  GET_SPACES_BY_ID_ERROR,
  GET_SPACES_BY_ID_SUCCESS,
  GET_SPACES_USER_BEGGIN,
  GET_SPACES_USER_ERROR,
  GET_SPACES_USER_SUCCESS,
  GET_SPACE_ONLY_BY_USER_BEGGIN,
  GET_SPACE_ONLY_BY_USER_ERROR,
  GET_SPACE_ONLY_BY_USER_SUCCESS,
  LOGOUT_USER_BEGGIN,
  LOGOUT_USER_ERROR,
  LOGOUT_USER_SUCCESS,
  RESET_VALUE,
  SETUP_USER_BEGIN,
  SETUP_USER_ERROR,
  SETUP_USER_SUCCESS,
  SET_SELECTED_CATEGORY,
  SET_SELECTED_CITY,
  UPDATE_PROFILEPIC_BEGIN,
  UPDATE_PROFILEPIC_ERROR,
  UPDATE_PROFILEPIC_SUCCESS,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
} from "./actions";
import {
  ENDPOINT_GET_CURRENT_USER,
  ENDPOINT_GET_SPACES_BY_USER,
  ENDPOINT_GET_SPACE_ONLY_BY_USER,
  ENDPOINT_LOGOUT,
  ENDPOINT_MAP_OPTIONS,
  ENDPOINT_SPACES,
  ENDPOINT_UPDATE_PASSWORD,
  ENDPOINT_UPDATE_PROFILE_PIC,
  ENDPOINT_UPDATE_USER,
} from "./endpoints.js";

const initialState = {
  user: null,
  spaces: [],
  successPost: null,
  spaceById: [],
  numOfPages: 0,
  totalSpaces: 0,
  isLoading: false,
  selectedCity: "",
  selectedCategory: "Todos",
  mapOptions: [],
  showAlert: false,
  alertText: "",
  alertType: "",
  userLoading: true,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const authFetch = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/`,
  });

  axios.defaults.withCredentials = true;

  const setUpUser = async (currentUser, endPoint, alertText) => {
    dispatch({ type: SETUP_USER_BEGIN });
    try {
      const response = await authFetch.post(endPoint, currentUser);
      const { user } = response.data;
      dispatch({
        type: SETUP_USER_SUCCESS,
        payload: { user, alertText },
      });
    } catch (error) {
      dispatch({
        type: SETUP_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const updateUser = async (updatedUser, alertText) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const response = await authFetch.patch(ENDPOINT_UPDATE_USER, updatedUser);
      const { user } = response.data;
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, alertText },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const updatePassword = async (updatedPassword, alertText) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    try {
      const response = await authFetch.patch(
        ENDPOINT_UPDATE_PASSWORD,
        updatedPassword
      );
      const { user } = response.data;
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: { user, alertText },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const updateProfilePic = async (image, alertText) => {
    dispatch({ type: UPDATE_PROFILEPIC_BEGIN });
    const formData = new FormData();
    formData.append("profileImage", image);
    try {
      const response = await authFetch.patch(
        ENDPOINT_UPDATE_PROFILE_PIC,
        formData
      );
      const { user } = response.data;
      dispatch({
        type: UPDATE_PROFILEPIC_SUCCESS,
        payload: { user, alertText },
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PROFILEPIC_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const getAllSpaces = async (pageNumber = 1) => {
    dispatch({ type: GET_DASHBOARD_BEGIN });
    try {
      let url = `${ENDPOINT_SPACES}?limit=10&page=${pageNumber}&filter=${state.selectedCategory}`;
      if (state.selectedCity !== "") {
        url += `&city=${state.selectedCity}`;
      }
      const response = await authFetch.get(url);
      const { spaces, totalSpaces, numOfPages } = response.data;
      dispatch({
        type: GET_DASHBOARD_SUCCESS,
        payload: { spaces, totalSpaces, numOfPages },
      });
      return spaces;
    } catch (error) {
      if (error.response) {
        dispatch({
          type: GET_DASHBOARD_ERROR,
          payload: { msg: error.response.data.msg },
        });
      } else {
        console.log(error);
      }
    }
  };

  const createSpace = async (space, imageFiles) => {  
    dispatch({ type: CREATE_SPACE_BEGGIN });
    const formData = new FormData();

    try {
      for (const key in space) {
        if (space.hasOwnProperty(key)) {
          if (Array.isArray(space[key])) {
            formData.append(key, space[key]); // Agrega el array directamente
          } else if (typeof space[key] === "object") {
            formData.append(key, JSON.stringify(space[key]));
          } else {
            formData.append(key, space[key]);
          }
        }
      }
      // Agregar las imágenes al FormData
      imageFiles.forEach((file, index) => {
        formData.append(`images`, file);
      });
      await authFetch.post(ENDPOINT_SPACES, formData);
      dispatch({ type: CREATE_SPACE_SUCCESS });
    } catch (error) {
      dispatch({
        type: CREATE_SPACE_ERROR,
        payload: { msg: error.response.data.msg },
      });
    } finally {
      resetValue("successPost", null, 3500);
    }
  };

  const getSpacesUser = async (filter) => {
    dispatch({ type: GET_SPACES_USER_BEGGIN });
    try {
      const response = await authFetch.get(
        `${ENDPOINT_GET_SPACES_BY_USER}?filter=${filter}`
      );
      const { spaces } = response.data;
      dispatch({
        type: GET_SPACES_USER_SUCCESS,
        payload: spaces,
      });
    } catch (error) {
      dispatch({
        type: GET_SPACES_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const getSpaceById = async (id) => {
    dispatch({ type: GET_SPACES_BY_ID_BEGGIN });
    try {
      const { data } = await authFetch.get(`${ENDPOINT_SPACES}${id}`, {
        withCredentials: true,
      });
      const { space } = data;
      dispatch({ type: GET_SPACES_BY_ID_SUCCESS, payload: space });
    } catch (error) {
      dispatch({
        type: GET_SPACES_BY_ID_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const getSpaceOnlyByUser = async (id) => {
    try {
      const { data } = await authFetch.get(
        `${ENDPOINT_GET_SPACE_ONLY_BY_USER}${id}`
      );
      const { space } = data;
      return space;
    } catch (error) {
      throw error;
    }
  };

  const updateSpace = async (space, imagesToRemove = [], imagesToAdd) => {
    const formData = new FormData();
    dispatch({type:GET_SPACE_ONLY_BY_USER_BEGGIN})
    try {
      if (space._id) {
        for (const key in space) {
          if (space.hasOwnProperty(key)) {
            if (Array.isArray(space[key])) {
              formData.append(key, space[key]); // Agrega el array directamente
            } else if (typeof space[key] === "object") {
              formData.append(key, JSON.stringify(space[key]));
            } else {
              formData.append(key, space[key]);
            }
          }
        }
        // Agregar las imágenes al FormData
        if (imagesToAdd) {
          imagesToAdd.forEach((file, index) => {
            formData.append(`images`, file);
          });
        }

        formData.append("imagesRemoved", imagesToRemove);
        await authFetch.patch(`${ENDPOINT_SPACES}${space._id}`, formData);
        dispatch({type:GET_SPACE_ONLY_BY_USER_SUCCESS,payload:{msg:"Actualizado Correctamente"}})
        return true;
      }
    } catch (error) {
      dispatch({type:GET_SPACE_ONLY_BY_USER_ERROR,payload:{msg: error.response.data.msg}})
      return false;

    }
  };

  const getMapOptions = async (params) => {
    dispatch({ type: GET_MAP_OPTIONS_BEGGIN });
    try {
      const { data } = await authFetch.post(ENDPOINT_MAP_OPTIONS, params);
      dispatch({ type: GET_MAP_OPTIONS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_MAP_OPTIONS_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const logoutUser = async () => {
    dispatch({ type: LOGOUT_USER_BEGGIN });
    try {
      const response = await authFetch.get(ENDPOINT_LOGOUT);
      const { msg } = response.data.msg;
      dispatch({
        type: LOGOUT_USER_SUCCESS,
        payload: msg,
      });
    } catch (error) {
      dispatch({
        type: LOGOUT_USER_ERROR,
        payload: { msg: error.response.data.msg },
      });
    }
  };

  const setSelectedCity = (city) => {
    dispatch({ type: SET_SELECTED_CITY, payload: city });
  };

  const setSelectedCategory = async (category) => {
    await dispatch({ type: SET_SELECTED_CATEGORY, payload: category });
  };

  const getCurrentUser = async () => {
    dispatch({ type: GET_CURRENT_USER_BEGIN });
    try {
      const { data } = await authFetch.get(ENDPOINT_GET_CURRENT_USER, {
        withCredentials: true,
      });
      const { user } = data;
      dispatch({ type: GET_CURRENT_USER_SUCCESS, payload: { user } });
    } catch (error) {
      dispatch({ type: GET_CURRENT_USER_ERROR });
      if (error.response.status === 401) return;
      logoutUser();
    }
  };

  const resetValue = useCallback((field, value, time) => {
    setTimeout(() => {
      dispatch({ type: RESET_VALUE, field: field, value: value });
    }, time);
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const disableAlert = () => {
    dispatch({ type: DISABLE_ALERT });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        updateUser,
        setUpUser,
        getAllSpaces,
        getSpacesUser,
        getSpaceById,
        getSpaceOnlyByUser,
        updateSpace,
        disableAlert,
        getCurrentUser,
        setSelectedCity,
        setSelectedCategory,
        logoutUser,
        updateProfilePic,
        getMapOptions,
        updatePassword,
        createSpace,
        authFetch
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
