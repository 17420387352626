export const DISPLAY_ALERT = 'SHOW_ALERT'
export const RESET_VALUE = 'RESET_VALUE'
export const DISABLE_ALERT = 'DISABLE_ALERT'
//example
export const SETUP_USER_ERROR = 'SETUP_USER_ERROR'
export const SETUP_USER_BEGIN = 'SETUP_USER_BEGIN'
export const SETUP_USER_SUCCESS = 'SETUP_USER_SUCCESS'

export const GET_DASHBOARD_ERROR= 'SETUP_DASHBOARD_ERROR'
export const GET_DASHBOARD_BEGIN = 'SETUP_DASHBOARD_BEGIN'
export const GET_DASHBOARD_SUCCESS = 'SETUP_DASHBOARD_SUCCESS'

export const GET_SPACES_USER_BEGGIN = 'GET_SPACES_USER_BEGGIN'
export const GET_SPACES_USER_SUCCESS = 'GET_SPACES_USER_SUCCESS'
export const GET_SPACES_USER_ERROR = 'GET_SPACES_USER_ERROR'

export const GET_CURRENT_USER_BEGIN = 'GET_CURRENT_USER_BEGIN'
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR'

export const LOGOUT_USER_BEGGIN = 'LOGOUT_USER_BEGGIN'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR'

export const GET_SPACES_BY_ID_BEGGIN = 'GET_SPACES_BY_ID_BEGGIN'
export const GET_SPACES_BY_ID_SUCCESS = 'GET_SPACES_BY_ID_SUCCESS'
export const GET_SPACES_BY_ID_ERROR= 'GET_SPACES_BY_ID_ERROR'

export const UPDATE_USER_BEGIN= 'UPDATE_USER_BEGIN'
export const UPDATE_USER_SUCCESS= 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR= 'UPDATE_USER_ERROR'

export const UPDATE_PROFILEPIC_BEGIN= 'UPDATE_PROFILEPIC_BEGIN'
export const UPDATE_PROFILEPIC_SUCCESS= 'UPDATE_PROFILEPIC_SUCCESS'
export const UPDATE_PROFILEPIC_ERROR= 'UPDATE_PROFILEPIC_ERROR'

export const UPDATE_PASSWORD_BEGIN = 'UPDATE_PASSWORD_BEGIN'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR'

export const SET_SELECTED_CITY = 'SET_SELECTED_CITY'
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY'

export const GET_MAP_OPTIONS_BEGGIN = 'GET_MAP_OPTIONS_BEGGIN'
export const GET_MAP_OPTIONS_SUCCESS = 'GET_MAP_OPTIONS_SUCCESS'
export const GET_MAP_OPTIONS_ERROR = 'GET_MAP_OPTIONS_BEGGIN'

export const CREATE_SPACE_BEGGIN = 'CREATE_SPACE_BEGGIN'
export const CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS'
export const CREATE_SPACE_ERROR = 'CREATE_SPACE_ERROR'

export const GET_SPACE_ONLY_BY_USER_BEGGIN = "GET_SPACE_ONLY_BY_USER_BEGGIN"
export const GET_SPACE_ONLY_BY_USER_SUCCESS = "GET_SPACE_ONLY_BY_USER_SUCCESS"
export const GET_SPACE_ONLY_BY_USER_ERROR= "GET_SPACE_ONLY_BY_USER_ERROR"