import React, { useCallback, useEffect, useRef } from "react";
import { Navbar, Button, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Logo from "./Logo";
import { useState } from "react";
import { useAppContext } from "../context/appContext";
import {
  URL_DASHBOARD,
  URL_LOGIN,
  URL_REGISTER,
  URL_PROFILE,
  URL_PANELCONTROL,
  URL_GETSPACE,
  URL_CONTACT,
  URL_RULES,
  URL_UPLOAD_SPACE,
  URL_LANDING_CREATE_SPACE, 
  URL_HOW_DOES_IT_WORK,
  URL_ABOUT_US,
  URL_LANDING
} from "../context/urlPath";
import { Autocomplete, TextField, Drawer, Box, List, useMediaQuery } from "@mui/material";
import "../css/miNavbar.css";
import Divider from "./Divider";
import { SVG } from "../helpers/Svgs.js";
import mixpanel from '../helpers/Mixpanel.js';

const MiNavbar = (props) => {
  const locations = [
    { pais: "España", ciudad: "Albacete" },
    { pais: "España", ciudad: "Madrid" },
    { pais: "España", ciudad: "Barcelona" },
    { pais: "España", ciudad: "Valencia" },
    { pais: "España", ciudad: "Sevilla" },
    { pais: "España", ciudad: "Bilbao" },
    { pais: "España", ciudad: "Málaga" },
    { pais: "España", ciudad: "Alicante" },
    { pais: "España", ciudad: "Zaragoza" },
    { pais: "España", ciudad: "Murcia" },
    { pais: "España", ciudad: "Palma de Mallorca" },
  ];

  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const cityQuery = params.get("city");

  const [city, setCity] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const { user, logoutUser, setSelectedCity } = useAppContext();
  const [urlSpace, setUrlSpace] = useState(null);
  const [pathName, setPathName] = useState("");

  const preValue = useRef("");

  let path;
  useEffect(() => {
    path = location.pathname;
    setUrlSpace(URL_GETSPACE(null, true));

    if (path.includes(urlSpace)) {
      setPathName(urlSpace);
    } else {
      setPathName(path);
    }
  }, [location.pathname]);

  useEffect(() => {
    setCity(
      locations.find((location) => location.ciudad === cityQuery) || null
    );
  }, [cityQuery]);

  const handleLogout = () => {

    logoutUser();
    mixpanel.reset();
    toogleDrawer();
  };
  const toogleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const handleRegisterClick = () => {
    mixpanel.track('Registration Starts', {
      'Acción': 'Registration Starts'
    });
  };
  const SearchByCity = useCallback((event) => {
    if (event.key === "Enter") {
      if (event.target.value.trim() !== "") {
        const normalizedInputCity = normalizeInput(event.target.value);
        if (preValue.current === normalizedInputCity) {
          return; // No hacer nada si el input es el mismo que el anterior
        }

        preValue.current = normalizedInputCity; // Actualizar el valor del input almacenado

        const findLocation = locations.find((location) => {
          const normalizedLocationCity = normalizeInput(location.ciudad);
          return normalizedLocationCity === normalizedInputCity;
        });

        if (findLocation) {
          setCity(findLocation);
          setSelectedCity(findLocation.ciudad);
          setInputValue(findLocation.ciudad);
        }
      } else {
        setCity(null);
        setSelectedCity(null);
        setInputValue("");
      }
    }
  }, []);

  const normalizeInput = (value) => {
    return removeAccents(value.trim().toLowerCase());
  };
  function removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  const handleLogoClick = (e) => {
    // Enviar evento a Mixpanel
    mixpanel.track('Logo Clicked', {
        'element': 'logo',
        'currentPath': pathName
    });
  
    if (pathName === URL_DASHBOARD) {
      window.location.reload();
    }
  };

  const smallThan1000px = useMediaQuery('(max-width:1000px)');
  const smallThan820px = useMediaQuery('(max-width:820px)');

  return (
    <Navbar expand="lg" className={pathName === urlSpace ? "" : "sticky-top"}>
      <div className="main__container">
        {pathName !== URL_REGISTER || pathName !== URL_LOGIN ? (
          <>
              <div className="navbar__container">
                <div className="d-flex align-items-center w-100">
                <Link style={{textDecoration:"none"}} to={URL_DASHBOARD} onClick={handleLogoClick}>
                  {smallThan1000px ?
                  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.7081 24C15.1349 24 12.7152 22.9658 10.8975 21.087L13.0252 18.8913C14.2742 20.1832 15.9385 20.8944 17.7081 20.8944C19.4777 20.8944 21.1389 20.1832 22.3909 18.8944C23.6428 17.6025 24.332 15.8851 24.332 14.059C24.332 12.2329 23.6428 10.5186 22.3939 9.22671L17.7081 4.3913L13.0222 9.22671C8.50492 13.8882 4.12305 14.0994 0 9.84472L2.12774 7.64907C4.57749 10.177 7.00618 11.0404 10.8915 7.03106L17.705 0L24.5186 7.03106C26.3364 8.90683 27.3385 11.4037 27.3385 14.059C27.3385 16.7143 26.3364 19.2081 24.5186 21.087C22.6978 22.9658 20.2782 24 17.705 24H17.7081Z" fill="#034CF3"/>
                  </svg>
                    :
                    <Logo typeLogo="Logo" />
                }
                </Link>
                {(pathName === URL_DASHBOARD || pathName === urlSpace) && (
                  <div className="input__container">
                    {SVG.LUPA}
                    <Autocomplete
                     fullWidth
                     
                      sx={{
                        width:"100%",
                        flex:"1",
                        ".MuiOutlinedInput-notchedOutline":{
                          border:"none !important"
                        },
                        ".MuiAutocomplete-input":{
                          color:"#717171",
                          fontFamily:"inherit",
                          fontWeight:"500",
                          "::placeholder":{
                            font:"inherit"
                          }
                        },
                        ".MuiAutocomplete-inputRoot .MuiAutocomplete-input":{
                          width:"100%"
                        }
                      }}  
                      isOptionEqualToValue={(option, value) => {
                        return option.ciudad === value.ciudad;
                      }}
                      value={city}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          const normalizednewValue = normalizeInput(
                            newValue.ciudad
                          );
                          if (normalizednewValue !== preValue.current) {
                            setCity(newValue);
                            preValue.current = normalizednewValue;
                            setSelectedCity(newValue.ciudad);
                          }
                        } else {
                          setCity(null);
                          setSelectedCity("");
                        }
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      selectOnFocus={true}
                      options={locations}
                      groupBy={(options) => options.pais}
                      getOptionLabel={(option) => option.ciudad}
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={SearchByCity}
                          style={{ 
                            maxHeight: "100%",
                            border: "none",
                          }}
                          {...params}
                          placeholder="Buscar por ciudad"
                        />
                      )}
                      popupIcon={<></>}
                      
                    />
                  </div>
                )}
              </div>

                {pathName === URL_PANELCONTROL && (
                  <div>
                    <p className="panel__control-text">Panel de control</p>
                  </div>
                )}
                {pathName === URL_PROFILE && (
                  <div>
                    <p className="panel__control-text">Ajustes de tu cuenta</p>
                  </div>
                )}
                <div className="container__buttons">
                  {pathName === URL_PANELCONTROL && (
                    <Link to={URL_PROFILE}>
                      <Button className="upload-space">
                        <>
                          {SVG.ENGRANAJE}
                          Ajustes de cuenta
                        </>
                      </Button>
                    </Link>
                  )}
                  {pathName === URL_PROFILE && (
                    <Link to={URL_PANELCONTROL}>
                      <Button className="upload-space">
                        <>
                          {SVG.CUADRADO_CUADRADOS_ESQUINAS}
                          Panel de Control
                        </>
                      </Button>
                    </Link>
                  )}
                  {(pathName === URL_DASHBOARD || pathName === urlSpace) && (
                    <Link id="upload-space" to={user ? URL_LANDING_CREATE_SPACE : URL_LOGIN}>
                      <Button className="upload-space">
                        <>
                          {SVG.CASA}
                          Sube tu espacio
                        </>
                      </Button>
                    </Link>
                  )}
                  {(pathName === URL_RULES || pathName == URL_LANDING_CREATE_SPACE) && user ? (
                    <Link id="register_navbar" to="/upload-space">
                      <Button className="register-button button-blue">Empezar</Button>
                    </Link>
                  ) : !user && pathName !== URL_REGISTER && pathName !== URL_LOGIN ? (
                    <Link id="register_navbar" to={URL_REGISTER} key="registrarse">
                      <Button className="register-button button-blue" onClick={handleRegisterClick}>Registrarse</Button>
                    </Link>
                  ) : null}



                  <Button className="drawer-button" onClick={toogleDrawer}>
                    {SVG.TRES_LINEAS_CENTRO}
                  </Button>
                  <Drawer
                    anchor="right"
                    open={showDrawer}
                    onClose={toogleDrawer}
                  >
                    <Box className="d-flex flex-column w-100 gap_2-5rem">
                      <div
                        onClick={toogleDrawer}
                        style={{ cursor: "pointer", width: "fit-content" }}
                      >
                        {SVG.FLECHA_DERECHA}
                      </div>

                      {user ? (
                        <List>
                          <p
                            style={{ marginBottom: "2.5rem" }}
                            className="text-black fs_1-25rem fw_600"
                          >
                            Hola, {user?.name}
                          </p>
                          <div className="d-flex flex-column gap_1-25rem">
                            <Link
                              className="d-flex align-items-center gap_0-62rem text-black fs_0-875rem fw_500"
                              to={URL_PANELCONTROL}
                              onClick={toogleDrawer}
                            >
                              {SVG.CUADRADO_CUADRADOS_ESQUINAS}
                              Panel de control
                            </Link>
                            <Link
                              className="d-flex align-items-center gap_0-62rem text-black fs_0-875rem fw_500"
                              to={URL_PROFILE}
                              onClick={toogleDrawer}
                            >
                              {SVG.ENGRANAJE}
                              Ajustes de cuenta
                            </Link>
                            <Link
                              to={path}
                              onClick={handleLogout}
                              className="d-flex align-items-center gap_0-62rem text-black fs_0-875rem fw_500"
                            >
                              {SVG.SALIR_FLECHA_FUERA}
                              Cerrar sesión
                            </Link>
                          </div>
                        </List>
                      ) : (
                        //SE EJECUTA ESTO CUANDO NO HAY USER
                        <List className="d-flex flex-column gap_1-25rem">
                          <Link to={URL_LOGIN}>
                            <Button
                              className="fs_0-875rem fw_500 simple_white_button p_simple_white_button"
                              onClick={toogleDrawer}
                            >
                              Inicia sesión
                            </Button>
                          </Link>
                          <Link to={URL_REGISTER}>
                            <Button
                              className="fs_0-875rem fw_500 simple_blue_button"
                              onClick={toogleDrawer}
                            >
                              Registrarse
                            </Button>
                          </Link>
                        </List>
                      )}
                      <Divider />
                      <List className="w-100 d-flex flex-column gap_1-25rem">
                        <Link
                          to={URL_LANDING_CREATE_SPACE}
                          onClick={toogleDrawer}
                          className="text-gray fs_0-875rem fw_500"
                        >
                          Subir un espacio
                        </Link>
                        <Link
                          to={URL_LANDING}
                          onClick={toogleDrawer}
                          className="text-gray fs_0-875rem fw_500"
                        >
                          ¿Cómo funciona?
                        </Link>
                        <Link
                          to={URL_ABOUT_US}
                          onClick={toogleDrawer}
                          className="text-gray fs_0-875rem fw_500"
                        >
                          Sobre Spacease
                        </Link>
                        <Link to={URL_CONTACT}
                          onClick={toogleDrawer}
                          className="text-gray fs_0-875rem fw_500"
                        >
                          Atención al cliente
                        </Link>
                      </List>
                    </Box>
                  </Drawer>
                </div>
              </div>
          </>
        ) : null}
      </div>
    </Navbar>
  );
};

export default MiNavbar;
