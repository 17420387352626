import { useState, useEffect } from "react";
import { getStateInfo, onlyNumbersAllowed } from "../context/modelHelper";
import {
  StatusValues,
  MinMaxLenghtValues,
  errorsInitialState,
  FieldNames,
} from "../helpers/Consts";
import { validateEmail, validatePhoneNumber } from "../helpers/Regex";
import { useAppContext } from "../context/appContext";
import mixpanel from '../helpers/Mixpanel.js';

function useSpaceValidation(state) {
  const [newSpace, setNewSpace] = useState(state);
  const [errors, setErrors] = useState(errorsInitialState);
  const [defaultSpace, setDefaultSpace] = useState();
  const {
    mapOptions,
    isLoading,
    getMapOptions,
    successPost,
    alertText,
    getSpaceOnlyByUser,
  } = useAppContext();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedIdMapOption, setSelectedIdMapOption] = useState(null);
  const [selectedMapOption, setSelectedMapOption] = useState(null);
  const [isOpenValidateLocation, setIsOpenValidateLocation] = useState(false);
  const [temporalCoordinates, setTemporalCoordinates] = useState([0, 0]);

  const handleAddressValidated = (mapOption) => {
    const coordinatesToChange = [
      parseFloat(mapOption.lat),
      parseFloat(mapOption.lon),
    ];

    setNewSpace((prevNewSpace) => {
      const newSpaceUpdates = { ...prevNewSpace };

      if (mapOption.address) {
        if (mapOption.address.road) {
          newSpaceUpdates.street = mapOption.address.road;
        }
        if (mapOption.address.postcode) {
          newSpaceUpdates.zipCode = mapOption.address.postcode;
        }
        if (mapOption.address.city) {
          newSpaceUpdates.city = mapOption.address.city;
        }
        if (mapOption.address.state) {
          newSpaceUpdates.province = mapOption.address.state;
        }
      }

      newSpaceUpdates.coordinates = coordinatesToChange;

      return newSpaceUpdates;
    });
  };

  const handleAtrributesCheckboxChange = (index) => {
    const newAttributes = [...newSpace.attributes]; // Copiar el array existente
    if (newAttributes[index] === 1) {
      newAttributes[index] = 0;
    } else {
      newAttributes[index] = 1;
      setErrors((prevErrors) => ({
        ...prevErrors,
        attributes: { ...prevErrors.attributes, hasError: false },
      }));
    }

    setNewSpace((prevNewSpace) => ({
      ...prevNewSpace,
      attributes: newAttributes,
    }));
  };

  const handleSpaceTypesCheckboxChange = (index) => {
    const newSpaceTypes = [...newSpace.spaceType]; // Copiar el array existente
    if (newSpaceTypes[index] === 1) {
      newSpaceTypes[index] = 0;
    } else {
      newSpaceTypes[index] = 1;
      setErrors((prevErrors) => ({
        ...prevErrors,
        spaceType: { ...prevErrors.spaceType, hasError: false },
      }));
    }

    setNewSpace((prevNewSpace) => ({
      ...prevNewSpace,
      spaceType: newSpaceTypes,
    }));
  };

  const handleNext = (countImages = 0) => {
    if (validateNextStep(countImages)) {
      setActiveStep((prevActiveStep) => {
        if (prevActiveStep <= 11) {
          const newStep = prevActiveStep + 1;
          mixpanel.track("Create space process", {
            Paso: newStep,
          });
          return newStep;
        }
        return prevActiveStep;
      });
    }
  };

  const reInicializeNewSpace = (space) => {
    setNewSpace(space);
    setDefaultSpace(space);
  };

  const saveActiveStep = (value) => {
    setActiveStep(value);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep > 0) {
        return prevActiveStep - 1;
      }
      return prevActiveStep;
    });
  };

  const setValueNewSpace = (e) => {
    const fieldId = e.target.id;
    let fieldValue = e.target.value;
    let fieldIsEmpty = fieldValue.trim() === "";
    let assignError = false;
    let canSetValue = false;

    switch (fieldId) {
      case FieldNames.name: {
        if (fieldValue.length <= MinMaxLenghtValues.name.max) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.description: {
        if (fieldValue.length >= MinMaxLenghtValues.description.min) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldId]: { ...prevErrors[fieldId], message: "" },
          }));
        }
        if (fieldValue.length <= MinMaxLenghtValues.description.max) {
          canSetValue = true;
        }

        break;
      }
      case FieldNames.email: {
        if (newSpace.email.trim() === "") {
          assignError = true;
        }
        if (fieldValue.length <= MinMaxLenghtValues.email.max) {
          canSetValue = true;
        }

        break;
      }

      case FieldNames.phoneNumber: {
        //COMPROBAR QUE SEA EXACTAMENTE IGUAL A 9 O NO ???
        fieldValue = onlyNumbersAllowed(fieldValue);
        if (fieldValue.length <= MinMaxLenghtValues.phoneNumber.max) {
          canSetValue = true;
        }
        break;
      }

      case FieldNames.street: {
        if (fieldValue.trim() === "") {
          assignError = true;
        }
        if (fieldValue.length <= MinMaxLenghtValues.street.max) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.numberStreet: {
        if (fieldValue.length <= MinMaxLenghtValues.numberStreet.max) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.extraInfoStreet: {
        if (fieldValue.length <= MinMaxLenghtValues.extraInfoStreet.max) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.zipCode: {
        if (fieldValue.length <= MinMaxLenghtValues.zipCode.max) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.city: {
        if (fieldValue.length <= MinMaxLenghtValues.city.max) {
          canSetValue = true;
        }
        break;
      }

      case FieldNames.neighbourDescription: {
        if (fieldValue.length <= MinMaxLenghtValues.neighbourDescription.max) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.spaceSize:
      case FieldNames.avgVisitors: {
        fieldValue = onlyNumbersAllowed(fieldValue);
        if (fieldValue.length <= 9) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.maxDays:
      case FieldNames.minDays: {
        fieldValue = onlyNumbersAllowed(fieldValue);
        if (fieldValue.length <= 3) {
          canSetValue = true;
        }
        break;
      }
      case FieldNames.monthlyPrice:
      case FieldNames.weeklyPrice:
      case FieldNames.dailyPrice: {
        fieldValue = onlyNumbersAllowed(fieldValue);
        if (fieldValue.length <= 6) {
          canSetValue = true;
          setErrors((prevErrors) => ({
            ...prevErrors,
            dailyPrice: {
              ...prevErrors.dailyPrice,
              hasError: false,
              shake: false,
            },
            weeklyPrice: {
              ...prevErrors.weeklyPrice,
              hasError: false,
              shake: false,
            },
            monthlyPrice: {
              ...prevErrors.monthlyPrice,
              hasError: false,
              shake: false,
            },
          }));
        }
        break;
      }
      default: {
        break;
      }
    }

    if (canSetValue) {
      setNewSpace((prevNewSpace) => ({
        ...prevNewSpace,
        [fieldId]: fieldValue,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldId]: {
        ...prevErrors[fieldId],
        hasError: fieldIsEmpty || assignError,
        shake: fieldIsEmpty || assignError,
      },
    }));

    resetShakeAnimation(fieldId);
  };

  const handleVisibility = (option) => {
    if (option !== null) {
      setNewSpace((prevNewSpace) => ({
        ...prevNewSpace,
        visibility: option,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        visibility: { ...prevErrors.visibility, hasError: false, shake: false },
      }));
    }
  };
  const handleStatus = (option) => {
    if (option !== null) {
      setNewSpace((prevNewSpace) => ({
        ...prevNewSpace,
        state: option,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        state: { ...prevErrors.state, hasError: false, shake: false },
      }));
    }
  };

  const validateNextStep = (countImages = 0) => {
    let isValid = true;
    switch (activeStep) {
      case 0: {
        if (newSpace.name.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            name: { ...prevErrors.name, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.name);
        }
        if (newSpace.description.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            description: {
              ...prevErrors.description,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.description);
        }
        if (newSpace.description.length < 100) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            description: {
              ...prevErrors.description,
              hasError: true,
              message: `Minimo ${MinMaxLenghtValues.description.min} caracteres`,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.description);
        }
        if (!newSpace.spaceType.some((value) => value === 1)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            spaceType: { ...prevErrors.spaceType, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.spaceType);
        }

        if (isValid) {
          //Resetear el message de error
          setErrors((prevErrors) => ({
            ...prevErrors,
            description: {
              ...prevErrors.description,
              hasError: false,
              message: "",
            },
          }));
        }
        break;
      }
      case 1: {
        if (
          newSpace.phoneNumber.trim() === "" ||
          !validatePhoneNumber.test(newSpace.phoneNumber)
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            phoneNumber: {
              ...prevErrors.phoneNumber,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.phoneNumber);
        }
        if (
          newSpace.email.trim() === "" ||
          !validateEmail.test(newSpace.email)
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            email: { ...prevErrors.email, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.email);
        }
        break;
      }
      case 2: {
        if (newSpace.street.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            street: { ...prevErrors.street, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.street);
        }
        if (newSpace.numberStreet.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            numberStreet: {
              ...prevErrors.numberStreet,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.numberStreet);
        }
        if (newSpace.zipCode.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            zipCode: { ...prevErrors.zipCode, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.zipCode);
        }
        if (newSpace.city.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            city: { ...prevErrors.city, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.city);
        }

        if (!selectedIdMapOption) {
          isValid = false;
          setErrors((prevErrors) => ({
            ...prevErrors,
            buttonMaps: {
              ...prevErrors.buttonMaps,
              hasError: true,
              shake: true,
            },
          }));
          resetShakeAnimation(FieldNames.buttonMaps);
        }
        break;
      }
      case 3: {
        if (newSpace.visibility === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            visibility: {
              ...prevErrors.visibility,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.visibility);
        }

        break;
      }
      case 4: {
        if (!newSpace.attributes.some((value) => value === 1)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            attributes: {
              ...prevErrors.attributes,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.attributes);
        }
        break;
      }
      case 5: {
        if (newSpace.neighbourDescription.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            neighbourDescription: {
              ...prevErrors.neighbourDescription,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.neighbourDescription);
        }

        if (newSpace.neighbourDescription.length < 100) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            neighbourDescription: {
              ...prevErrors.neighbourDescription,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.neighbourDescription);
        }
        break;
      }
      case 6: {
        if (
          typeof newSpace.avgVisitors === "number" ||
          newSpace.avgVisitors.trim() === ""
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            avgVisitors: {
              ...prevErrors.avgVisitors,
              hasError: true,
              shake: true,
            },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.avgVisitors);
        }
        if (newSpace.spaceSize.trim() === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            spaceSize: { ...prevErrors.spaceSize, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.spaceSize);
        }

        break;
      }
      case 7: {
        if (newSpace.state === "") {
          setErrors((prevErrors) => ({
            ...prevErrors,
            state: { ...prevErrors.state, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.state);
        }
        break;
      }
      case 8: {
        if (
          newSpace.dailyPrice.trim() === "" &&
          newSpace.weeklyPrice.trim() === "" &&
          newSpace.monthlyPrice.trim() === ""
        ) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dailyPrice: {
              ...prevErrors.dailyPrice,
              hasError: true,
              shake: true,
            },
            weeklyPrice: {
              ...prevErrors.weeklyPrice,
              hasError: true,
              shake: true,
            },
            monthlyPrice: {
              ...prevErrors.monthlyPrice,
              hasError: true,
              shake: true,
            },
          }));
          resetShakeAnimation(FieldNames.dailyPrice);
          resetShakeAnimation(FieldNames.weeklyPrice);
          resetShakeAnimation(FieldNames.monthlyPrice);
          isValid = false;
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            dailyPrice: {
              ...prevErrors.dailyPrice,
              hasError: false,
              shake: false,
            },
            weeklyPrice: {
              ...prevErrors.weeklyPrice,
              hasError: false,
              shake: false,
            },
            monthlyPrice: {
              ...prevErrors.monthlyPrice,
              hasError: false,
              shake: false,
            },
          }));
        }
        break;
      }
      case 9: {
        if (countImages < MinMaxLenghtValues.images.min) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            images: { ...prevErrors.images, hasError: true, shake: true },
          }));
          isValid = false;
          resetShakeAnimation(FieldNames.images);
        }
        break;
      }
      default: {
        break;
      }
    }
    return isValid;
  };

  const handleMapOptions = () => {
    if (checkErrorForShowMap()) {
      getMapOptions({
        street: newSpace.street,
        numberStreet: newSpace.numberStreet,
        city: newSpace.city,
        zipCode: newSpace.zipCode,
      });
      toogleIsOpenValidateLocation();
    }
  };

  const checkErrorForShowMap = () => {
    if (
      newSpace.street.trim() === "" ||
      newSpace.numberStreet.trim() === "" ||
      newSpace.zipCode.trim() === "" ||
      newSpace.city.trim() === ""
    ) {
      validateNextStep();
      setErrors((prevErrors) => ({
        ...prevErrors,
        buttonMaps: { ...prevErrors.buttonMaps, hasError: true, shake: true },
      }));

      resetShakeAnimation(FieldNames.buttonMaps);
      return false;
    }

    return true;
  };

  const resetShakeAnimation = (fieldId) => {
    setTimeout(() => {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldId]: { ...prevErrors[fieldId], shake: false },
      }));
    }, 800);
  };

  const toogleIsOpenValidateLocation = () => {
    setIsOpenValidateLocation(!isOpenValidateLocation);
  };

  const stateInfoValues = Object.values(StatusValues).map((value) =>
    getStateInfo(value)
  );

  const HandleSelectedIdMapOption = (option) => {
    setSelectedIdMapOption(option);
  };

  const HandleMapOptionClick = (mapOption) => {
    setSelectedMapOption(mapOption);
    setTemporalCoordinates([
      parseFloat(mapOption.lat),
      parseFloat(mapOption.lon),
    ]);
  };

  const handleRadiousMap = () => {
    setNewSpace((prevNewSpace) => ({
      ...prevNewSpace,
      radiousMap: !newSpace.radiousMap,
    }));
  };

  useEffect(() => {
    if (successPost === true) {
      setActiveStep(12);
    }
  }, [successPost]);

  const resaveNewSpace = (space) => {
    setNewSpace(space);
  };

  return {
    newSpace,
    setDefaultSpace,
    defaultSpace,
    resaveNewSpace,
    saveActiveStep,
    alertText,
    isLoading,
    successPost,
    selectedMapOption,
    handleRadiousMap,
    HandleMapOptionClick,
    HandleSelectedIdMapOption,
    mapOptions,
    selectedIdMapOption,
    temporalCoordinates,
    isOpenValidateLocation,
    activeStep,
    errors,
    handleBack,
    checkErrorForShowMap,
    toogleIsOpenValidateLocation,
    resetShakeAnimation,
    handleNext,
    getSpaceOnlyByUser,
    handleStatus,
    handleVisibility,
    setValueNewSpace,
    validateNextStep,
    handleAddressValidated,
    handleAtrributesCheckboxChange,
    handleSpaceTypesCheckboxChange,
    stateInfoValues,
    handleMapOptions,
    reInicializeNewSpace,
  };
}

export default useSpaceValidation;
