import React from "react";
import "../css/extraPages.css";
import Divider from "../components/Divider";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


const Contact = () => {
  const location = useLocation();
    return (
          <div>
            <Helmet>
                {/* Título básico */}
                <title>Contacta con nosotros - Spacease</title>

                {/* Caracteres y idioma */}
                <meta charSet="utf-8" />
                <html lang="es" />

                {/* Descripción de la página */}
                <meta name="description" content="Contáctanos mediante email o redes sociales. Atención al cliente de Spacease." />

                {/* Mobile Meta */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {/* Open Graph (para Facebook y LinkedIn) */}
                <meta property="og:title" content="Contacta con nosotros - Spacease" />
                <meta property="og:description" content="Contáctanos mediante email o redes sociales. Atención al cliente de Spacease." />
                <meta property="og:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Spacease" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@Spacease_es" />
                <meta name="twitter:title" content="Contacta con nosotros - Spacease" />
                <meta name="twitter:description" content="Contáctanos mediante email o redes sociales. Atención al cliente de Spacease." />
                <meta name="twitter:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/imageopengraph.png" />

                {/* Favicon */}
                <link rel="icon" type="image/png" href="https://www.spacease.com/favicon.ico" />

                {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
                <link rel="canonical" href={`https://www.spacease.com${location.pathname}`} />

                
            </Helmet>
            <main className="cities-container">
              <h3>Atención al cliente</h3>
              <p className="text-gray">Actualmente disponemos de las siguiente vías para contactar con nosotros:</p>
              <section className="cities-container__section">
                <h2>Email</h2>
                <a href="mailto:hello@spacease.com" className="emailLink">hello@spacease.com</a>
              </section>
              <section className="cities-container__section">
                <h2>Redes sociales</h2>
                <a href="https://linkedin.com/company/spacease/" className="rssLink" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                <a href="https://x.com/spacease_es" className="rssLink" target="_blank" rel="noopener noreferrer">X (Anteriormente Twitter)</a>
                <a href="https://instagram.com/spacease_es" className="rssLink" target="_blank" rel="noopener noreferrer">Instagram</a>
              </section>
              <Divider />
              <p className="text-gray">Puedes escribir un mensaje privado a nuestros perfiles de redes sociales si lo deseas.
              Intentaremos responder con la mayor brevedad posible</p>
              <section className="cities-container__section">
                <h2>Equipo</h2>
                <section>
                  <p>Rodrigo Estero - CEO</p>
                  <a href="mailto:rodrigo@spacease.com" className="emailLink">rodrigo@spacease.com</a>
                </section>
                <section>
                  <p>Alejandro Pérez - CTO</p>
                  <a href="mailto:alex@spacease.com" className="emailLink">alex@spacease.com</a>
                </section>
                <section>
                  <p>Sergio Jorquera - COO</p>
                  <a href="mailto:sergio@spacease.com" className="emailLink">sergio@spacease.com</a>
                </section>
              </section>
            </main>
          </div>
      );
};

export default Contact;