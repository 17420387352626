import React from "react";
import { Container } from "react-bootstrap";
const HowDoesItWork = () => {
    return (
        <Container className="container__text">
          <h2 className="text-blue">¿Cómo funciona?</h2>
          <h3>Video explicativo utilizando la aplicación</h3>
    
        </Container>
      );
};

export default HowDoesItWork;