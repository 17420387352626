import React from 'react'
import { sofa_no_water_mark } from '../Assets'
import cssStillHavingQuestions from "../css/stillHavingQuestions.module.css"
import {URL_CONTACT} from "../context/urlPath"
const StillHavingQuestions = () => {
  return (
    <section className={cssStillHavingQuestions["container"]}>
          <div className={cssStillHavingQuestions["container__image-container"]}>
            <img className={cssStillHavingQuestions["container__image-container__image"]} src={sofa_no_water_mark} alt="" />
          </div>
          <aside className={cssStillHavingQuestions["container__aside"]}>
            <h3 className={cssStillHavingQuestions["container__aside__title"]}>
              ¿Sigues teniendo preguntas?
            </h3>
            <p className={cssStillHavingQuestions["container__aside__subTitle"]}>
              Asistencia personalizada de Spacease
            </p>
            <p className={cssStillHavingQuestions["container__aside__description"]}>
              Te acompañamos durante todo el proceso en caso de dudas. Resuelve problemas con tu cuenta, 
              configuración del anuncio y otros aspectos importantes.
            </p>
            <a style={{maxWidth:"fit-content"}} href={URL_CONTACT} className="simple_blue_button">
              Contacta con nuestro equipo
            </a>
          </aside>
        </section>
  )
}

export default StillHavingQuestions;