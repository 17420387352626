
import { Button } from "react-bootstrap";
import '../css/popup.css'
import { useEffect, useState } from "react";
export const PopUp = () => {

  const [showButton,setShowButton]= useState(true);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('hasAcceptedPrivacy');
    if (hasAccepted === 'true') {
      setShowButton(false);
    }
  }, []);


  const handleCloseButton = () =>{
    localStorage.setItem('hasAcceptedPrivacy', 'true');
    setShowButton(false);
  }

  return (
    <section style={ !showButton ? {display:"none"}:{}} className='popUp__container'>
        <div className='popUp__container__container'>
            <section>
                <h2 className='popUp__title'>Privacidad y Cookies</h2>
                <p className='popUp__description'>Utilizamos cookies y tecnologías similares para personalizar el contenido, adaptar los anuncios y medir su eficacia, así como para ofrecer una mejor experiencia. Al hacer clic en "Vale", consientes el uso de cookies.</p>
            </section>
            <aside className="popUp__button__container">
                <Button onClick={handleCloseButton} className="simple_blue_button">Vale</Button>
            </aside>
        </div>
    </section>
  )
}

export default PopUp;