import React, { useState } from "react";
import axios from "axios";
import { Form, Button, Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import "../css/resetPassword.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


function ResetPasswordRequest() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  
  const location = useLocation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Verificar que el captcha ha sido completado
    if (!captchaToken) {
      setCaptchaError(true);
      return;
    }

    setLoading(true);

    try {
      await axios.post("/api/auth/reset-password", { email });
      setShowModal(true);
    } catch (err) {
      console.error("Error al solicitar el restablecimiento de contraseña:", err);
      // Puedes añadir más lógica de manejo de errores aquí si lo necesitas
    }

    setLoading(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
    <Helmet>
                {/* Título básico */}
                <title>Resetear contraseña</title>

                {/* Caracteres y idioma */}
                <meta charSet="utf-8" />
                <html lang="es" />

                {/* Descripción de la página */}
                <meta name="description" content="Introduce tu email y te mandaremos un link para que puedas recuperar tu cuenta en Spacease" />

                {/* Mobile Meta */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {/* Open Graph (para Facebook y LinkedIn) */}
                <meta property="og:title" content="Resetear contraseña" />
                <meta property="og:description" content="Introduce tu email y te mandaremos un link para que puedas recuperar tu cuenta en Spacease" />
                <meta property="og:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Spacease" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@Spacease_es" />
                <meta name="twitter:title" content="Resetear contraseña" />
                <meta name="twitter:description" content="Introduce tu email y te mandaremos un link para que puedas recuperar tu cuenta en Spacease" />
                <meta name="twitter:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg" />

                {/* Favicon */}
                <link rel="icon" type="image/png" href="https://www.spacease.com/favicon.ico" />

                {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
                <link rel="canonical" href={`https://www.spacease.com${location.pathname}`} />

                
            </Helmet>
      {showModal && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Solicitud recibida</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Si tu dirección de correo electrónico existe en nuestro sistema, hemos enviado un correo electrónico con instrucciones para restablecer la contraseña.
          </Modal.Body>
        </Modal>
      )}
      <div>
        <Form onSubmit={handleSubmit} className="form__login">
          <h2>¿Has olvidado tu contraseña?</h2>
          <p>
            Introduce tu dirección de email, te mandaremos instrucciones sobre como resetear tu contraseña.
          </p>
          <Form.Control
            type="email"
            placeholder="Dirección de email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="captcha-container">
            <ReCAPTCHA
              sitekey="6LdGb9IlAAAAABf6T4yPbvHNbc9QuKI0sWMsQrbo"
              onChange={(token) => {
                setCaptchaToken(token);
                setCaptchaError(false);
              }}
            />
            {captchaError && (
              <div className="error-message">
                Por favor, completa el CAPTCHA.
              </div>
            )}
          </div>
          <div className="justify-content-center d-flex flex-column">
            <Button
              className="button-blue"
              variant="primary"
              type="submit"
              disabled={loading}
            >
              Mandar instrucciones de reseteo
            </Button>
            <Link to="/login" className="link-style">
              Volver a iniciar sesión
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
}

export default ResetPasswordRequest;
