import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";



const ShowMore = (props) =>
{

    const {text,classNameContainer, classNameText} = props;
    const paragraphStyles= {
        WebkitLineClamp:3,
        WebkitBoxOrient: 'vertical',
        overflow:'hidden',
        display:'-webkit-box',
        maxWidth:'100%'
    }
    const [isOpen,setIsOpen] = useState(false);
    const [showButton,setShowButton] = useState(false);

    const ref = useRef(null);
    useEffect(()=>{
        if(ref.current){
            setShowButton(ref.current.scrollHeight !== ref.current.clientHeight)
        }
    },[])

   return( 
   <div style={{width:"100%",display:"inline-block",wordBreak:"break-word"}} className={`${classNameContainer}`}>
        <p className={`m-0 ${classNameText}`} style={isOpen ? null: paragraphStyles} ref={ref}>
            {text}
        </p>
        {showButton &&
            <Link className="text-blue fs_0-875rem fw_400" onClick={()=>{setIsOpen(!isOpen)}}>{isOpen ? 'Leer menos...':"Leer más..."}</Link>
        }
    </div>)

}

export default ShowMore;