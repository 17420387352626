import React from "react";
import { Card, CardContent, Skeleton } from "@mui/material";

const SpaceCardLoading = () => {
	return (
		<Card className="card__container">
			<Skeleton
				variant="rectangular"
				sx={{
					"&": {
						height: "17.35rem",
						borderRadius: "1rem",
						margin: ".5rem",
						width: "-webkit-fill-available",
						display: "inline-block",
					},
				}}
			></Skeleton>
			<CardContent>
				<div className="mt-1 description__container d-flex flex-column">
					<Skeleton sx={{ maxWidth: "80%" }} variant="rounded" />
					<Skeleton sx={{ maxWidth: "40%" }} variant="rounded" />
					<div className="w-100 d-flex gap_1-25rem">
						<Skeleton
							sx={{ maxWidth: "20%", display: "inline-block", width: "100%" }}
							variant="rounded"
						/>
						<Skeleton
							sx={{ maxWidth: "20%", display: "inline-block", width: "100%" }}
							variant="rounded"
						/>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default SpaceCardLoading;
