import React from "react";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  URL_ABOUT_US,
  URL_CITIES,
  URL_CONTACT,
  URL_LANDING,
  URL_LANDING_CREATE_SPACE,
  URL_REGISTER,
  URL_RULES,
  URL_TERMS_OF_USE,
  URL_UPLOAD_SPACE,
} from "../context/urlPath";

import "../css/footer.css"; // asumir que tienes un archivo Footer.css en la misma carpeta que Footer.js
import Logo from "./Logo";

function Footer() {
  const location = useLocation();
  const [pathName, setPathName] = useState("");

  useEffect(() => {
    const path = location.pathname;
    setPathName(path);
  }, [location.pathname]);

  return (
    <>
      {pathName !== URL_REGISTER && pathName !== URL_UPLOAD_SPACE ? (
        <footer className="footer__container">
          <div className="footer__mainFooter">
            <div className="footer__mainFooter__column">
              <div className="footer__mainFooter__column__logo">
                <Logo />
                <p className="footer__mainFooter__column__text-beta">
                  Versión beta 1.0
                </p>
              </div>
              <div style={{ maxWidth: "245px" }}>
                <p className="footer__mainFooter__column__text-gray">
                Los mejores locales comerciales y espacios de retail en un solo lugar
                </p>
              </div>
            </div>
            <div className="footer__mainFooter__column">
              <p className="footer__mainFooter__column__textHeader">
                Atención al cliente
              </p>
              <div className="footer__mainFooter__column__description__container">
                <a
                  className="footer__mainFooter__column__text"
                  href={URL_CONTACT}
                >
                  Nuestro Email
                </a>
              </div>
            </div>
            <div className="footer__mainFooter__column">
              <p className="footer__mainFooter__column__textHeader">
                Sobre Spacease
              </p>
              <div className="footer__mainFooter__column__description__container">
                <a
                  className="footer__mainFooter__column__text"
                  href={URL_LANDING}
                >
                  ¿Cómo funciona?
                </a>
                <a
                  className="footer__mainFooter__column__text"
                  href={URL_ABOUT_US}
                >
                  Sobre nosotros
                </a>
                <a
                  className="footer__mainFooter__column__text"
                  href={URL_CITIES}
                >
                  Ciudades
                </a>
              </div>
            </div>
            <div className="footer__mainFooter__column">
              <p className="footer__mainFooter__column__textHeader">
                Dueños de espacios
              </p>
              <div className="footer__mainFooter__column__description__container">
                <a
                  className="footer__mainFooter__column__text"
                  href={URL_LANDING_CREATE_SPACE}
                >
                  Sube tu espacio
                </a>
                <a
                  className="footer__mainFooter__column__text"
                  href={URL_RULES}
                >
                  Reglas para dueños
                </a>
              </div>
            </div>
          </div>
          <div className="footer__subFooter">
            <p className="footer__mainFooter__column__text-gray">
              © Spacease 2023{" "}
            </p>
            <p className="footer__mainFooter__column__text-gray">·</p> 
             <a
              className="footer__mainFooter__column__text-gray"
              href={URL_TERMS_OF_USE}
            >
              Política de Privacidad
            </a>
            <p className="footer__mainFooter__column__text-gray">·</p>
            <a
              className="footer__mainFooter__column__text-gray"
              href={URL_TERMS_OF_USE}
            >
              Términos de Uso
            </a>
          </div>
        </footer>
      ) : (
        <></>
      )}
    </>
  );
}

export default Footer;