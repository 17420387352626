import React from "react";
import cssRules from "../css/rules.module.css";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import {sofa_no_water_mark,rules_1} from "../Assets/"
import { Link } from "react-router-dom";
import StillHavingQuestions from "../components/StillHavingQuestions";

const Rules = () => {
  const location = useLocation();
  return (
    <div>
      <Helmet>
                {/* Título básico */}
                <title>Reglas para espacios - Spacease</title>

                {/* Caracteres y idioma */}
                <meta charSet="utf-8" />
                <html lang="es" />

                {/* Descripción de la página */}
                <meta name="description" content="Consulta los requisitos que aplicamos a la hora de seleccionar locales comerciales para la plataforma" />

                {/* Mobile Meta */}
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

                {/* Open Graph (para Facebook y LinkedIn) */}
                <meta property="og:title" content="Reglas para espacios - Spacease" />
                <meta property="og:description" content="Consulta los requisitos que aplicamos a la hora de seleccionar locales comerciales para la plataforma" />
                <meta property="og:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Spacease" />

                {/* Twitter Card */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content="@Spacease_es" />
                <meta name="twitter:title" content="Reglas para espacios - Spacease" />
                <meta name="twitter:description" content="Consulta los requisitos que aplicamos a la hora de seleccionar locales comerciales para la plataforma" />
                <meta name="twitter:image" content="https://spaceasebucket.s3.us-east-2.amazonaws.com/resources_images/spacease-rules-for-owners.jpg" />

                {/* Favicon */}
                <link rel="icon" type="image/png" href="https://www.spacease.com/favicon.ico" />

                {/* Canonical URL (importante para evitar contenido duplicado en los motores de búsqueda) */}
                <link rel="canonical" href={`https://www.spacease.com${location.pathname}`} />

                
            </Helmet>
      <main className={cssRules["main__container"]}>
        <header>
          <h1 className={cssRules["main__container__title"]}>Requisitios para espacios</h1>
          <p className={cssRules["main__container__subTitle"]}>
          Cuando se sube un anuncio a la plataforma, lo revisamos cuidadosamente para garantizar su conformidad con nuestros requisitos. 
          Si no cumple con alguno de ellos, te contactaremos para hacer los ajustes necesarios.
          </p>
          <img className={cssRules["main__container__image-container__image"]} src={rules_1} alt="" />
        </header>
        <p className={cssRules["main__container__lastMod"]}>Última vez actualizado el 1/9/2023</p>
        <section className={cssRules["main__container__section"]}>
          <h3 className={cssRules["main__container__section__mainText"]}>1 - Información veraz y detallada</h3>
          <p className={cssRules["main__container__section__subText"]}>
          Proporciona una descripción completa y precisa del local comercial. 
          Asegúrate de rellenar correctamente la información de ubicación, tamaño en metros cuadrados, tráfico peatonal, 
          descripciones de espacio y área, etc. Incluye información relevante a tener en cuenta en los campos de descripción. 
          La información debe ser verídica en todo momento, y en caso de encontrar información incorrecta o puesta con mala fe, 
          rechazaremos el anuncio y bloquearemos la cuenta.
           </p>
        </section>
        <section className={cssRules["main__container__section"]}>
          <h3 className={cssRules["main__container__section__mainText"]}>2 - Fotografías de calidad</h3>
          <p className={cssRules["main__container__section__subText"]}>
          Sube imágenes claras y de alta resolución del espacio desde diferentes ángulos. 
          Las fotos deben mostrar tanto el exterior como el interior del local para dar a los usuarios una 
          idea precisa de cómo se ve. Si en las imágenes el espacio está sucio, mal iluminado o son de baja calidad, 
          no podremos aprobar el anuncio. Alternativamente, ofrecemos un servicio gratuito de fotografía (bajo ciertas condiciones) 
          para aquellos propietarios que no pueden tomar imágenes acordes a nuestros requisitos. Manda un correo electrónico a hello@spacease.com
          para recibir más información sobre el servicio de fotografía si estás interesado.
          </p>
        </section>
        <section className={cssRules["main__container__section"]}>
          <h3 className={cssRules["main__container__section__mainText"]}>3 - Precio claro y visible</h3>
          <p className={cssRules["main__container__section__subText"]}>
            Especifica el precio de alquiler de manera clara y detallada. 
            Requerimos que todos los anuncios incluyan precios visibles de un vistazo para asegurar una experiencia de comparación
            correcta para los usuarios. Los precios deben ser estándar, aunque luego se incluyan cláusulas especiales en el 
            contrato final o varíen ligeramente para un presupuesto.
          </p>
        </section>
        <section className={cssRules["main__container__section"]}>
          <h3 className={cssRules["main__container__section__mainText"]}>4 - Ubicación y área del espacio</h3>
          <p className={cssRules["main__container__section__subText"]}>
          Proporciona la dirección exacta del local comercial. En la descripción del área, 
          puedes incluir detalles como cercanía a puntos de referencia, vías de acceso, opciones de transporte público
           u otra información relevante sobre la ubicación. Describe correctamente el área o barrio donde se encuentra el
            local comercial, ya que es un factor clave para los usuarios que no conocen el lugar y mejora de manera sustancial 
            las probabilidades de recibir inquilinos.
          </p>
          <p className={cssRules["main__container__section__subText"]}>
          En lo que respecta a la posición geográfica del espacio, no aceptamos anuncios en áreas problemáticas. 
          Nos esforzamos por crear una selección de anuncios de la más alta calidad posible, por lo tanto, priorizamos zonas
          con características como alto potencial comercial, alto tráfico peatonal, ubicación céntrica, centros comerciales, etc.
          </p>
        </section>
        <section className={cssRules["main__container__section"]}>
          <h3 className={cssRules["main__container__section__mainText"]}>5 - Detalles de contacto</h3>
          <p className={cssRules["main__container__section__subText"]}>
            Proporciona información de contacto actualizada para que los usuarios puedan comunicarse contigo fácilmente. 
            Incluye un número de teléfono al que tengas acceso de forma constante y un correo electrónico que compruebes con frecuencia.
          </p>
        </section>
        <section className={cssRules["main__container__section"]}>
          <h3 className={cssRules["main__container__section__mainText"]}>6 - Respuesta rápida</h3>
          <p className={cssRules["main__container__section__subText"]}>
          Establece un proceso para responder rápidamente a las consultas de los interesados. 
          Una atención al cliente efectiva puede marcar la diferencia en la decisión de un inquilino potencial.</p>
        </section>
        <StillHavingQuestions/>
      </main>
    </div>
  );
};

export default Rules;
